import { z } from 'zod'

export const monthAbbrSchema = z
  .union([
    z.literal('jan'),
    z.literal('feb'),
    z.literal('mar'),
    z.literal('apr'),
    z.literal('may'),
    z.literal('jun'),
    z.literal('jul'),
    z.literal('aug'),
    z.literal('sep'),
    z.literal('oct'),
    z.literal('nov'),
    z.literal('dec'),
  ])
  .catch('jan')

export type YearMonthObject = {
  year: number
  month: z.infer<typeof monthAbbrSchema>
}
