import { useState } from 'react'
import { SmallPieChart } from 'ygf_graphs'

import DashboardSection from '../../../components/dashboard/DashboardSection'
import { CURRENCY_SYMBOL } from '../../../constants'
import { reduceToTen } from '../../../helpers/DataReducers'
import DashboardPage from '../../content/DashboardPage'

import * as data from './all_data'

const PaymentProviders = () => {
  const [option, setOption] = useState(Object.keys(industryToData)[0])

  const optionIsIndustry = Object.prototype.hasOwnProperty.call(
    industryToData,
    option
  )
  const dataToUse = optionIsIndustry
    ? industryToData[option]
    : brandToData[option]

  return (
    <DashboardPage
      title='Payment Providers'
      description={`An example of a customer base's spending accross a few industries and brands broken down by payment provider`}>
      <DashboardSection
        title='Select an industry or brand'
        description='If you would like to see similar data for another industry or brand, please contact us at info@yougov.finance.'>
        <div className='mb-7'>
          <select onChange={e => setOption(e.target.value)}>
            <optgroup label='Industries'>
              {Object.keys(industryToData).map(i => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </optgroup>
            <optgroup label='Brands'>
              {Object.keys(brandToData).map(i => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </optgroup>
          </select>
        </div>
        <h6>
          Spend {`${optionIsIndustry ? 'in' : 'at'} ${option}`} across payment
          providers
        </h6>
        <SmallPieChart
          currency={CURRENCY_SYMBOL}
          id='piechart'
          data={reduceToTen(
            Object.entries({
              ...dataToUse.DEBIT,
              ...dataToUse.CREDIT,
            }).reduce((acc, [key, value]) => [...acc, { name: key, value }], [])
          )}
        />
        <h6>
          Spend {`${optionIsIndustry ? 'in' : 'at'} ${option}`} across payment
          cards
        </h6>
        <SmallPieChart
          currency={CURRENCY_SYMBOL}
          id='piechart2'
          data={Object.entries(
            Object.entries({
              ...dataToUse.DEBIT,
              ...dataToUse.CREDIT,
            }).reduce((acc, [key, value]) => {
              const card = bankToCard[key] ?? key
              if (!Object.prototype.hasOwnProperty.call(acc, card))
                acc[card] = 0
              acc[card] += value
              return acc
            }, {})
          ).reduce((acc, [key, value]) => [...acc, { name: key, value }], [])}
        />
        <h6>
          Spend {`${optionIsIndustry ? 'in' : 'at'} ${option}`} across credit
          cards
        </h6>
        <SmallPieChart
          currency={CURRENCY_SYMBOL}
          id='piechart3'
          data={Object.entries(
            Object.entries({
              ...dataToUse.CREDIT,
            }).reduce((acc, [key, value]) => {
              const card = bankToCard[key] ?? key
              if (!Object.prototype.hasOwnProperty.call(acc, card))
                acc[card] = 0
              acc[card] += value
              return acc
            }, {})
          ).reduce((acc, [key, value]) => [...acc, { name: key, value }], [])}
        />
      </DashboardSection>
    </DashboardPage>
  )
}

const industryToData = {
  Supermarkets: data.supermarkets,
  Online: data.online,
  Transport: data.transport,
  'High Street Retail': data.highStreetRetail,
  // 'Gambling/Casinos': data.gamblingCasinos,
}

const brandToData = {
  Amazon: data.amazon,
  Netflix: data.netflix,
  Uber: data.uber,
}

const bankToCard = {
  'first direct': 'Mastercard debit',
  TSB: 'Visa debit',
  Halifax: 'Visa debit',
  NatWest: 'Visa debit',
  Barclays: 'Visa debit',
  HSBC: 'Visa debit',
  Starling: 'Mastercard debit',
  Nationwide: 'Visa debit',
  Monzo: 'Mastercard debit',
  Santander: 'Mastercard debit',
  Lloyds: 'Visa debit',
  'Royal Bank of Scotland': 'Visa debit',
  'Bank of Scotland': 'Visa debit',
  'Barclays Business': 'Visa debit',
  Revolut: 'Prepaid Revolut',
  VISA: 'Visa credit',
  MASTERCARD: 'Mastercard credit',
  AMEX: 'American Express',
  'Tesco Bank': 'Visa debit',
  Tide: 'Mastercard debit',
  'Ulster Bank': 'Visa debit',
  Wise: 'Mastercard debit',
  'Danske Bank': 'Mastercard debit',
}

export default PaymentProviders
