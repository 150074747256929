import { Link } from 'react-router-dom'

const navigation = {
  support: [{ name: 'Get in touch', href: '/get-in-touch' }],
  company: [
    { name: 'About', href: 'https://business.yougov.com/product/finance' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy-policy' },
    { name: 'Terms', href: '/terms' },
  ],
}

const Footer = () => (
  <footer className='bg-white' aria-labelledby='footer-heading'>
    <h2 id='footer-heading' className='sr-only'>
      Footer
    </h2>
    <div className='mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16'>
      <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
        <div className='space-y-8 xl:col-span-1'>
          <span className='flex flex-row text-center align-middle'>
            <img
              className='h-10 py-2.5'
              src='/favicon.png'
              alt='Company name'
            />
            <h1 className='align-center ml-2 mt-1 h-10 text-2xl font-semibold text-gray-400 sm:mt-3'>
              YouGov Finance
            </h1>
          </span>
        </div>
        <div className='mt-12 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0'>
          <div className='md:grid md:grid-cols-3 md:gap-8'>
            <div className='mt-12 md:mt-0'>
              <h3 className='text-sm font-semibold uppercase tracking-wider text-gray-400'>
                Support
              </h3>
              <ul className='mt-4 space-y-4'>
                {navigation.support.map(item => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className='text-base text-gray-500 hover:text-gray-900'>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className='text-sm font-semibold uppercase tracking-wider text-gray-400'>
                Company
              </h3>
              <ul className='mt-4 space-y-4'>
                {navigation.company.map(item => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-base text-gray-500 hover:text-gray-900'>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='mt-12 md:mt-0'>
              <h3 className='text-sm font-semibold uppercase tracking-wider text-gray-400'>
                Legal
              </h3>
              <ul className='mt-4 space-y-4'>
                {navigation.legal.map(item => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className='text-base text-gray-500 hover:text-gray-900'>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-12 border-t border-gray-200 pt-8'>
        <p className='text-base text-gray-400 xl:text-center'>
          &copy; 2022 YouGov Finance Ltd. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
