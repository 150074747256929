export type Brand = {
  id: number
  name: string
  imageUrl: string
}

const brands: Brand[] = [
  'Aldi',
  'Amazon',
  'Asda',
  'Deliveroo',
  'eBay',
  { name: 'Just Eat', imageUrl: 'https://logo.clearbit.com/just-eat.co.uk' },
  'Lidl',
  {
    name: 'Marks & Spencer',
    imageUrl: 'https://logo.clearbit.com/marksandspencer.in',
  },
  "Mcdonald's",
  "Morrison's",
  {
    name: 'National Lottery',
    imageUrl: 'https://logo.clearbit.com/national-lottery.co.uk',
  },
  "Sainsbury's",
  'Tesco',
  'Uber Eats',
  'Waitrose',
].map((b, i) =>
  typeof b === 'object'
    ? { ...b, id: i }
    : {
        id: i,
        name: b,
        imageUrl: `https://logo.clearbit.com/${b
          .toLowerCase()
          .split(' ')
          .join('')
          .split("'")
          .join('')}.com`,
      }
)

export default brands
