import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SmallPieChart } from 'ygf_graphs'
import { z } from 'zod'
import DashboardSection from '../../components/dashboard/DashboardSection'
import MultiSelector from '../../components/dashboard/MultiSelector'
import { TextLink } from '../../components/shared/TextLink'
import { CURRENCY_SYMBOL } from '../../constants'
import {
  brandsSchema,
  useBrandInfoQuery,
  useBrandsQuery,
} from '../../hooks/useBrandInfo'
import { useScreenWidth } from '../../hooks/useScreenWidth'
import { FactSheet, FactSheetItem } from '../components/FactSheet'
import DashboardPage from '../content/DashboardPage'
import { TRIAL_BRAND } from '../trial-data'
import { AssociatedBrands } from './components/associated-brands'

const BrandProfiles = () => {
  const trial = useSelector(({ trial }) => trial)
  const params = useParams()
  const navigate = useNavigate()

  const brand = params?.query

  const brandsQuery = useBrandsQuery()
  const brandInfoQuery = useBrandInfoQuery(brand)

  const brands = (brandsQuery.data || []).map(
    (b: z.infer<typeof brandsSchema>[number]) => b.brand
  )
  const brandLogos = useMemo(() => {
    return (brandsQuery.data || []).reduce(
      (
        acc: Record<string, string>,
        { brand, logo_link }: { brand: string; logo_link: string }
      ) => ({ ...acc, [brand]: logo_link }),
      {}
    )
  }, [brandsQuery.data])

  const setSelectedBrands = (brands: string[]) => {
    if (brands.length === 0) return
    navigate(brands[0])
  }

  // combine 70-80 and 80+ into 70+
  if (
    brandInfoQuery.data &&
    'age_dist' in brandInfoQuery.data &&
    Object.prototype.hasOwnProperty.call(
      brandInfoQuery.data.age_dist,
      '70-80'
    ) &&
    Object.prototype.hasOwnProperty.call(brandInfoQuery.data.age_dist, '80+')
  ) {
    brandInfoQuery.data.age_dist['70+'] =
      brandInfoQuery.data.age_dist['70-80'] +
      brandInfoQuery.data.age_dist['80+']
    delete brandInfoQuery.data.age_dist['70-80']
    delete brandInfoQuery.data.age_dist['80+']
  }

  const TAILWIND_XL_BREAKPOINT = 1280
  const screenWidth = useScreenWidth()

  return (
    <DashboardPage
      backLink={brand && !trial ? '..' : undefined}
      description={
        brand
          ? `An aggregated breakdown of ${brand} customers`
          : 'An aggregated breakdown by brand'
      }
      title={`Brand Profiles${brand ? ` - ${brand}` : ''}`}>
      {!brand ? (
        <>
          {trial && (
            <div className='mt-2 max-w-sm rounded-lg bg-stonegrey-400 p-3 text-sm'>
              <span className='mr-2 rounded bg-stonegrey-200 p-1 px-2 text-xs font-bold uppercase'>
                demo
              </span>
              This is a demo of the Brand Profiles feature. You only have access
              to one brand: {TRIAL_BRAND}. To unlock the 4,000+ brands that we
              track across hundreds of sectors,{' '}
              <TextLink
                target='_blank'
                href='https://business.yougov.com/?marketo=demo'>
                book a demo call
              </TextLink>
              .
            </div>
          )}
          {brandsQuery.isLoading ? (
            <>Loading...</>
          ) : (
            <DashboardSection title='Select a brand'>
              <div>
                <div className='inline-block rounded-lg border pl-2.5'>
                  <MultiSelector
                    title='Brands'
                    options={brands.filter(
                      (b: string) => !trial || b === TRIAL_BRAND
                    )}
                    selected={[]}
                    setSelected={setSelectedBrands}
                    placeholder='Search brands...'
                  />
                </div>
              </div>
            </DashboardSection>
          )}
        </>
      ) : (
        <>
          {trial && brand !== TRIAL_BRAND ? (
            <div className='mt-2 max-w-sm rounded-lg bg-stonegrey-400 p-3 text-sm'>
              <span className='mr-2 rounded bg-stonegrey-200 p-1 px-2 text-xs font-bold uppercase'>
                demo
              </span>
              This is a demo of the Brand Profiles feature. You don't have
              access to this brand. To see a brand you do have access to,{' '}
              <TextLink to={`/dashboard/brand-profiles/${TRIAL_BRAND}`}>
                click here
              </TextLink>
              . To unlock our all the 4,000+ brands that we track across
              hundreds of sectors,{' '}
              <TextLink
                target='_blank'
                href='https://business.yougov.com/?marketo=demo'>
                book a demo call
              </TextLink>
              .
            </div>
          ) : (
            <>
              {trial && (
                <div className='mt-2 max-w-sm rounded-lg bg-stonegrey-400 p-3 text-sm'>
                  <span className='mr-2 rounded bg-stonegrey-200 p-1 px-2 text-xs font-bold uppercase'>
                    demo
                  </span>
                  This is a demo of the Brand Profiles feature. You only have
                  access to Brand Profiles for the brand {TRIAL_BRAND}. To
                  unlock our all the 4,000+ brands that we track across hundreds
                  of sectors,{' '}
                  <TextLink
                    target='_blank'
                    href='https://business.yougov.com/?marketo=demo'>
                    book a demo call
                  </TextLink>
                  .
                </div>
              )}
              <DashboardSection title={`${brand} fact sheet`}>
                {!brandInfoQuery.isLoading &&
                  typeof brandInfoQuery.data === 'object' &&
                  !Array.isArray(brandInfoQuery.data) && (
                    <FactSheet>
                      {!!brandInfoQuery.data.nr_users &&
                        !!brandInfoQuery.data.nr_trans &&
                        !!brandInfoQuery.data.avg_amount && (
                          <FactSheetItem
                            title='Average total spend (per customer)'
                            info='Total spend divided by number of customers'>{`${CURRENCY_SYMBOL}${
                            Math.round(
                              (-100 *
                                (brandInfoQuery.data.nr_trans *
                                  brandInfoQuery.data.avg_amount)) /
                                brandInfoQuery.data.nr_users
                            ) / 100
                          }`}</FactSheetItem>
                        )}
                      {!!brandInfoQuery.data.avg_freq_days && (
                        <FactSheetItem
                          title='Average monthly spend (per customer)'
                          info={`Average total spend in a month.\nCalculated by finding the average total spend in a month for each active user over the months that they had payments in, and then averaging over all of those users`}>
                          {CURRENCY_SYMBOL}
                          {-1 * brandInfoQuery.data.avg_total_month}
                        </FactSheetItem>
                      )}
                      {!!brandInfoQuery.data.avg_amount && (
                        <FactSheetItem
                          title='Average transaction value'
                          info='Average transaction amount over all transactions'>
                          {CURRENCY_SYMBOL}
                          {-1 * brandInfoQuery.data.avg_amount}
                        </FactSheetItem>
                      )}
                      {!!brandInfoQuery.data.avg_nr_trans_month && (
                        <FactSheetItem
                          title='Average number of transactions per month'
                          info={`Average number of transactions per month.\nCalculated by finding the average number of transactions in a month for each active user over the months that they had payments in, and then averaging over all of those users`}>
                          {brandInfoQuery.data.avg_nr_trans_month}
                        </FactSheetItem>
                      )}
                      {!!brandInfoQuery.data.nr_active_users && (
                        <FactSheetItem
                          title='Number of active customers in sample'
                          info='Number of users whose last payment at the brand was made less than 3 months ago'>
                          {brandInfoQuery.data.nr_active_users}
                        </FactSheetItem>
                      )}
                      {!!brandInfoQuery.data.avg_freq_days && (
                        <FactSheetItem
                          title='Average number of days between transactions'
                          info={`Average payment frequency in days.\nCalculated by finding the average number of days between payments for each active user that has transactions at the brand, (given they have at least two), and then averaging over all of those users`}>
                          {Math.round(brandInfoQuery.data.avg_freq_days)}
                        </FactSheetItem>
                      )}
                    </FactSheet>
                  )}
              </DashboardSection>

              <DashboardSection title='Demographic breakdown'>
                {!brandInfoQuery.isLoading &&
                  typeof brandInfoQuery.data === 'object' &&
                  !Array.isArray(brandInfoQuery.data) && (
                    <div className='block justify-around xl:flex'>
                      <div>
                        <h4 className='font-bold'>Gender:</h4>
                        <SmallPieChart
                          data={Object.entries(brandInfoQuery.data.gend_dist)
                            .filter(([k]) => k !== 'Unknown')
                            .map(([key, value]) => ({
                              name: key,
                              value: value,
                            }))}
                          hideExactValue={true}
                          id='genderSplitChart'
                          legendBelow={screenWidth > TAILWIND_XL_BREAKPOINT}
                        />
                      </div>
                      <div>
                        <h4 className='font-bold'>Age:</h4>
                        <SmallPieChart
                          data={Object.entries(brandInfoQuery.data.age_dist)
                            .filter(([k]) => k !== 'Unknown')
                            .map(([key, value]) => ({
                              name: key,
                              value: value,
                            }))}
                          hideExactValue={true}
                          id='ageSplitChart'
                          legendBelow={screenWidth > TAILWIND_XL_BREAKPOINT}
                        />
                      </div>
                      <div>
                        <h4 className='font-bold'>Location:</h4>
                        <SmallPieChart
                          data={Object.entries(brandInfoQuery.data.region_dist)
                            .filter(([k]) => k !== 'Unknown')
                            .map(([key, value]) => ({
                              name: key,
                              value: value,
                            }))}
                          hideExactValue={true}
                          id='locationSplitChart'
                          legendBelow={screenWidth > TAILWIND_XL_BREAKPOINT}
                        />
                      </div>
                    </div>
                  )}
              </DashboardSection>
              <DashboardSection title='Associated brands'>
                {!brandInfoQuery.isLoading &&
                  typeof brandInfoQuery.data === 'object' &&
                  !Array.isArray(brandInfoQuery.data) && (
                    <div className='flex items-start gap-4'>
                      <AssociatedBrands
                        data={brandInfoQuery.data}
                        brandLogos={brandLogos}
                      />
                    </div>
                  )}
              </DashboardSection>
            </>
          )}
        </>
      )}
    </DashboardPage>
  )
}

export default BrandProfiles
