/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cloud_logic_custom: [
    {
      name: 'clientGateway',
      endpoint: 'https://yhqqootgx6.execute-api.eu-west-2.amazonaws.com/us',
      region: 'eu-west-2',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-west-2:1a25ae4b-88d7-4525-ae92-b17e2dd76253',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_DLTxf7Yzl',
  aws_user_pools_web_client_id: '3rq7o9vbutdjmvstv2pu5vhvhb',
  oauth: {},
  Storage: {
    AWSS3: {
      bucket: 'client-file-storage182032-us',
    },
  },
}

export default awsmobile
