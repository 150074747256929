import { useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { ZodSchema, z } from 'zod'

export const brandsSchema = z.array(
  z.object({
    brand: z.string(),
    logo_link: z.string().nullable(),
  })
)

const numberOfNumberStringSchema = z
  .union([z.string(), z.number()])
  .refine(v => {
    if (typeof v === 'string') return !isNaN(Number(v))
    return true
  })
  .transform(v => {
    if (typeof v === 'string') return Number(v)
    return v
  })
  .nullable()

export const brandInfoSchema = z.object({
  brand: z.string(),
  avg_amount: numberOfNumberStringSchema,
  avg_freq_days: numberOfNumberStringSchema,
  avg_total_month: numberOfNumberStringSchema,
  avg_nr_trans_month: numberOfNumberStringSchema,
  nr_trans: numberOfNumberStringSchema,
  nr_users: numberOfNumberStringSchema,
  nr_active_users: numberOfNumberStringSchema,
  nr_new_users: numberOfNumberStringSchema,
  region_dist: z.record(z.number()),
  avg_age: numberOfNumberStringSchema,
  age_dist: z.record(z.number()),
  gend_dist: z.record(z.number()),
  top_shop_dist: z
    .object({
      Streaming: z.record(z.number()),
      Supermarkets: z.record(z.number()),
      Takeout: z.record(z.number()),
    })
    .nullable(),
  switch_dist: z
    .object({
      from: z.record(z.number()),
      to: z.record(z.number()),
    })
    .nullable(),
  weekly_totals: z.record(z.array(z.number())),
  monthly_totals: z.record(z.array(z.number())),
  four_weekly_totals: z.record(z.array(z.number())),
  weekly_counts: z.record(z.array(z.number())),
  monthly_counts: z.record(z.array(z.number())),
  four_weekly_counts: z.record(z.array(z.number())),
})

const useBrandInfoApiQuery = (
  brand: string | undefined,
  schema: ZodSchema,
  enabled: boolean = true
) =>
  useQuery(
    ['brandInfo', brand, schema],
    async () => {
      const init = {
        queryStringParameters: {} as Record<string, string>,
      }
      if (brand) init.queryStringParameters.brand = brand
      const res = await API.get('clientGateway', '/data/brandinfo', init)
      return schema.parse(res)
    },
    { enabled }
  )

export const useBrandsQuery = () =>
  useBrandInfoApiQuery(undefined, brandsSchema)
export const useBrandInfoQuery = (brand: string | undefined) =>
  useBrandInfoApiQuery(brand, brandInfoSchema, !!brand)
