import { CURRENCY_SYMBOL, DATA_MIN_YEAR, MIN_START_DATE } from '../../constants'
import { toDate } from '../../helpers/yearMonthObjectHelper'
import LightWeightChartWrapper from './LightWeightChartWrapper'

// types
import { BrandDataRecord } from '../../hooks/useMarketTrends'

const MAX_TIMESTAMP = 8640000000000000

export const LineGraph = ({
  data,
  id,
  minDate = new Date(0),
  maxDate = new Date(MAX_TIMESTAMP),
  showAmount = true,
  stacked = false,
  timeFrame = '1m',
  dataStartYear = DATA_MIN_YEAR,
}: {
  data: BrandDataRecord
  id: string
  minDate?: Date
  maxDate?: Date
  showAmount?: boolean
  stacked?: boolean
  timeFrame?: '1w' | '1m' | '4w'
  dataStartYear?: number
}) => {
  if (
    !data ||
    Object.keys(data).length === 0 ||
    (data &&
      data[Object.keys(data)[0]] &&
      Object.prototype.hasOwnProperty.call(
        !data[Object.keys(data)[0]],
        'weekly_split'
      ))
  )
    return <></>
  if (!['1w', '4w', '1m'].includes(timeFrame)) timeFrame = '1m'
  const varKey = showAmount ? 'amount' : 'count'
  const timeFrameKey =
    timeFrame === '1m'
      ? 'monthly_split'
      : timeFrame === '4w'
      ? 'four_weekly_split'
      : 'weekly_split'

  type StringToNumberRecord = Record<string, number>

  const reducedData: { date: Date; values: StringToNumberRecord }[] = []
  Object.values(data)[0][timeFrameKey][varKey].forEach((_, i) => {
    const values: StringToNumberRecord = {}
    Object.keys(data).forEach(
      key => (values[key] = data[key][timeFrameKey][varKey][i])
    )
    const month = timeFrame === '1m' ? i : 0
    const day = timeFrame === '1w' ? 7 * i : timeFrame === '4w' ? 28 * i : 2

    const date = new Date(dataStartYear, month, day)
    if (date < minDate || date > maxDate) return
    reducedData.push({ date, values })
  })

  while (
    Object.values(reducedData[0]?.values ?? [1]).reduce(
      (acc, cur) => acc + cur
    ) === 0
  )
    reducedData.shift()
  while (
    Object.values(reducedData[reducedData.length - 1]?.values ?? [1]).reduce(
      (acc, cur) => acc + cur
    ) === 0
  )
    reducedData.pop()

  return (
    <LightWeightChartWrapper
      currency={showAmount ? CURRENCY_SYMBOL : ''}
      data={reducedData}
      timeRange={{
        from: toDate(MIN_START_DATE).toISOString(),
        to: new Date().toISOString(),
      }}
      showDateDay={timeFrame !== '1m'}
      {...{ id, stacked }}
    />
  )
}
