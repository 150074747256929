import Url from 'url-parse'

type Params = { [key: string]: string }

const URLParamHelper = {
  getQuery: () => {
    const URLString = new Url(window.location.href)
    return URLString.query.substring(1)
  },

  getParams: () => {
    const params: Params = {}
    if (URLParamHelper.getQuery()) {
      URLParamHelper.getQuery()
        .split('&')
        .forEach(param => {
          if (!param.includes('=')) return
          const parts = param.split('=')
          params[parts[0]] = parts[1]
        })
    }
    return params
  },

  get: (param: string) => {
    if (param in URLParamHelper.getParams())
      return URLParamHelper.getParams()[param]
    return null
  },

  hasParams: () => {
    return Object.keys(URLParamHelper.getParams()).length >= 1
  },

  paramsToURL: (params: Params) => {
    let paramString = ''
    Object.entries(params).forEach(tuple => {
      const key = tuple[0]
      const value = tuple[1]
      paramString += key + '=' + value + '&'
    })
    return paramString.substring(0, paramString.length - 1)
  },
}

export default URLParamHelper
