import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { BarChart, PieChart } from 'ygf_graphs'
import DashboardSection from '../../../components/dashboard/DashboardSection'
import Table from '../../../components/dashboard/Table'
import { CURRENCY_SYMBOL } from '../../../constants'
import { FactSheet, FactSheetItem } from '../../components/FactSheet'
import DashboardPage from '../../content/DashboardPage'
import bank_data from './users_by_bank'

const BankProfiles = () => {
  const users_by_bank = bank_data.sort((a, b) => a.bank.localeCompare(b.bank))

  const params = useParams()
  const navigate = useNavigate()

  const bank = params?.query
  const [brandLogoLinks, setBrandLogoLinks] = useState({})
  const [category, setCategory] = useState('Supermarkets')
  const [loadingWheel, setLoadingWheel] = useState(false)
  const [loadingBarChart, setLoadingBarChart] = useState(false)
  const [shareOfWalletData, setShareOfWalletData] = useState(null)
  const [transactions, setTransactions] = useState(null)

  if (bank && !users_by_bank.find(bankData => bankData.bank === bank)) {
    navigate('../')
  }

  const bankData = users_by_bank.filter(bankData => bankData.bank === bank)[0]

  useEffect(() => {
    if (!bank) return setLoadingWheel(false)

    setTransactions(null)
    setLoadingWheel(true)

    const init = {
      queryStringParameters: { bank },
    }

    API.get('clientGateway', '/data/usersbybrand', init)
      .then(res => {
        setTransactions(res.data)
        setLoadingWheel(false)
      })
      .catch(err => {
        setLoadingWheel(false)
        console.error(err)
      })
  }, [bank])

  useEffect(() => {
    if (!bank) return setLoadingBarChart(false)

    setShareOfWalletData(null)
    setLoadingBarChart(true)

    const init = {
      queryStringParameters: {
        bank,
        categories: encodeURIComponent(category),
      },
    }

    API.get('clientGateway', '/data/aggregatedspending', init)
      .then(res => {
        setShareOfWalletData(res.data)
        setBrandLogoLinks(res.logo_links)
        setLoadingBarChart(false)
      })
      .catch(err => {
        setLoadingBarChart(false)
        console.error(err)
      })
  }, [bank, category])

  const sumOfSpends =
    shareOfWalletData && Object.entries(shareOfWalletData).length > 0
      ? Object.values(shareOfWalletData).reduce(
          (acc, cur) =>
            acc + cur.monthly_split.amount.reduce((acc, cur) => acc + cur),
          0
        )
      : 0

  if (
    bank &&
    users_by_bank.filter(bankData => bankData.bank === bank).length === 0
  ) {
    return <></>
  }

  return (
    <DashboardPage
      backLink={bank ? '..' : null}
      className='tsb-data-page'
      description={`An example of a customer base's spending habit by bank used`}
      title={bank ? `Bank Profiles - ${bankData.bank}` : 'Bank Profiles'}>
      {/* page no longer supported, so have a big red banner at the top */}
      <div className='bg-red-500 p-4 text-center text-white'>
        This page is no longer supported and may not work as expected.
      </div>
      {!bank ? (
        <DashboardSection
          title='Select a bank'
          description='If you would like to see similar data for another industry or brand, please contact us at info@yougov.finance.'>
          <Container className='ml-0'>
            <Row>
              {users_by_bank.map(bankData => (
                <Col
                  key={bankData.bank}
                  className='m-1 flex cursor-pointer flex-col items-center justify-center rounded border border-stonegrey-400 bg-stonegrey-200 p-4 text-center text-xs hover:border-stonegrey-600 hover:bg-stonegrey-400'
                  lg='2'
                  md='3'
                  onClick={() => navigate(bankData.bank)}
                  sm='4'
                  xs='6'>
                  <img
                    alt={`${bankData.bank} logo`}
                    src={bankData.logoLink}
                    className='h-12 w-auto'
                  />
                  {bankData.bank}
                </Col>
              ))}
            </Row>
          </Container>
        </DashboardSection>
      ) : (
        <DashboardSection title={`${bankData.bank} fact sheet`}>
          <FactSheet>
            <FactSheetItem
              title={
                <>
                  Number of <i>{bankData.bank}</i> users in sample
                </>
              }>
              {bankData.count.toLocaleString()}
            </FactSheetItem>
          </FactSheet>
        </DashboardSection>
      )}
      {bank && transactions && (
        <DashboardSection title={`${bankData.bank} aggregated spend chart`}>
          <PieChart
            data={transactions}
            id='tsb-pie-chart'
            layers={1}
            symbol={CURRENCY_SYMBOL}
            width={600}
          />
          <div className='h-7' />
        </DashboardSection>
      )}
      {loadingWheel && (
        <div>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      )}
      {bank && category && (
        <DashboardSection
          title={`${bankData.bank} aggregated spend by industry`}>
          <select value={category} onChange={e => setCategory(e.target.value)}>
            {cats.map(val => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </select>
          {!loadingBarChart &&
            (shareOfWalletData && Object.keys(shareOfWalletData).length > 0 ? (
              <>
                <BarChart
                  data={shareOfWalletData}
                  id='bar-chart'
                  width={1000}
                  height={400}
                />
                <Table
                  data={{
                    headers: [
                      '#',
                      'Brand',
                      'Total Spend',
                      ...(sumOfSpends > 0 ? ['% of market'] : []),
                    ],
                    body: Object.entries(shareOfWalletData)
                      .sort(
                        (a, b) =>
                          b[1].monthly_split.amount.reduce(
                            (acc, cur) => acc + cur,
                            0
                          ) -
                          a[1].monthly_split.amount.reduce(
                            (acc, cur) => acc + cur,
                            0
                          )
                      )
                      .map((brandData, i) => [
                        i + 1,
                        <>
                          {brandLogoLinks[brandData[0]] &&
                            brandLogoLinks[brandData[0]] !== '-' && (
                              <img
                                alt={`${brandData[0]} logo`}
                                src={`https://logo.clearbit.com/${
                                  brandLogoLinks[brandData[0]]
                                }`}
                                className='mr-2 h-auto w-7'
                              />
                            )}
                          {brandData[0]}
                        </>,
                        CURRENCY_SYMBOL +
                          (
                            Math.round(
                              brandData[1].monthly_split.amount.reduce(
                                (acc, cur) => acc + cur,
                                0
                              ) * 100
                            ) / 100
                          ).toLocaleString(),
                        ...(sumOfSpends > 0
                          ? [
                              (
                                (brandData[1].monthly_split.amount.reduce(
                                  (acc, cur) => acc + cur,
                                  0
                                ) /
                                  sumOfSpends) *
                                100
                              ).toPrecision(2),
                            ]
                          : []),
                      ]),
                  }}
                />
              </>
            ) : (
              <div>No transactions for this bank in this industry.</div>
            ))}
        </DashboardSection>
      )}
      {loadingBarChart && (
        <div>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      )}
    </DashboardPage>
  )
}

export default BankProfiles

const cats = [
  'ATM Withdrawals / Cashback',
  'Airlines',
  'Arts & Museums',
  'Bakeries & Desserts',
  'Banking Institutions',
  'Beer & Cider',
  'Benefits',
  'Betting',
  'Bookstores',
  'Bus & Ferry Operators',
  'Butchers',
  'Camping Facilities',
  'Car Brands',
  'Car Makers & Dealerships',
  'Car Sales, Service & Fuel',
  'Casinos',
  'Charity',
  'Cinemas',
  'Clothing',
  'Coffee Shops',
  'Coffee shops',
  'Convenience Stores',
  'Credit Cards & Payment Services',
  'Credit Scores',
  'Dating',
  'Dentists',
  'Department Stores',
  'Discount Stores',
  'Drugs & Pharmacies',
  'Electronics & Software',
  'Eye Care',
  'Fast Food',
  'Flower Shops',
  'Food Delivery',
  'Gaming',
  'General Retail',
  'Giftshops',
  'Gym',
  'Hair & Grooming Products',
  'Hairdressers',
  'Healthcare Companies',
  'Home Improvement / Furnishings / Appliances',
  'Hospitals & Medical Services',
  'Hotels & Resorts',
  'Household Consumeables',
  'Insurance',
  'Investments & Financial Services',
  'Jewelry',
  'Kids',
  'Leisure',
  'Loans',
  'Lottery',
  'Loyalty Schemes',
  'Luxury Fashion',
  'Membership Associations & Social Clubs',
  'Mortgage Lenders',
  'Music',
  'Newspaper & Magazines',
  'Office Supplies',
  'Parking',
  'Pets',
  'Phone',
  'Political Party',
  'Printing',
  'Property',
  'Public Transport',
  'Pubs & Bars',
  'Rental Car & Taxi',
  'Restaurants',
  'Shipping',
  'Skin Care & Cosmetics',
  'Spa & Massage',
  'Sporting Goods',
  'Sports',
  'Streaming',
  'Supermarkets',
  'TV & Broadband',
  'TV & Radio',
  'Takeout',
  'Tools / Hardware',
  'Trains',
  'Travel Agents & Cruises',
  'Utilities & Services',
  'Watches & Accessories',
  'Websites & Apps',
  'Weightloss Programs',
  'Wine',
  'Zoos & Amusement Parks',
  'eReaders and Audiobooks',
]
