import {
  faMagnifyingGlass,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Combobox } from '@headlessui/react'
import { useState } from 'react'

const classNames = (...classes: (string | false)[]) =>
  classes.filter(Boolean).join(' ')

const MultiSelector = ({
  title,
  placeholder,
  options,
  selected,
  setSelected,
}: {
  title: string
  placeholder: string
  options: string[]
  selected: string[]
  setSelected: (selected: string[]) => void
}) => {
  const [query, setQuery] = useState('')

  const unselectedOptions = options.filter(o => !selected.includes(o))
  const filtered =
    query === ''
      ? unselectedOptions
      : // filter by options containing the query, then sort by the index of the query in the option
        unselectedOptions
          .filter(o => o.toLowerCase().includes(query.toLowerCase()))
          .sort((a, b) => {
            const aIndex = a.toLowerCase().indexOf(query.toLowerCase())
            const bIndex = b.toLowerCase().indexOf(query.toLowerCase())
            return aIndex - bIndex
          })

  const getItemLabel = (label: string) => {
    if (query.length === 0) return label
    const search = new RegExp(`(${query})`, 'gi')
    return label.split(search).map((el, i) => {
      if (i % 2 === 0) return <span key={i}>{el}</span>
      return (
        <span key={i}>
          <b>{el}</b>
        </span>
      )
    })
  }

  if (
    options.length === 1 &&
    (selected.length !== 1 || selected[0] !== options[0])
  )
    setSelected([options[0]])

  return (
    <div className='p-0'>
      <div className='flex items-center gap-4 p-2'>
        <div className='text-sm font-bold'>{title}</div>
        {options.length > 1 && (
          <div className='flex-shrink-0'>
            <Combobox
              as='div'
              value={selected}
              onChange={(v: string[]) => {
                setQuery('')
                setSelected(v)
              }}
              multiple>
              {({ open }) => (
                <div className='relative'>
                  <Combobox.Input
                    className='w-full rounded-md border bg-white py-1.5 pl-3 pr-10 text-gray-900 focus:ring-2 focus:ring-stonegrey-600 sm:text-sm sm:leading-6'
                    onChange={event => setQuery(event.target.value)}
                    value={query}
                    placeholder={placeholder}
                  />
                  <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </Combobox.Button>

                  {open && query.length > 0 && (
                    <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {(filtered.length > 0
                        ? filtered
                        : options.filter(o => !selected.includes(o))
                      )
                        .slice(0, 5)
                        .map(o => (
                          <Combobox.Option
                            key={o}
                            value={o}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none py-2 pl-3 pr-9',
                                active
                                  ? 'bg-stonegrey-1000 text-white'
                                  : 'text-gray-900'
                              )
                            }>
                            <span className='block truncate'>
                              {getItemLabel(o)}
                            </span>
                          </Combobox.Option>
                        ))}
                    </Combobox.Options>
                  )}
                </div>
              )}
            </Combobox>
          </div>
        )}
        {selected.length > 0 && (
          <div className='flex flex-wrap gap-1'>
            {selected.map(value => (
              <Selection
                key={value}
                name={value}
                unselect={() => setSelected(selected.filter(v => v !== value))}
                showUnselect={options.length > 1}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const Selection = ({
  name,
  unselect,
  showUnselect = true,
}: {
  name: string
  unselect: () => void
  showUnselect?: boolean
}) => {
  return (
    <div className='inline-block rounded bg-stonegrey-1000 px-2 py-1 text-sm text-white'>
      {name + ' '}
      {showUnselect && (
        <button aria-label={`Unselect ${name}`} onClick={unselect}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      )}
    </div>
  )
}

export default MultiSelector
