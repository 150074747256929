import { Dispatch, SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PieChart } from 'ygf_graphs'
import DashboardSection from '../../components/dashboard/DashboardSection'
import { LineGraph } from '../../components/dashboard/LineGraph'
import SelectedSample from '../../components/dashboard/SelectedSample'
import { UserCard, UserCardCarousel } from '../../components/dashboard/UserCard'
import { TextLink } from '../../components/shared/TextLink'
import { CURRENCY_SYMBOL } from '../../constants'
import { useSample } from '../../hooks/useSample'
import { useTransactions } from '../../hooks/useTransactions'
import { State } from '../../store/rootReducer'
import { SampleStates } from '../../types/sample'
import DashboardPage from '../content/DashboardPage'
import { TRIAL_PERSONAS } from '../trial-data'

const ConsumerPersonas = ({ sampleStates }: { sampleStates: SampleStates }) => {
  const params = useParams()
  const navigate = useNavigate()
  const selectedUser = params?.query
  const trial = useSelector(({ trial }: State) => trial)
  const [showNum, setShowNum] = useState(8)
  const [graphCategory, setGraphCategory] = useState<string>()

  const {
    data: actualSample,
    isLoading: sampleIsLoading,
    isSuccess,
  } = useSample({
    gender: sampleStates?.gender?.[0],
    maxAge: sampleStates?.maxAge?.[0],
    maxDate: sampleStates?.maxDate?.[0],
    minAge: sampleStates?.minAge?.[0],
    minDate: sampleStates?.minDate?.[0],
    natRep: sampleStates?.natRep?.[0],
    regions: sampleStates?.regions?.[0],
    sampleSize: sampleStates?.sampleSize?.[0],
    useCoverageDates: sampleStates?.useCoverageDates?.[0],
  })

  const sample = trial ? TRIAL_PERSONAS : actualSample?.sample

  const users = !sampleIsLoading && typeof sample === 'object' ? sample : {}

  if (
    isSuccess &&
    selectedUser &&
    selectedUser.length > 0 &&
    !(selectedUser in users) &&
    trial
  )
    navigate('/dashboard/consumer-personas')

  const currentUser =
    selectedUser && users ? users?.[selectedUser] ?? null : null

  const {
    data: transactionsData,
    isFetching: isFetchingTransactions,
    isError: isErrorTransactions,
  } = useTransactions(selectedUser)

  const {
    barChartData,
    categories: userCategories,
    pieChartData: currentTransactions,
  } = isErrorTransactions
    ? { barChartData: null, categories: [], pieChartData: null }
    : transactionsData

  if (graphCategory === null && userCategories.length > 0)
    setGraphCategory(
      userCategories.indexOf('Supermarkets') > -1
        ? 'Supermarkets'
        : userCategories[0]
    )

  return (
    <DashboardPage
      title='Consumer Personas'
      info='Select a user to see their financial profile.'
      description='Real life examples of financial profiles to enrich marketing and consumer understanding.'>
      {!selectedUser ? (
        sampleIsLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {trial ? (
              <div className='mt-2 max-w-sm rounded-lg bg-stonegrey-400 p-3 text-sm'>
                <span className='mr-2 rounded bg-stonegrey-200 p-1 px-2 text-xs font-bold uppercase'>
                  demo
                </span>
                This is a demo of the Consumer Personas feature. You only have
                access to four profiles to browse. To unlock our full sample of
                10,000+ panelists,{' '}
                <TextLink
                  target='_blank'
                  href='https://business.yougov.com/?marketo=demo'>
                  book a demo call
                </TextLink>
                .
              </div>
            ) : (
              <SelectedSample sampleStates={sampleStates} />
            )}
            <DashboardSection
              title='Select user'
              description='Select a user below (taken from the above sample) to get an in-depth breakdown of their spending.'>
              <div className={selectedUser ? 'max-h-72 overflow-scroll' : ''}>
                <UserCardCarousel
                  selectFunc={(userId: string) => navigate(userId)}
                  selectedUser={selectedUser}
                  users={Object.entries(users)
                    .map(([a, b]) => ({ user_id: a, ...b }))
                    .sort((a, b) => {
                      const first_few_ids = [
                        '11bab403-3d4e-4896-94d2-9dd9a2f1dd25',
                        '71257f9c-0744-4352-8750-7fdbbf80aeaa',
                        'ac6eeb0f-089d-4439-927c-9e3af9accf3f',
                        'b76f9cdc-9169-460a-bade-a9d809771dec',
                        '11bab403-3d4e-4896-94d2-9dd9a2f1dd25',
                      ]
                      if (first_few_ids.includes(a.user_id)) return -1
                      if (first_few_ids.includes(b.user_id)) return 1
                      if (!a.income_bracket) return 1
                      if (!b.income_bracket) return -1
                      const bracketToInt = (bracket: string) =>
                        parseInt(bracket.substring(1, bracket.indexOf('k')))
                      return (
                        bracketToInt(b.income_bracket) -
                        bracketToInt(a.income_bracket)
                      )
                    })
                    .slice(0, showNum)}
                />
                {showNum < Object.keys(users).length && (
                  <div className='flex justify-center'>
                    <button
                      onClick={() => setShowNum(sn => sn + 8)}
                      className='mt-3 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                      Show more
                    </button>
                  </div>
                )}
              </div>
            </DashboardSection>
          </>
        )
      ) : (
        <>
          {selectedUser in users && (
            <DashboardSection
              title='Selected user'
              description='Click on the user to unselect.'>
              <div className='flex flex-col items-start'>
                <UserCard
                  age={currentUser?.age ?? 'Loading...'}
                  gender={currentUser?.gender ?? 'Loading...'}
                  location={currentUser?.region ?? 'Loading...'}
                />
              </div>
            </DashboardSection>
          )}
          <button
            onClick={() => navigate('..')}
            className='mt-3 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-stonegrey-500 focus:ring-offset-2'>
            Browse other users
          </button>
          <UserDataSection
            key={selectedUser}
            {...{
              selectedUser,
              currentTransactions,
              graphCategory,
              setGraphCategory,
              barChartData,
              userCategories,
              loadingData: isFetchingTransactions,
              error: isErrorTransactions,
            }}
          />
        </>
      )}
    </DashboardPage>
  )
}

const UserDataSection = ({
  selectedUser,
  currentTransactions,
  graphCategory,
  setGraphCategory,
  userCategories,
  barChartData,
  loadingData,
  error,
}: {
  selectedUser: string
  currentTransactions: object[]
  graphCategory: string | undefined
  setGraphCategory: Dispatch<SetStateAction<string | undefined>>
  userCategories: string[]
  barChartData:
    | Record<
        string,
        {
          joined_payments: Record<
            string,
            {
              weekly_split: { amount: number[]; count: number[] }
              monthly_split: { amount: number[]; count: number[] }
              four_weekly_split: { amount: number[]; count: number[] }
            }
          >
          years: number[]
        }
      >
    | null
    | undefined
  loadingData: boolean
  error: boolean
}) => (
  <>
    <DashboardSection
      title='Interactive data'
      description='This is an interactive pie chart. Click on the elements to breakdown spending. Click the middle to go back.'>
      <div className='flex'>
        {error ? (
          <div>Something went wrong. Please try again later.</div>
        ) : loadingData || !currentTransactions ? (
          <div>Loading...</div>
        ) : (
          <PieChart
            data={currentTransactions}
            id='user-pie-chart'
            layers={1}
            currency={CURRENCY_SYMBOL}
            width={600}
            height={600}
          />
        )}
      </div>
    </DashboardSection>
    <DashboardSection
      title='User Spend Analysis'
      description="Select an industry and see the user's breakdown for that industry.">
      {error ? (
        <div>Something went wrong. Please try again later.</div>
      ) : loadingData ? (
        <div>Loading...</div>
      ) : selectedUser ? (
        <>
          <div>
            <label htmlFor='industry' className='block text-sm font-medium'>
              Industry
            </label>
            <select
              id='industry'
              name='industry'
              className='mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
              value={graphCategory}
              onChange={e => setGraphCategory(e.target.value)}>
              {userCategories.map(val =>
                val ? (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ) : (
                  <></>
                )
              )}
            </select>
          </div>
          <div className='mt-5'>
            {graphCategory && barChartData?.[graphCategory]?.joined_payments ? (
              <LineGraph
                data={barChartData[graphCategory].joined_payments}
                id='user-line-graph'
                dataStartYear={
                  barChartData[graphCategory].years.length > 0
                    ? barChartData[graphCategory].years[0]
                    : undefined
                }
              />
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </DashboardSection>
  </>
)

export default ConsumerPersonas
