import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import Loading from '../../components/dashboard/Loading'

// types
import { ReactNode } from 'react'
import { State } from '../../store/rootReducer'

type Props = {
  backLink?: string
  children: ReactNode
  description?: string
  info?: string
  title: string
  topButtons?: ReactNode
}

const DashboardPage = ({
  backLink,
  children,
  description,
  info,
  title,
  topButtons = <></>,
}: Props) => {
  if (title) document.title = `${title} | YouGov Finance`

  const hasLoadedUser = useSelector(({ hasLoadedUser }: State) => hasLoadedUser)

  if (!hasLoadedUser) return <Loading />
  return (
    <div className='mx-auto flex max-w-full flex-row justify-center px-6 py-6 md:px-12 md:py-12'>
      <div className='w-full max-w-5xl'>
        <div className='flex flex-row justify-between'>
          <div className='text-3xl font-bold'>
            {backLink && (
              <Link to={backLink} className='mr-2 text-black'>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Link>
            )}
            <h2 className='inline'>{title}</h2>
            {info && (
              <span className='ml-2 text-xl leading-5 text-gray-700 hover:text-gray-800'>
                <FontAwesomeIcon
                  aria-hidden='false'
                  data-for={title}
                  data-tip={'<div class="w-52">' + info + '</div>'}
                  data-html={true}
                  icon={faInfoCircle}
                />
                <ReactTooltip
                  className='font-normal'
                  effect='solid'
                  id={title}
                  multiline={true}
                  place='bottom'
                />
              </span>
            )}
          </div>
          <div>{topButtons}</div>
        </div>
        {description && <div>{description}</div>}
        {children}
      </div>
    </div>
  )
}

export default DashboardPage
