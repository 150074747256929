import { Range, Time, createChart } from 'lightweight-charts'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { colors } from '../../colors.js'
import { CURRENCY_SYMBOL } from '../../constants'

type Data = { date: Date; values: Record<string, number> }[]
const LightWeightChartWrapper = ({
  className,
  colors: propColors,
  currency = '£',
  data: dataIn,
  height,
  id,
  showDateDay = true,
  stacked,
  style,
  timeRange,
  width,
}: {
  className?: string
  colors?: { [key: string]: string }
  currency?: string
  data: Data
  height?: number
  id: string
  showDateDay?: boolean
  stacked: boolean
  style?: { [key: string]: string }
  timeRange?: Range<Time>
  width?: number
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const data = getDataSeries(dataIn)

  let colorsToUse = Object.values(colors)
  if (
    propColors &&
    Array.isArray(propColors) &&
    propColors.length >= Object.keys(data).length
  ) {
    colorsToUse = propColors
  }

  const colorDict = useMemo(() => {
    const res: { [key: string]: string } = {}
    Object.keys(data).forEach((k, i) => {
      res[k] = colorsToUse[i]
    })
    return res
  }, [colorsToUse, data])
  const color = useCallback(
    (key: keyof typeof colorDict) => colorDict[key],
    [colorDict]
  )

  // subscribe to container width
  const [containerWidth, setContainerWidth] = useState(0)
  useEffect(() => {
    const container = containerRef.current
    window.addEventListener('resize', () => {
      if (!container) return
      setContainerWidth(container.offsetWidth)
    })
    if (container) setContainerWidth(container.offsetWidth)
    return () =>
      window.removeEventListener('resize', () => {
        if (!container) return
        setContainerWidth(container.offsetWidth)
      })
  }, [])

  useEffect(() => {
    const obj = document.getElementById(id)
    if (obj) obj.innerHTML = ''
    const chart = createChart(id, {
      height: height ?? 300,
      layout: { fontFamily: 'Graphik, Rawline' },
      localization: {
        dateFormat: showDateDay ? 'dd MMM yyyy' : 'MMM yyyy',
        priceFormatter: (value: number) =>
          `${currency ?? CURRENCY_SYMBOL}${(
            Math.round(value * 100) / 100
          ).toLocaleString()}`,
      },
      timeScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
      },
      rightPriceScale: {
        scaleMargins: {
          bottom: 0,
          top: 0.2,
        },
      },
      width: width ?? containerWidth,
    })
    const entries = Object.entries(data).sort((a, b) =>
      a[0] === 'Other' ? 1 : b[0] === 'Other' ? -1 : a[0].localeCompare(b[0])
    )

    if (stacked && entries.length >= 1) {
      entries.reverse()
      for (let i = 1; i < entries.length; i++)
        for (let j = 0; j < entries[i][1].length; j++)
          entries[i][1][j].value += entries[i - 1][1][j].value
      entries.reverse()
    }
    entries.forEach(([key, values]) =>
      chart
        .addAreaSeries({
          bottomColor: `${color(key)}${stacked ? '' : '02'}`,
          lineColor: color(key),
          lineWidth: 2,
          topColor: `${color(key)}${stacked ? '' : '66'}`,
        })
        .setData(values)
    )
    if (timeRange) chart.timeScale().setVisibleRange(timeRange)
  }, [
    containerWidth,
    data,
    id,
    stacked,
    timeRange,
    width,
    height,
    showDateDay,
    currency,
    color,
  ])

  return (
    <div ref={containerRef} className='overflow-hidden'>
      <div className='w-0 overflow-visible'>
        <div id={id} className={className} style={style} />
        <div className='overflow-hidden'>
          {Object.keys(data)
            .sort((a, b) =>
              a === 'Other' ? 1 : b === 'Other' ? -1 : a.localeCompare(b)
            )
            .map(key => (
              <div key={key} className='float-left mb-1 mr-4 text-xs leading-5'>
                <div
                  className='float-left mr-1 mt-0.5 h-3.5 w-3.5 rounded-full'
                  style={{ backgroundColor: color(key) }}
                />
                {key}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

type DataSeries = Record<string, { time: string; value: number }[]>
const getDataSeries = (data: Data) => {
  // data is in format [{ date: '2019-04-11', values: { Uber: 80.01, Bolt: 80.2 }, ... }]
  // we want data in format { Uber: [{ time: '2019-04-11', value: 80.01 }, ...], Bolt: [{ time: '2019-04-11', value: 80.2 }, ...]}
  const dataSeries: DataSeries = {}
  data.forEach(d => {
    Object.keys(d.values).forEach(key => {
      if (!dataSeries[key]) {
        dataSeries[key] = []
      }

      const date = new Date(d.date)
      // convert date to string in format YYYY-MM-DD
      const dateString = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`
      dataSeries[key].push({ time: dateString, value: d.values[key] })
    })
  })

  if (Object.keys(dataSeries).length > 10) {
    // combine smallest into 'Other' key
    const otherKeys = Object.entries(dataSeries)
      .map(([key, values]) => ({
        key,
        value: values.reduce((acc, cur) => acc + cur.value, 0),
      }))
      .sort((a, b) => a.value - b.value)
      .slice(0, Object.keys(dataSeries).length - 10)
      .map(d => d.key)

    const newDataSeries: DataSeries = Object.entries(dataSeries)
      .filter(([key]) => otherKeys.indexOf(key) === -1)
      .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})

    newDataSeries.Other = Object.entries(
      otherKeys.reduce((acc, cur) => {
        const current = dataSeries[cur]
        current.forEach(({ time, value }) => {
          if (!acc[time]) acc[time] = 0
          acc[time] += value
        })
        return acc
      }, {} as Record<string, number>)
    ).reduce(
      (acc, [time, value]) => [...acc, { time, value }],
      [] as { time: string; value: number }[]
    )

    return newDataSeries
  }
  return dataSeries
}

export default LightWeightChartWrapper
