export const freeData = {
  report_id: 'free',
  timestamp: '2024-04-11T09:50:24.996Z',
  email: '',
  brand: 'TV Licensing',
  sample_id: '14d51235-f710-4052-a32f-fd5f055e55de',
  status: 'finished',
  payment_status: 'payment_success',
  checkout_id: '6617b2613133430d855bfe79',
  avg_amount: '-19.0',
  avg_freq_days: '36.9',
  avg_total_month: '-40.7',
  avg_nr_trans_month: '1.1',
  nr_trans: '17551',
  nr_users: '1896',
  region_dist: {
    North: 0.258,
    South: 0.25,
    Midlands: 0.157,
    East: 0.102,
    London: 0.088,
    Scotland: 0.08,
    Wales: 0.052,
    'Northern Ireland': 0.012,
    Unknown: 0.002,
  },
  avg_age: '54.7',
  age_dist: {
    '66-70': 0.162,
    '51-55': 0.118,
    '71-75': 0.104,
    '41-45': 0.1,
    '56-60': 0.092,
    '36-40': 0.087,
    '46-50': 0.081,
    '61-65': 0.077,
    '31-35': 0.068,
    '76+': 0.056,
    '26-30': 0.04,
    '18-25': 0.015,
  },
  gend_dist: {
    Female: 0.55,
    Male: 0.45,
  },
  top_shop_dist: {
    Streaming: {
      Netflix: 0.4673,
      'Prime Video': 0.2243,
      'Now TV': 0.1342,
      'Disney+': 0.1054,
      BritBox: 0.0255,
      'Discovery+': 0.0127,
      'ITV Hub': 0.011,
      'Paramount+': 0.0059,
      'Channel 4': 0.0051,
      Mubi: 0.0025,
      Hayu: 0.0017,
      'Acorn TV': 0.0017,
      'NFL GamePass': 0.0008,
      Crunchyroll: 0.0008,
      'BFI Player': 0.0008,
    },
    Supermarkets: {
      Tesco: 0.2969,
      "Sainsbury's": 0.2337,
      Asda: 0.1206,
      "Morrison's": 0.0813,
      Aldi: 0.0807,
      Lidl: 0.0616,
      'Co-op Group Food': 0.0499,
      Waitrose: 0.0218,
      Iceland: 0.0159,
      Costco: 0.0159,
      SPAR: 0.0085,
      'One Stop': 0.0032,
      Nisa: 0.0021,
      FarmFoods: 0.0016,
      Costcutter: 0.0016,
      'Holland & Barrett': 0.0011,
      'Abel & Cole': 0.0011,
      'Riverford Organic Farmers': 0.0005,
      Budgens: 0.0005,
      'Booker Wholesale': 0.0005,
      'Heron Foods': 0.0005,
      'The Co-operative': 0.0005,
    },
    Takeout: {
      'Just Eat': 0.568,
      'Uber Eats': 0.2363,
      Deliveroo: 0.1956,
    },
  },
  switch_dist: null,
  weekly_totals: {
    '2022': [
      -17135.47, -2650.83, -1957.84, -2329.85, -16181.25, -2685.08, -2721.73,
      -2453.73, -17428.04, -2191.64, -1686.78, -2742.3, -16619.49, -2640.85,
      -139.6, -4463.79, -653.52, -16782.17, -36347.19, -2037.04, -2227.64,
      -16871.72, -2029.61, -2115.5, -2296.78, -16984.88, -2694.08, -2071.58,
      -2958.33, -3204.21, -16539.98, -3121.44, -2464.66, -2635.5, -17774.61,
      -2638.19, -2044.98, -2860.59, -2597.15, -17315.38, -2472.14, -1803.03,
      -3218.82, -17138.47, -2630.06, -2436.78, -2758.48, -17409.36, -2937.93,
      -2233.84, -2762.76, -545.33, 0,
    ],
  },
  monthly_totals: {
    '2022': [
      -24128.68, -24071.17, -26277.29, -22219.65, -59470.74, -23224.04,
      -25932.45, -27259.72, -23099.93, -27186.76, -25274.39, -25497.18,
    ],
  },
  four_weekly_totals: {
    '2022': [
      -24073.99, -24041.79, -24048.76, -23863.73, -55819.92, -23244.47,
      -24047.32, -25823.96, -25512.96, -24818.1, -24632.46, -25234.68, -8479.86,
      0,
    ],
  },
  weekly_counts: {
    '2022': [
      1020, 145, 112, 133, 1028, 138, 119, 142, 1050, 132, 115, 146, 1052, 144,
      9, 248, 18, 1073, 143, 116, 128, 1048, 138, 113, 142, 1065, 145, 116, 145,
      159, 956, 154, 112, 133, 1062, 147, 109, 146, 146, 971, 138, 112, 127,
      1065, 141, 120, 145, 1064, 141, 118, 143, 19, 0,
    ],
  },
  monthly_counts: {
    '2022': [
      1414, 1429, 1584, 1325, 1593, 1450, 1489, 1501, 1326, 1489, 1476, 1475,
    ],
  },
  four_weekly_counts: {
    '2022': [
      1410, 1427, 1443, 1453, 1350, 1427, 1468, 1414, 1454, 1372, 1442, 1470,
      421, 0,
    ],
  },
  last_reinvoke_hour: null,
}
