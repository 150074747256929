import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { PortalCountrySwitcher } from '../portal-country-switcher'
import { Button } from './Button'
import Logo from './Logo'
import { TextLink } from './TextLink'

const NavBar = () => (
  <Popover className='relative z-10'>
    <div className='mx-auto max-w-7xl px-4 sm:px-6'>
      <div className='flex items-center justify-between py-6 md:justify-start md:space-x-10'>
        <div className='flex items-center justify-start gap-4 lg:w-0 lg:flex-1'>
          <Link to='/'>
            <span className='sr-only'>YouGov Finance</span>
            <Logo height='20px' color='#FF412C' />
          </Link>
          <PortalCountrySwitcher />
        </div>

        <div className='-my-2 -mr-2 md:hidden'>
          <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 '>
            <span className='sr-only'>Open menu</span>
            <MenuIcon className='h-6 w-6' aria-hidden='true' />
          </Popover.Button>
        </div>
        <div className='hidden items-center justify-end gap-4 md:flex md:flex-1 lg:w-0'>
          <TextLink
            as='link'
            className='whitespace-nowrap font-semibold'
            to='/login'
            showVisited={false}
            variant='standalone'>
            Sign in
          </TextLink>
          <Button
            as='a'
            variant='gradient'
            href='https://platform.yougov.com/register/finance'>
            Register
          </Button>
        </div>
      </div>
    </div>

    <Transition
      as={Fragment}
      enter='duration-200 ease-out'
      enterFrom='opacity-0 scale-95'
      enterTo='opacity-100 scale-100'
      leave='duration-100 ease-in'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-95'>
      <Popover.Panel
        focus
        className='absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden'>
        <div className='divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
          <div className='px-5 pb-6 pt-5'>
            <div className='flex items-center justify-between'>
              <div>
                <Logo height='20px' color='black' />
              </div>
              <div className='-mr-2'>
                <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset '>
                  <span className='sr-only'>Close menu</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </Popover.Button>
              </div>
            </div>
            <div className='mt-6'>
              <nav className='grid gap-y-8'></nav>
            </div>
          </div>
          <div className='space-y-6 px-5 py-6'>
            <div className='flex flex-col items-center'>
              <Button
                as='a'
                variant='gradient'
                href='https://platform.yougov.com/register/finance'>
                Register
              </Button>
              <p className='mt-6 text-center text-base font-medium text-gray-500'>
                Existing customer?{' '}
                <Link
                  to='/login'
                  className='text-yougov-orange hover:text-yougov-pink'>
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
)

export default NavBar
