import { useState } from 'react'
import { Table as BootTable } from 'react-bootstrap'

const ROWS_TO_SHOW = 5

const Table = ({ data: { headers, body } }) => {
  const [showAllRows, setShowAllRows] = useState(false)

  const N = body.length
  const rows = showAllRows ? body : body.slice(0, Math.min(N, ROWS_TO_SHOW))

  return (
    <BootTable hover className='leading-7'>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            {row.map((cell, index) => (
              <td key={index}>{cell}</td>
            ))}
          </tr>
        ))}
        {N > ROWS_TO_SHOW && (
          <tr>
            <td
              colspan={body[0].length}
              onClick={() => setShowAllRows(s => !s)}
              className='cursor-pointer text-center'>
              {showAllRows ? 'Hide rows' : 'Show all rows'}
            </td>
          </tr>
        )}
      </tbody>
    </BootTable>
  )
}

export default Table
