import { ReactNode } from 'react'

export const SectionHeading = ({
  children,
  className,
  number,
  total,
  ...props
}: {
  children: ReactNode
  className?: string
  number: number
  total?: number
}) => (
  <h2
    className={
      className +
      ' inline-flex items-center rounded-full px-4 py-1 text-sm uppercase text-black  ring-1 ring-inset ring-black'
    }
    {...props}>
    <span className='font-mono text-sm' aria-hidden='true'>
      {number.toString().padStart(2, '0')}
      {total !== undefined && `/${total.toString().padStart(2, '0')}`}
    </span>
    <span className='ml-3 h-3.5 w-px bg-black/20' />
    <span className='ml-3 text-sm font-semibold  tracking-tight'>
      {children}
    </span>
  </h2>
)
