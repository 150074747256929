import {
  faAngleDown,
  faArrowRight,
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom'
import Loading from '../../components/dashboard/Loading'
import { PortalCountrySwitcher } from '../../components/portal-country-switcher'
import Logo from '../../components/shared/Logo'
import { TextLink } from '../../components/shared/TextLink'
import LoginManager from '../../helpers/LoginManager'
import Redirect from '../../helpers/Redirect'
import { useBrands } from '../../hooks/useBrands'
import { State, User } from '../../store/rootReducer'
import store from '../../store/store'
import { RouteType } from '../routes'

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

export type BrandOption = {
  brand: string
  category: string[]
  logo_link: string
}
type ContextType = {
  brandOptions: BrandOption[]
}

export const useBrandOptionsContext = () => useOutletContext<ContextType>()

const DashboardLayout = ({ pages }: { pages: RouteType[] }) => {
  const hasLoadedUser = useSelector(({ hasLoadedUser }: State) => hasLoadedUser)
  const user = useSelector(({ user }: State) => user)
  const groups = useSelector(({ userGroups }: State) => userGroups)
  const trial = useSelector(({ trial }: State) => trial)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsMenuOpen(!isMenuOpen)
  }
  const closeUserMenu = () => setIsMenuOpen(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const { data: brandOptions } = useBrands()

  const pathname = location.pathname

  useEffect(() => {
    document.addEventListener('click', closeUserMenu)
    return () => document.removeEventListener('click', closeUserMenu)
  }, [])

  useEffect(() => {
    const nav = document.getElementById('nav')
    const trigger = document.getElementById('trigger')
    if (!nav || !trigger) return

    // @ts-expect-error expanded added by web component
    nav.addEventListener('closed', () => (nav.expanded = false))
    // @ts-expect-error expanded added by web component
    trigger.addEventListener('click', () => (nav.expanded = true))
    // @ts-expect-error expanded added by web component
    nav.removeEventListener('closed', () => (nav.expanded = false))
    // @ts-expect-error expanded added by web component
    trigger.removeEventListener('click', () => (nav.expanded = true))
  }, [])

  const getHeader = () => {
    return (
      <div className='fixed sticky flex-1 border-b border-gray-300 bg-white'>
        <yg-sidebar
          id='nav'
          login-url='https://login.yougov.com/?client_id=yougov-finance'></yg-sidebar>
        <div className='flex justify-start px-1 py-3'>
          <div className='flex w-full items-center'>
            <div className='hidden md:block'>
              <yg-sidebar-trigger
                id='trigger'
                className='hidden'
                style={{
                  '--sidebar-trigger-color': 'rgb(55, 65, 81)',
                  '--sidebar-hover-color': 'rgb(229, 231, 235)',
                }}></yg-sidebar-trigger>
            </div>
            <div className='block px-2 pt-1 md:hidden'>
              <button
                type='button'
                className='text-stonegrey-500 hover:text-stonegrey-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                onClick={() => setSidebarOpen(true)}>
                <span className='sr-only'>Open sidebar</span>
                <MenuIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
            <div className=''>
              <Link to='/dashboard'>
                <Logo height={14} />
              </Link>
            </div>
            <div className='ml-2'>
              <PortalCountrySwitcher />
            </div>
            {/* spacer div */}
            <div className='flex-1'></div>
            <div className='flex'>
              {hasLoadedUser &&
                'attributes' in user &&
                user.attributes?.email && (
                  <div className='relative inline-block text-left text-xs'>
                    <button
                      className='mr-2 flex flex-row gap-2 px-2 leading-8'
                      onClick={e => {
                        handleMenuClick(e)
                      }}>
                      <b>{user.attributes.email}</b>{' '}
                      <div className='h-8 w-8 rounded-full bg-gray-200 text-center font-bold capitalize'>
                        {user.attributes.email.substring(0, 1)}
                      </div>
                      <span>
                        {!isMenuOpen ? (
                          <FontAwesomeIcon
                            className='delay-50 rotate-0 transform transition ease-in-out'
                            icon={faAngleDown}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className='delay-50 rotate-180 transform transition ease-in-out'
                            icon={faAngleDown}
                          />
                        )}
                      </span>
                    </button>
                    {isMenuOpen && (
                      <div className='absolute right-8 top-10 z-10 w-36 origin-top-right border border-gray-800 bg-white'>
                        <div className=''>
                          <button
                            className='w-full px-3 py-2.5 text-left font-bold text-gray-800 hover:bg-gray-100'
                            onClick={LoginManager.signOut}>
                            Logout
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Redirect ifAuthStateIs={false}>
      <div id='dashboard'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 md:hidden'
            onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'>
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'>
                    <div className='absolute right-0 top-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}>
                        <span className='sr-only'>Close sidebar</span>
                        <XIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex items-center bg-white py-4 pl-4'>
                    {/* this is the menu icon on short */}
                    <yg-sidebar-trigger
                      id='trigger'
                      className='hidden'
                      style={{
                        '--sidebar-trigger-color': 'rgb(55, 65, 81)',
                        '--sidebar-hover-color': 'rgb(229, 231, 235)',
                      }}></yg-sidebar-trigger>
                    <Link to='/dashboard'>
                      <Logo height={16} />
                    </Link>
                  </div>
                  <SideBarContent
                    {...{
                      hasLoadedUser,
                      pages,
                      pathname,
                      user,
                    }}
                  />
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0'></div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className='flex h-screen flex-col'>
          <div className='sticky top-0 z-10 bg-white'>{getHeader()}</div>

          <div className='flex flex-1 justify-start'>
            <div className='flex hidden min-h-screen flex-col md:block'>
              <SideBarContent {...{ hasLoadedUser, pages, pathname, user }} />
            </div>

            <div className='w-4/5 flex-1 overflow-y-auto bg-white'>
              <main className=''>
                {Array.isArray(groups) && groups.includes('internal') && (
                  <div className='border-b p-2 text-center text-sm'>
                    <span className='mr-2 rounded bg-stonegrey-200 p-1 px-2 text-xs font-bold uppercase'>
                      internal
                    </span>
                    You are logged in as an internal user. You can toggle the
                    trial mode on and off.
                    <button
                      className='ml-2 underline'
                      onClick={() => {
                        store.dispatch({ type: 'changeTrial', payload: !trial })
                      }}>
                      {trial ? 'Disable' : 'Enable'} trial mode
                    </button>
                  </div>
                )}
                {trial && (
                  <div className='border-b p-2 text-center text-sm'>
                    <span className='mr-2 rounded bg-stonegrey-200 p-1 px-2 text-xs font-bold uppercase'>
                      demo
                    </span>
                    This is a demo version of YouGov Finance. To get full
                    access,{' '}
                    <TextLink
                      target='_blank'
                      href='https://business.yougov.com/?marketo=demo'>
                      book a demo call
                    </TextLink>
                    .
                  </div>
                )}
                <div className='min-h-screen'>
                  <div id='dashboard-content'>
                    <Outlet context={{ brandOptions } satisfies ContextType} />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </Redirect>
  )
}

export default DashboardLayout

const SideBarLink = ({
  depth,
  icon,
  link,
  pathname,
  subroutes,
  title,
}: {
  depth: number
  icon: typeof faHome
  link: string
  pathname: string
  subroutes?: RouteType[]
  title: string
}) => {
  if (link.includes(':')) return <></>

  const isActive =
    pathname === `/dashboard/${link}` ||
    ((!subroutes || subroutes.length === 0) &&
      pathname.startsWith(`/dashboard/${link}`))

  return (
    <>
      <Link
        to={link}
        className={classNames(
          isActive ? 'bg-gray-200' : '',
          `group flex flex-row items-center gap-2 rounded-sm px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 ml-${
            depth * 4
          }`
        )}>
        <FontAwesomeIcon icon={icon} />
        <div className='flex flex-1 flex-row items-center'>{title}</div>
        <div>{isActive && <FontAwesomeIcon icon={faArrowRight} />}</div>
      </Link>
      {subroutes &&
        subroutes.map(
          item =>
            item !== 'divider' &&
            item !== 'loader' && (
              <SideBarLink
                key={item.link}
                depth={depth + 1}
                {...item}
                link={`${link}/${item.link}`}
                pathname={pathname}
              />
            )
        )}
    </>
  )
}

const SideBarContent = ({
  pages,
  pathname,
}: {
  hasLoadedUser: boolean
  pages: RouteType[]
  pathname: string
  user: User
}) => (
  <div className='flex min-h-full max-w-xs flex-1 flex-col overflow-y-auto border-r bg-white'>
    <nav className='flex-1 pt-3'>
      {pages.map((item, index) => {
        if (item === 'divider')
          return <hr key={index} className='my-3 bg-white' />
        else if (item === 'loader') return <Loading />
        else
          return (
            <div className='my-1 px-3' key={item.link}>
              <SideBarLink depth={0} pathname={pathname} {...item} />
            </div>
          )
      })}
    </nav>
  </div>
)

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'yg-sidebar': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'yg-sidebar-trigger': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

declare module 'csstype' {
  interface Properties {
    '--sidebar-trigger-color'?: string
    '--sidebar-hover-color'?: string
  }
}
