import { TextLink } from '../../shared/TextLink'
import { Timeframe } from './SelectTimeframe'
import { Brand } from './brands'

const PaymentPage = ({
  brand,
  checkoutId,
  reportId,
  timeframe,
}: {
  brand: Brand
  checkoutId: string | undefined
  reportId: string | undefined
  timeframe: Timeframe
}) => {
  return (
    <div>
      <p className='mb-4'>
        Your{' '}
        <b>
          {brand.name} {timeframe.title} Brand Report
        </b>{' '}
        Purchase is Complete!
      </p>
      <p className='mb-4'>
        Thank you for purchasing your {brand.name} {timeframe.title} Brand
        Report on YouGov Finance. Your transaction has been successfully
        processed, and we're thrilled to assist you in gaining valuable insights
        into your brand.
      </p>
      <p className='mb-4 font-bold'>Order Details:</p>
      <ul className='mb-4 list-disc'>
        {checkoutId && <li>Order Number: {checkoutId}</li>}
        <li>
          Report Name: {brand.name} {timeframe.title} Report
        </li>
        <li>Date of Purchase: {new Date().toLocaleDateString()}</li>
      </ul>
      <p className='mb-4 font-bold'>What's Next?</p>
      <ol className='mb-4 list-decimal'>
        <li>
          <b>Report Delivery:</b> Your Brand Report is ready to view at{' '}
          <TextLink to={`/dashboard/report/${reportId ?? ''}`}>
            business.yougov.finance/dashboard/report
            {reportId ? '/' + reportId : ''}
          </TextLink>
          . You can also access your report by logging into your YouGov Finance
          account and locating it in the "Reports" section of the menu.
        </li>
        <li>
          <b>Feedback:</b> We value your feedback! After you have had the chance
          to review your Brand Report, we invite you to share your thoughts and
          suggestions with us. Your input helps us continuously improve our
          services.
        </li>
        <li>
          <b>Explore More:</b> Discover additional resources and services on our
          platform to enhance your brand's success. We offer a range of tools
          and expertise to help you achieve your goals.{' '}
        </li>
      </ol>
      <p>
        Thank you once again for choosing our platform. We look forward to
        assisting you in achieving a stronger and more successful brand
        presence. If you have any further questions or need assistance at any
        point in the future, please do not hesitate to reach out.
      </p>
    </div>
  )
}

export default PaymentPage
