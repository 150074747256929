import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { Dispatch, SetStateAction } from 'react'

import { DateString } from '../../../types/shared'

export type Timeframe = {
  id: number
  title: string
  description: string
  users: string
  min_date: DateString
  max_date: DateString
}

export const timeframes: Timeframe[] = [
  {
    id: 1,
    title: '2022',
    description: 'Report for the selected brand for the year 2022.',
    users: 'NatRep sample of 2,500+ users.',
    min_date: '2022-01-01',
    max_date: '2022-12-31',
  },
  {
    id: 2,
    title: '2021',
    description: 'Report for the selected brand for the year 2021.',
    users: 'NatRep sample of 2,500+ users.',
    min_date: '2021-01-01',
    max_date: '2022-12-31',
  },
]

const SelectTimeframe = ({
  timeframe,
  setTimeframe,
}: {
  timeframe: Timeframe
  setTimeframe: Dispatch<SetStateAction<Timeframe>>
}) => (
  <RadioGroup value={timeframe} onChange={setTimeframe}>
    <div className='mb-4 mt-4 grid grid-cols-1 justify-center gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
      {timeframes.map(t => (
        <RadioGroup.Option
          key={t.id}
          value={t}
          className={({ checked }) =>
            (checked
              ? 'border-2 border-indigo-500'
              : 'border-grey-300 border') +
            ' relative flex cursor-pointer rounded-lg bg-white p-4 shadow-sm focus:outline-none'
          }>
          {({ checked }) => (
            <>
              <span className='flex flex-1'>
                <span className='flex flex-col'>
                  <RadioGroup.Label
                    as='span'
                    className='block w-2/3 text-3xl font-semibold tracking-tighter text-gray-900'>
                    {t.title}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as='span'
                    className='mt-1 flex items-center text-sm text-gray-500'>
                    {t.description}
                  </RadioGroup.Description>
                  <RadioGroup.Description
                    as='span'
                    className='mt-6 text-sm font-medium text-gray-900'>
                    {t.users}
                  </RadioGroup.Description>
                </span>
              </span>
              <CheckCircleIcon
                className={
                  (!checked ? 'invisible' : '') + ' h-5 w-5 text-indigo-600'
                }
                aria-hidden='true'
              />
            </>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
)

export default SelectTimeframe
