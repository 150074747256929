import {
  faBagShopping,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

export const AssociatedBrands = ({
  brandLogos,
  getLink,
  switchFrom,
  switchTo,
  topSupermarket,
  topStreaming,
  topTakeout,
}: {
  brandLogos: Record<string, string>
  getLink?: (brand: string) => string | undefined
  switchFrom?: [string, number][]
  switchTo?: [string, number][]
  topSupermarket: [string, number]
  topStreaming: [string, number]
  topTakeout: [string, number]
}) => {
  const from = switchFrom ?? ([] as [string, number][])
  const to = switchTo ?? ([] as [string, number][])

  const getSwitchedTo = (brand: string) => to.find(b => b[0] === brand)
  const getSwitchedFrom = (brand: string) => from.find(b => b[0] === brand)

  // pass the formatted data to build the markup
  return (
    <>
      <AssociatedBrand
        actionPhrase='Shop at'
        brand={topSupermarket[0]}
        link={(getLink && getLink(topSupermarket[0])) || undefined}
        from={getSwitchedFrom(topSupermarket[0])}
        logo={brandLogos[topSupermarket[0]]}
        to={getSwitchedTo(topSupermarket[0])}
        topValue={Math.round(topSupermarket[1] * 100)}
      />
      <AssociatedBrand
        actionPhrase='Get takeout from'
        brand={topTakeout[0]}
        link={(getLink && getLink(topTakeout[0])) || undefined}
        from={getSwitchedFrom(topTakeout[0])}
        logo={brandLogos[topTakeout[0]]}
        to={getSwitchedTo(topTakeout[0])}
        topValue={Math.round(topStreaming[1] * 100)}
      />
      <AssociatedBrand
        actionPhrase='Stream on'
        brand={topStreaming[0]}
        link={(getLink && getLink(topStreaming[0])) || undefined}
        from={getSwitchedFrom(topStreaming[0])}
        logo={brandLogos[topStreaming[0]]}
        to={getSwitchedTo(topStreaming[0])}
        topValue={Math.round(topStreaming[1] * 100)}
      />
    </>
  )
}

const AssociatedBrand = ({
  actionPhrase,
  brand,
  from,
  link,
  logo,
  to,
  topValue,
}: {
  actionPhrase: string
  brand: string
  from: [string, number] | undefined
  link?: string | undefined
  logo: string
  to: [string, number] | undefined
  topValue: number
}) => {
  const streamOnShopAtIcon = (
    <IconWithBackground
      icon={faBagShopping}
      colorClass='text-blue-500'
      backgroundColorClass='bg-blue-100'
    />
  )

  const className = 'block w-1/3 rounded border-2 border-gray-200 px-3'
  const Container = ({ children }: { children: ReactNode }) =>
    link ? (
      <Link to={link} className={className}>
        {children}
      </Link>
    ) : (
      <div className={className}>{children}</div>
    )

  return (
    <Container>
      <div className='px-2 py-4 text-xl font-bold'>
        <img
          alt={`${brand} logo`}
          src={`https://logo.clearbit.com/${logo}`}
          className='mr-2 inline-block h-auto w-7'
          onError={e => ((e.target as HTMLImageElement).style.display = 'none')}
        />
        {brand}
      </div>
      <AssociatedBrandRow
        value={topValue}
        icon={streamOnShopAtIcon}
        title={`${actionPhrase} ${brand}`}
      />
      {from && (
        <AssociatedBrandRow
          value={Math.round(from[1] * 100)}
          icon={
            <IconWithBackground
              icon={faPlus}
              colorClass='text-green-700'
              backgroundColorClass='bg-green-200'
            />
          }
          title={`Switched from ${from[0]}`}
        />
      )}
      {to && (
        <AssociatedBrandRow
          value={Math.round(to[1] * 100)}
          icon={
            <IconWithBackground
              icon={faMinus}
              colorClass='text-red-700'
              backgroundColorClass='bg-red-200'
            />
          }
          title={`Switched to ${to[0]}`}
        />
      )}
    </Container>
  )
}

const AssociatedBrandRow = ({
  icon,
  title,
  value,
}: {
  icon: ReactNode
  title: string
  value: number
}) => (
  <div className='border-t-2 border-gray-200 px-2 py-4'>
    <div className='flex content-center items-center gap-2'>
      <div className='text-2xl'>{icon}</div>
      <div className='text-2xl font-bold'>{value}%</div>
    </div>
    <div className='text-xs'>{title}</div>
  </div>
)

const IconWithBackground = ({
  backgroundColorClass,
  colorClass,
  icon,
}: {
  backgroundColorClass: string
  colorClass: string
  icon: typeof faBagShopping
}) => (
  <div
    className={`flex h-6 w-6 flex-col justify-center rounded-full bg-blue-100 text-center ${backgroundColorClass}`}>
    <FontAwesomeIcon
      className={`fa-regular fa-sharp bg-transparent text-xs ${colorClass}`}
      icon={icon}
    />
  </div>
)
