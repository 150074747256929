import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps } from 'react'
import ReactTooltip from 'react-tooltip'
import Loading from '../../components/dashboard/Loading'
import { Sample } from '../../hooks/useSample'

const SampleInfoContent = ({ sample }: { sample: Sample }) => {
  const ageKey = (age: number | string): string => {
    if (typeof age === 'string') return age
    if (age < 25) return '18-24'
    if (age >= 65) return '65+'
    return `${age + 5 - ((age + 5) % 10) - 5}-${age + 5 - ((age + 5) % 10) + 4}`
  }

  const ageSplit = splitSampleVariables(sample, 'age', ageKey)
  const genderSplit = splitSampleVariables(sample, 'gender')
  const locationSplit = splitSampleVariables(sample, 'region')

  const N = Object.keys(sample ?? {}).length

  const ageData = Object.entries(ageSplit)
    .sort((a, b) => a[0].substring(0, 2).localeCompare(b[0].substring(0, 2)))
    .filter(x => x[0] !== 'null')
  const genderData = Object.entries(genderSplit)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .filter(x => x[0] !== 'null')
  const locationData = Object.entries(locationSplit)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .filter(x => x[0] !== 'null')

  return (
    <div className='flex flex-col gap-4'>
      <header className='text-xl font-bold'>Sample info</header>
      <div>
        <div className='flex flex-row xl:justify-between'>
          <div className='pr-4 font-bold'>Sample size</div>
          <div>
            {N.toLocaleString()}{' '}
            <FontAwesomeIcon
              data-for='sample-size'
              data-tip={
                '<div class="w-52">The sample size might be smaller than what you selected if the demographic filters you selected restrict the sample.</div>'
              }
              data-html={true}
              icon={faInfoCircle}
              className='text-xs'
            />
            <ReactTooltip
              className='font-normal'
              effect='solid'
              id='sample-size'
              multiline={true}
              place='bottom'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-between gap-4 lg:flex-row xl:flex-col'>
        <SplitSection data={ageData} N={N} title='Age split' />
        <SplitSection data={genderData} N={N} title='Gender split' />
        <SplitSection data={locationData} N={N} title='Location split' />
      </div>
    </div>
  )
}

const SplitSection = ({
  data,
  N,
  title,
}: {
  data: [string, number][]
  N: number
  title: string
}) => {
  if (N === 0 || data.length === 0) return null
  data = data.filter(x => Math.round((x[1] / N) * 100) > 0)
  return (
    <div>
      <b>{title}</b>
      <div>
        {data.map(([label, count]) => (
          <div className='flex justify-between'>
            <div className='text-ellipsis py-1 text-sm'>{label}</div>
            <div className='py-1 text-sm'>{Math.round((count / N) * 100)}%</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const SampleInfo = ({
  sample,
  isLoadingSample,
  sampleError,
}: {
  sample: Sample | undefined
  isLoadingSample: boolean
  sampleError: unknown
}) => (
  <>
    {!isLoadingSample && !sampleError && sample ? (
      <SampleInfoContent {...{ sample }} />
    ) : (
      <>
        <Loading /> Loading sample info...
      </>
    )}
  </>
)

export const SampleInfoCol = (props: ComponentProps<typeof SampleInfo>) => (
  <div className='hidden min-h-screen w-60 border-l border-gray-300 bg-gray-200 px-8 pt-6 xl:block'>
    <SampleInfo {...props} />
  </div>
)

export const SampleInfoBox = (props: ComponentProps<typeof SampleInfo>) => (
  <div className='block rounded-lg border-gray-300 bg-gray-200 p-6 xl:hidden'>
    <SampleInfo {...props} />
  </div>
)

const splitSampleVariables = (
  sample: Sample,
  v: 'age' | 'gender' | 'region',
  key: ((x: string | number) => string) | undefined = undefined
) =>
  typeof sample === 'object'
    ? Object.values(sample).reduce((acc, cur) => {
        const k = key !== undefined ? key(cur[v]) : cur[v]
        if (typeof k !== 'string') return acc
        if (!(k in acc)) acc[k] = 0
        acc[k] += 1
        return acc
      }, {} as Record<string, number>)
    : {}
