import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'rc-slider/assets/index.css'
import { ReactNode, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MAX_AGE, MAX_END_DATE, MIN_AGE, MIN_START_DATE } from '../../constants'
import { TRIAL_BRAND } from '../../dashboard/trial-data'
import months from '../../helpers/DateHelper'
import { monthAbbrs } from '../../helpers/yearMonthObjectHelper'
import DateRangeSelector from './DateRangeSelector'
import MultiSelector from './MultiSelector'

// types
import { BrandOption } from '../../dashboard/layout'
import { State } from '../../store/rootReducer'
import { monthAbbrSchema, YearMonthObject } from '../../types/year-month-object'

const AudienceSelector = ({
  brandOptions,
  maxDate = MAX_END_DATE,
  minDate = MIN_START_DATE,
}: {
  brandOptions: BrandOption[]
  maxDate?: YearMonthObject
  minDate?: YearMonthObject
}) => {
  const trial = useSelector(({ trial }: State) => trial)
  const navigate = useNavigate()

  const hrefElems = window.location.href.replace(/\/\s*$/, '').split('/')
  const query: Record<string, string> = hrefElems[hrefElems.length - 1]
    .split('&')
    .filter(q => q.includes('='))
    .reduce(
      (acc, cur) => ({ ...acc, [cur.split('=')[0]]: cur.split('=')[1] }),
      {}
    )
  const [lastQuery, setLastQuery] = useState({})

  // BRAND VARS
  const [brands, setBrands] = useState(
    query?.brands ? decodeURIComponent(query.brands).split(',') : []
  )

  // DATE RANGE VARS
  const [startDate, setStartDate] = useState(
    typeof query?.startDate === 'string' ? dateObject(query.startDate) : minDate
  )
  const [endDate, setEndDate] = useState(
    typeof query?.endDate === 'string' ? dateObject(query.endDate) : maxDate
  )

  useEffect(() => {
    if (equalObjects(query, lastQuery)) return
    setBrands(
      query?.brands
        ? trial
          ? [TRIAL_BRAND]
          : decodeURIComponent(query.brands)?.split(',')
        : []
    )
    setStartDate(
      typeof query?.startDate === 'string'
        ? dateObject(query.startDate)
        : minDate
    )
    setEndDate(
      typeof query?.endDate === 'string' ? dateObject(query.endDate) : maxDate
    )
    setLastQuery(query)
  }, [query, lastQuery, trial])

  const getBrandSummary = () => {
    if (brands.length === 0) return 'No brands selected'
    return brands.join(', ')
  }

  const getDateSummary = () => {
    if (startDate.month === endDate.month && startDate.year === endDate.year)
      return months[monthAbbrs.indexOf(startDate.month)] + ' ' + startDate.year
    return (
      months[monthAbbrs.indexOf(startDate.month)] +
      ' ' +
      startDate.year +
      ' - ' +
      months[monthAbbrs.indexOf(endDate.month)] +
      ' ' +
      endDate.year
    )
  }

  if (
    endDate.year > maxDate.year ||
    (endDate.year === maxDate.year && endDate.month > maxDate.month)
  )
    setEndDate(maxDate)
  if (
    startDate.year < minDate.year ||
    (startDate.year === minDate.year && startDate.month < minDate.month)
  )
    setStartDate(minDate)

  return (
    <div className='inline-block rounded-lg border p-2.5'>
      <Container className='audience-selector'>
        <AudienceSelectorSection
          title='Select the brands you want to include'
          summary={getBrandSummary()}>
          <MultiSelector
            title='Brands'
            placeholder='Search brands...'
            options={brandOptions.map(brand => brand.brand)}
            selected={brands}
            setSelected={setBrands}
          />
        </AudienceSelectorSection>
        <AudienceSelectorSection
          title='Select the date range for analysis'
          summary={getDateSummary()}>
          <DateRangeSelector
            endDateState={[endDate, setEndDate]}
            maxYearMonth={maxDate}
            minYearMonth={minDate}
            startDateState={[startDate, setStartDate]}
          />
        </AudienceSelectorSection>
        <Row className='text-sm'>
          <Col className='m-2 flex justify-center'>
            <button
              onClick={() => {
                if (brands.length === 0)
                  return alert('Please select at least one brand.')
                navigate(
                  (query?.brands?.length > 0 ? '.' : '') +
                    './' +
                    getQueryURL({
                      brands,
                      endDate,
                      startDate,
                    })
                )
              }}
              className='mr-3 inline-flex justify-center rounded-md border border-transparent bg-stonegrey-1100 px-4 py-2 text-sm font-medium text-white hover:bg-stonegrey-1000 focus:outline-none focus:ring-2 focus:ring-stonegrey-800 focus:ring-offset-2'>
              Run
            </button>
            <button
              onClick={() => {
                setLastQuery({})
                setBrands([])
                setStartDate(minDate)
                setEndDate(maxDate)
                navigate('../')
              }}
              className='rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-stonegrey-800 focus:ring-offset-2'>
              Reset
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const AudienceSelectorSection = ({
  children,
  summary,
  title,
}: {
  children: ReactNode
  summary: string
  title: string
}) => {
  const [open, setOpen] = useState(true)

  return (
    <>
      <Row className='text-sm'>
        <Col
          onClick={() => setOpen(x => !x)}
          className='cursor-pointer p-2 pb-1 font-bold'
          xs='12'>
          <h4 className='inline-block'>{title}</h4>
          <FontAwesomeIcon
            icon={faCaretDown}
            className={`mx-2 my-0 ${open ? '' : '-rotate-90'}`}
          />
          <span className={'font-normal ' + (open ? 'hidden' : 'inline')}>
            {summary}
          </span>
        </Col>
      </Row>
      <Row className={'text-sm ' + (open ? 'flex' : 'hidden')}>
        <Col xs='12' className='section'>
          {children}
        </Col>
      </Row>
    </>
  )
}

const dateString = (ym: YearMonthObject) => `${ym.month}-${ym.year}`

export const dateObject = (dateString: string) => ({
  month: monthAbbrSchema.parse(dateString.substring(0, 3)),
  year: parseInt(dateString.substring(4, 8)),
})

type QueryUrlParams = {
  ageRange?: [number, number]
  brands?: string[]
  endDate?: YearMonthObject
  startDate?: YearMonthObject
}
const getQueryURL = (params: QueryUrlParams) => {
  if (
    params.ageRange &&
    params.ageRange[0] === MIN_AGE &&
    params.ageRange[1] === MAX_AGE
  )
    delete params.ageRange
  if (
    params.startDate &&
    dateString(params.startDate) === dateString(MIN_START_DATE)
  )
    delete params.startDate
  if (params.endDate && dateString(params.endDate) === dateString(MAX_END_DATE))
    delete params.endDate
  return Object.entries(params)
    .filter(
      keyVal =>
        typeof keyVal[1] !== 'undefined' &&
        (!Array.isArray(keyVal[1]) || keyVal[1].length > 0)
    )
    .map(keyVal => [
      keyVal[0],
      typeof keyVal[1] === 'object' && 'month' in keyVal[1]
        ? dateString(keyVal[1])
        : keyVal[1],
    ])
    .filter(keyVal => keyVal[1] !== undefined)
    .map(keyVal =>
      keyVal[1] === undefined
        ? ''
        : `${keyVal[0]}=${encodeURIComponent(
            Array.isArray(keyVal[1]) ? keyVal[1].join(',') : keyVal[1]
          )}`
    )
    .join('&')
}

export const equalObjects = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false
  return Object.keys(obj1).every(key => obj1[key] === obj2[key])
}

export default AudienceSelector
