import API from '@aws-amplify/api'
import { useQuery } from '@tanstack/react-query'

const fetcher = async (path, options = {}, method = 'GET') =>
  method === 'POST'
    ? await API.post('clientGateway', path, options)
    : await API.get('clientGateway', path, options)

export const useQueryWrapper = (
  queryKey,
  path,
  processResponse = res => res,
  apiOptions = {},
  queryOptions = {},
  method = 'GET'
) =>
  useQuery(
    queryKey,
    async () => processResponse(await fetcher(path, apiOptions, method)),
    queryOptions
  )
