import {
  faAsterisk,
  faCircleNotch,
  faEnvelope,
  faUnlock,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Auth } from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useRef, useState } from 'react'
import {
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
} from 'react-bootstrap'
import Footer from '../components/shared/Footer'
import NavBar from '../components/shared/NavBar'
import { ENV } from '../env'
import LoginManager from '../helpers/LoginManager'
import Redirect from '../helpers/Redirect'

const SignInPage = () => {
  const FORM_STATE = {
    SIGN_IN: 'SIGN_IN',
    SUBMITTED_SIGN_IN: 'SUBMITTED_SIGN_IN',
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    NEW_PASSWORD_REQUIRED_SUBMITTED: 'NEW_PASSWORD_REQUIRED_SUBMITTED',
    CODE: 'CODE',
    SUBMITTED_CODE: 'SUBMITTED_CODE',
    AUTHED: 'AUTHED',
  }

  const accessCodeRef = useRef()
  const [accessCode, setAccessCode] = useState('')

  const [code, setCode] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [formState, setFormState] = useState(FORM_STATE.SIGN_IN)
  const [newPassword, setNewPassword] = useState('')
  const [password, setPassword] = useState('')
  const [user, setUser] = useState(null)

  const sendCode = (useNewPassword = false) => {
    const passwordToUse = useNewPassword ? newPassword : password
    setFormState(FORM_STATE.SUBMITTED_SIGN_IN)
    setErrorMessage('')
    LoginManager.signIn(email, passwordToUse)
      .then(r => {
        setUser(r)
        if (r.challengeName === 'CUSTOM_CHALLENGE')
          return setFormState(FORM_STATE.CODE)
        if (r.challengeName === 'NEW_PASSWORD_REQUIRED')
          return setFormState(FORM_STATE.NEW_PASSWORD_REQUIRED)
        setFormState(FORM_STATE.SIGN_IN)
      })
      .catch(e => {
        console.error(JSON.stringify(e))
        setErrorMessage(e.message)
        setFormState(FORM_STATE.SIGN_IN)
      })
  }

  const sendNewPassword = () => {
    if (newPassword.length < 8)
      return alert('Password should be at least 8 characters.')
    if (newPassword !== confirmPassword) return alert("Password don't match.")
    setFormState(FORM_STATE.NEW_PASSWORD_REQUIRED_SUBMITTED)
    Auth.completeNewPassword(user, newPassword)
      .then(() => {
        sendCode(true)
      })
      .catch(() => {
        setFormState(FORM_STATE.NEW_PASSWORD_REQUIRED)
      })
  }

  const signIn = () => {
    setFormState(FORM_STATE.SUBMITTED_CODE)
    LoginManager.sendCustomChallengeAnswer(user, code).catch(e => {
      console.error(JSON.stringify(e))
      setErrorMessage(
        'Incorrect code or code has expired, please submit your email and password again.'
      )
      setFormState(FORM_STATE.SIGN_IN)
    })
  }

  const buttonClick = () => {
    switch (formState) {
      case FORM_STATE.NEW_PASSWORD_REQUIRED:
        return sendNewPassword()
      case FORM_STATE.CODE:
        return signIn()
      case FORM_STATE.SIGN_IN:
      default:
        return sendCode()
    }
  }

  const getButtonText = () => {
    switch (formState) {
      case FORM_STATE.SIGN_IN:
      case FORM_STATE.NEW_PASSWORD_REQUIRED:
        return 'Next'
      case FORM_STATE.CODE:
        return 'Login'
      default:
        return <FontAwesomeIcon icon={faCircleNotch} spin />
    }
  }

  const ifEnterDoButtonClick = target => target.charCode === 13 && buttonClick()

  if (accessCode !== ENV.REACT_APP_OLD_AUTH_PASS)
    return (
      <>
        <input ref={accessCodeRef} type='password' />
        <button onClick={() => setAccessCode(accessCodeRef.current.value)}>
          Submit
        </button>
      </>
    )

  return (
    <Redirect ifAuthStateIs={true} to={'/dashboard'}>
      <div className='d-flex flex-column justify-content-between auth-page-wrapper'>
        <NavBar />
        <Container className='auth-page sign-in-page border p-10 '>
          <h2 id='title'>Sign into your account</h2>
          <label htmlFor='title'>
            Enter your email and password to continue
          </label>
          <div>
            <InputGroup className='mb-3'>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
              <FormControl
                isInvalid={errorMessage !== ''}
                placeholder='Email Address'
                disabled={formState !== FORM_STATE.SIGN_IN}
                onChange={e => setEmail(e.target.value)}
                onKeyPress={ifEnterDoButtonClick}
              />
            </InputGroup>
          </div>
          <div>
            <InputGroup className='mb-3'>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUnlock} />
              </InputGroup.Text>
              <FormControl
                isInvalid={errorMessage !== ''}
                placeholder='Password'
                type='password'
                disabled={formState !== FORM_STATE.SIGN_IN}
                onChange={e => setPassword(e.target.value)}
                onKeyPress={ifEnterDoButtonClick}
              />
              <Form.Control.Feedback type='invalid'>
                {errorMessage}
              </Form.Control.Feedback>
            </InputGroup>
          </div>
          <div></div>

          {(newPassword.length ||
            confirmPassword.length ||
            formState === FORM_STATE.NEW_PASSWORD_REQUIRED) && (
            <div>
              <Form.Text muted>Please enter a new password</Form.Text>
              <InputGroup className='mb-3'>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faUnlockAlt} />
                </InputGroup.Text>
                <FormControl
                  placeholder='New password'
                  type='password'
                  disabled={formState !== FORM_STATE.NEW_PASSWORD_REQUIRED}
                  onChange={e => setNewPassword(e.target.value)}
                  onKeyPress={ifEnterDoButtonClick}
                />
              </InputGroup>
            </div>
          )}

          {(newPassword.length ||
            confirmPassword.length ||
            formState === FORM_STATE.NEW_PASSWORD_REQUIRED) && (
            <div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faUnlockAlt} />
                </InputGroup.Text>
                <FormControl
                  placeholder='Confirm new password'
                  type='password'
                  disabled={formState !== FORM_STATE.NEW_PASSWORD_REQUIRED}
                  onChange={e => setConfirmPassword(e.target.value)}
                  onKeyPress={ifEnterDoButtonClick}
                />
              </InputGroup>
            </div>
          )}

          {(formState === FORM_STATE.CODE ||
            formState === FORM_STATE.SUBMITTED_CODE) && (
            <div>
              <Form.Text muted>
                Enter the code we just sent to your email.
              </Form.Text>
              <InputGroup className='mb-3'>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faAsterisk} />
                </InputGroup.Text>
                <FormControl
                  placeholder='Code'
                  type='code'
                  disabled={formState !== FORM_STATE.CODE}
                  onChange={e => setCode(e.target.value)}
                  onKeyPress={ifEnterDoButtonClick}
                />
              </InputGroup>
            </div>
          )}

          <p className='my-2'>
            <Button style={{ backgroundColor: 'black' }} onClick={buttonClick}>
              {getButtonText()}
            </Button>
          </p>
        </Container>
        <Footer />
      </div>
    </Redirect>
  )
}

export default SignInPage
