import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { Grid, Input } from 'react-spreadsheet-grid' //Input
import DashboardPage from '../../../content/DashboardPage'

const UserRecordPlaid = () => {
  const [pmx_id, setPmxId] = useState('')
  const [email, setEmail] = useState('')
  const [data, setData] = useState({
    connections: [],
    events: [],
  })
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const getData = method => {
    if (
      ((pmx_id && method === 'pmxid') || (email && method === 'email')) &&
      !loading
    ) {
      setErrorMessage('')
      setLoading(true)
      setData({
        connections: [],
        events: [],
      })
      const params = method === 'pmxid' ? { pmx_id: pmx_id } : { email: email }
      API.get('clientGateway', '/internal/userrecordplaid', {
        queryStringParameters: params,
      })
        .then(res => {
          if (res.error) {
            if (res.error === 'Not Found') {
              setErrorMessage('User Not Found')
            } else {
              setErrorMessage('Something went wrong. Please try again later')
            }
            console.error(res.error)
          } else {
            let data = res
            setData(data)
            if (
              data['connections'].length === 0 &&
              data['events'].length === 0
            ) {
              setErrorMessage('User has not added any connections yet')
            }
          }
          setLoading(false)
        })
        .catch(e => {
          setErrorMessage('Something went wrong. Please try again later')
          setLoading(false)
          console.error(e)
        })
    }
  }

  const getTodayDate = () => {
    let today = new Date()
    let dd = String(today.getDate()).padStart(2, '0')
    let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    let yyyy = today.getFullYear()
    return mm + '-' + dd + '-' + yyyy
  }

  const DisplayConnections = () => {
    return (
      <div className='mt-2.5'>
        <span>Connections:</span>
        <Grid
          columns={[
            {
              id: 1,
              title: () => (
                <button
                  onClick={() => {
                    let connections = data['connections']
                    if (
                      connections.length > 0 &&
                      connections[0].institution_name >
                        connections[connections.length - 1].institution_name
                    ) {
                      connections = connections.sort((a, b) =>
                        a['institution_name'].localeCompare(
                          b['institution_name']
                        )
                      )
                    } else {
                      connections = connections.sort((a, b) =>
                        b['institution_name'].localeCompare(
                          a['institution_name']
                        )
                      )
                    }
                    setData({
                      connections: connections,
                      events: data['events'],
                    })
                  }}>
                  Provider
                </button>
              ),
              value: row => {
                return (
                  <div className='flex flex-row justify-start'>
                    {row.logo !== '-' && (
                      <img
                        className='mr-2 h-4'
                        src={row.logo}
                        alt='provider logo'
                      />
                    )}
                    {row.institution_name}
                  </div>
                )
              },
            },
            {
              id: 2,
              title: () => (
                <button
                  onClick={() => {
                    let connections = data['connections']
                    if (
                      connections.length > 0 &&
                      connections[0].accounts.length >
                        connections[connections.length - 1].accounts.length
                    ) {
                      connections = connections.sort(
                        (a, b) => a['accounts'].length - b['accounts'].length
                      )
                    } else {
                      connections = connections.sort(
                        (a, b) => b['accounts'].length - a['accounts'].length
                      )
                    }
                    setData({
                      connections: connections,
                      events: data['events'],
                    })
                  }}>
                  Accounts
                </button>
              ),
              value: row => {
                return (
                  <div className='flex flex-col justify-start'>
                    {row.accounts.map(r => (
                      <div>
                        {r.name + ' ' + (r.mask ?? '') + ' (' + r.type + ')'}
                      </div>
                    ))}
                  </div>
                )
              },
              width: 35,
            },
            {
              id: 3,
              title: () => (
                <button
                  onClick={() => {
                    let connections = data['connections']
                    if (
                      connections.length > 0 &&
                      connections[0].accounts.length >
                        connections[connections.length - 1].accounts.length
                    ) {
                      connections = connections.sort(
                        (a, b) => a['accounts'].length - b['accounts'].length
                      )
                    } else {
                      connections = connections.sort(
                        (a, b) => b['accounts'].length - a['accounts'].length
                      )
                    }
                    setData({
                      connections: connections,
                      events: data['events'],
                    })
                  }}>
                  Nr
                </button>
              ),
              value: row => {
                return (
                  <div className='flex flex-row justify-start'>
                    {row.accounts.length}
                  </div>
                )
              },
              width: 3,
            },
            {
              id: 4,
              title: () => (
                <button
                  onClick={() => {
                    let connections = data['connections']
                    if (
                      connections.length > 0 &&
                      connections[0].consent_expiration_time >
                        connections[connections.length - 1]
                          .consent_expiration_time
                    ) {
                      connections = connections.sort((a, b) =>
                        a['consent_expiration_time'].localeCompare(
                          b['consent_expiration_time']
                        )
                      )
                    } else {
                      connections = connections.sort((a, b) =>
                        b['consent_expiration_time'].localeCompare(
                          a['consent_expiration_time']
                        )
                      )
                    }
                    setData({
                      connections: connections,
                      events: data['events'],
                    })
                  }}>
                  Consent Expiration
                </button>
              ),
              value: row => {
                return (
                  <div className='flex flex-row justify-start'>
                    {row.consent_expiration_time}
                  </div>
                )
              },
              width: 20,
            },
            {
              id: 5,
              title: () => (
                <button
                  onClick={() => {
                    let connections = data['connections']
                    if (
                      connections.length > 0 &&
                      connections[0].disabled.toString() >
                        connections[connections.length - 1].disabled.toString()
                    ) {
                      connections = connections.sort((a, b) =>
                        a['disabled']
                          .toString()
                          .localeCompare(b['disabled'].toString())
                      )
                    } else {
                      connections = connections.sort((a, b) =>
                        b['disabled']
                          .toString()
                          .localeCompare(a['disabled'].toString())
                      )
                    }
                    setData({
                      connections: connections,
                      events: data['events'],
                    })
                  }}>
                  Disabled
                </button>
              ),
              value: row => {
                return (
                  <div className='flex flex-row justify-start'>
                    {row.disabled.toString()}
                  </div>
                )
              },
              width: 8,
            },
            {
              id: 6,
              title: () => (
                <button
                  onClick={() => {
                    let connections = data['connections']
                    if (
                      connections.length > 0 &&
                      connections[0].expired.toString() >
                        connections[connections.length - 1].expired.toString()
                    ) {
                      connections = connections.sort((a, b) =>
                        a['expired']
                          .toString()
                          .localeCompare(b['expired'].toString())
                      )
                    } else {
                      connections = connections.sort((a, b) =>
                        b['expired']
                          .toString()
                          .localeCompare(a['expired'].toString())
                      )
                    }
                    setData({
                      connections: connections,
                      events: data['events'],
                    })
                  }}>
                  Expired
                </button>
              ),
              value: row => {
                return (
                  <div className='flex flex-row justify-start'>
                    {row.expired.toString()}
                  </div>
                )
              },
              width: 8,
            },
          ]}
          rows={data['connections']}
          style={{ background: 'yellow' }}
          rowHeight={
            Math.max.apply(
              null,
              data['connections'].map(c => c.accounts.length)
            ) * 20
          }
          getRowKey={row => row.id}
        />
      </div>
    )
  }

  const DisplayEvents = () => {
    return (
      <div className='mt-4 h-[600px]'>
        <div className='flex flex-row justify-between'>
          <span>Events:</span>
          <CSVLink
            data={data['events'].map(e => {
              const con_idx = data['connections'].findIndex(
                a => a.id === e['aggregateId']
              )
              let eventBody = e['eventBody']
              delete eventBody['intermediaryConsentId']
              delete eventBody['intermediaryConsentTimestampUTC']
              let success_accounts = []
              let fail_accounts = []
              const cleanAccount = acc => {
                let a = { ...acc }
                a['name'] = a['displayName'] + a['providerAccountId'].slice(6)
                a['type'] = a['accountType'].replace('BANKING.', '')
                delete a['accountId']
                delete a['intermediaryAccountId']
                delete a['displayName']
                delete a['providerAccountId']
                delete a['accountType']
                return a
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  eventBody,
                  'accountsEnabled'
                )
              ) {
                success_accounts = eventBody['accountsEnabled'].map(a =>
                  cleanAccount(a)
                )
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  eventBody,
                  'accountsFailedToEnable'
                )
              ) {
                fail_accounts = eventBody['accountsFailedToEnable'].map(a =>
                  cleanAccount(a)
                )
              }
              return {
                timestamp: e['timestampUTC'],
                eventType: e['eventType'],
                connection:
                  con_idx > -1
                    ? data['connections'][con_idx].institution_name
                    : 'deleted' + e['aggregateId'].slice(0, 4),
                connectionId: e['aggregateId'],
                providerName: eventBody.providerName ?? '',
                reason: eventBody.reason ?? '',
                expiresAt: eventBody.expiresAtUTC ?? '',
                readyToRenewAt: eventBody.readyToRenewAtUTC ?? '',
                successful_accounts: JSON.stringify(success_accounts)
                  .replaceAll(',', ';')
                  .replaceAll('"', '""'),
                failed_accounts: JSON.stringify(fail_accounts)
                  .replaceAll(',', ';')
                  .replaceAll('"', '""'),
              }
            })}
            filename={pmx_id + '_events_' + getTodayDate() + '.csv'}
            className='underline'>
            Download CSV
          </CSVLink>
        </div>
        <Grid
          isScrollable
          columns={[
            {
              id: 1,
              title: () => (
                <button
                  onClick={() => {
                    let events = data['events']
                    if (
                      events.length > 0 &&
                      new Date(events[0].timestampUTC) >
                        new Date(events[events.length - 1].timestampUTC)
                    ) {
                      events = events.sort(
                        (a, b) =>
                          new Date(a['timestampUTC']) -
                          new Date(b['timestampUTC'])
                      )
                    } else {
                      events = events.sort(
                        (a, b) =>
                          new Date(b['timestampUTC']) -
                          new Date(a['timestampUTC'])
                      )
                    }
                    setData({
                      connections: data['connections'],
                      events: events,
                    })
                  }}>
                  Time
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.timestampUTC}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
            },
            {
              id: 2,
              title: () => (
                <button
                  onClick={() => {
                    let events = data['events']
                    if (
                      events.length > 0 &&
                      events[0].aggregateId >
                        events[events.length - 1].aggregateId
                    ) {
                      events = events.sort((a, b) =>
                        a['aggregateId'].localeCompare(b['aggregateId'])
                      )
                    } else {
                      events = events.sort((a, b) =>
                        b['aggregateId'].localeCompare(a['aggregateId'])
                      )
                    }
                    setData({
                      connections: data['connections'],
                      events: events,
                    })
                  }}>
                  Connection
                </button>
              ),
              value: row => {
                const con = data['connections'].filter(
                  c => c.id === row.aggregateId
                )
                let name = 'deleted-' + row['aggregateId'].slice(0, 4)
                if (con.length > 0) {
                  name = con[0].institution_name
                }
                return (
                  <div className='flex flex-row justify-start'>
                    {con.length > 0 && (
                      <img
                        className='mr-2 h-4'
                        src={con[0].logo}
                        alt='provider logo'
                      />
                    )}
                    {name}
                  </div>
                )
              },
            },
            {
              id: 3,
              title: () => <span className='font-normal'>Event</span>,
              value: row => (
                <div className='flex flex-row justify-start'>
                  {row.eventType}
                </div>
              ),
            },
            {
              id: 4,
              title: () => <span className='font-normal'>Failure Reason</span>,
              value: row => (
                <div className='flex flex-row justify-start'>
                  {row.eventBody.reason ? row.eventBody.reason : ''}
                </div>
              ),
              width: 30,
            },
          ]}
          rows={data['events']}
          getRowKey={row => row.eventId}
        />
      </div>
    )
  }

  return (
    <DashboardPage
      className='UserRecordPlaid'
      title='User Record Connection Hub'
      description={
        'Tool to see which connections and connections a panelist has added and through connection hub.'
      }>
      <div className={'flex flex-row items-center justify-start'}>
        <div className='mt-4 flex h-12 w-full items-center justify-start'>
          <span className='font-bold'>Enter pmx_id to search for: </span>
          <input
            className='ml-4 h-5/6 border'
            onChange={event => {
              setPmxId(event.target.value.replace(/\D/g, ''))
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                getData('pmxid')
              }
            }}
            value={pmx_id}
            placeholder='pmx_id'
          />
          <button className='ml-4' onClick={() => getData('pmxid')}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <div className='mt-4 flex h-12 w-full items-center justify-start'>
          <span className='font-bold'>or search by email:</span>
          <input
            className='w-50 ml-4 h-5/6 border'
            onChange={event => {
              setEmail(event.target.value.replace(' ', ''))
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                getData('email')
              }
            }}
            value={email}
            placeholder='email'
          />
          <button className='ml-4' onClick={() => getData('email')}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      {loading && <div className='mt-2.5'>Loading...</div>}
      {errorMessage && <div className='mt-2.5'>{errorMessage}</div>}
      {!loading && data['connections'].length > 0 && DisplayConnections()}
      {!loading && data['events'].length > 0 && DisplayEvents()}
    </DashboardPage>
  )
}

export default UserRecordPlaid
