import { ComponentProps } from 'react'
import { Link } from 'react-router-dom'

import { Icons_Actions_Add } from '../../assets/icons/Icons_Actions_Add'

type BaseProps = {
  Icon?: typeof Icons_Actions_Add
  iconPosition?: 'left' | 'right' | 'none'
  variant?: 'primary' | 'destructive' | 'gradient'
  size?: 'sm' | 'md'
}
type Props = BaseProps &
  (
    | ({ as: 'a' } & ComponentProps<'a'>)
    | ({ as: 'button' } & ComponentProps<'button'>)
    | ({ as: 'link' } & ComponentProps<typeof Link>)
  )

export const Button = ({
  children,
  className: inputClassName,
  Icon,
  iconPosition = 'right',
  variant = 'primary',
  size = 'md',
  ...props
}: Props) => {
  const variantClassName =
    variant === 'gradient'
      ? 'bg-gradient-to-br from-grapefruit-700 to-pomegranate-700 hover:from-grapefruit-800 hover:to-pomegranate-800 focus:from-grapefruit-800 focus:to-pomegranate-800 active:from-grapefruit-900 active:to-pomegranate-900'
      : variant === 'destructive'
      ? 'bg-grapefruit-700 hover:bg-grapefruit-800 focus:bg-grapefruit-800 active:bg-grapefruit-900'
      : 'bg-blueberry-1000 hover:bg-blueberry-1100 focus:bg-blueberry-1100 active:bg-blueberry-1200'

  const sizeClassName =
    size === 'sm' ? 'h-9 px-6 text-sm gap-2' : 'h-12 px-8 text-lg gap-3'

  const className = `flex flex-grow-0 justify-center items-center rounded-full font-semibold text-white outline-offset-0 outline-blueberry-300 disabled:bg-stonegrey-500 focus:outline-4 active:outline-4 ${variantClassName} ${sizeClassName} ${inputClassName}`

  const content =
    iconPosition === 'left' && Icon ? (
      <div className='flex flex-row items-center gap-2'>
        <Icon className='block' height={12} color={'#fff'} />
        {children}
      </div>
    ) : iconPosition === 'right' && Icon ? (
      <div className='flex flex-row items-center gap-2'>
        {children}
        <Icon className='block' height={12} color={'#fff'} />
      </div>
    ) : (
      <>{children}</>
    )

  if (props.as === 'a')
    return (
      <a className={className} {...props}>
        {content}
      </a>
    )

  if (props.as === 'button')
    return (
      <button className={className} {...props}>
        {content}
      </button>
    )

  if (props.as === 'link')
    return (
      <Link className={className} {...props}>
        {content}
      </Link>
    )

  return <></>
}
