/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cloud_logic_custom: [
    {
      name: 'clientGateway',
      endpoint: 'https://2copxxkp9g.execute-api.eu-west-2.amazonaws.com/prod',
      region: 'eu-west-2',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-west-2:c7ace1f4-ee12-4181-9b5a-b7f2e28ca9cf',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_3ZPJOhjqs',
  aws_user_pools_web_client_id: '10pgobt67pb9r0otri9rea1fht',
  oauth: {},
  Storage: {
    AWSS3: {
      bucket: 'client-file-storage135708-prod',
    },
  },
}

export default awsmobile
