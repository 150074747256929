/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cloud_logic_custom: [
    {
      name: 'clientGateway',
      endpoint: 'https://2l1c61rdxl.execute-api.eu-west-2.amazonaws.com/dev',
      region: 'eu-west-2',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-west-2:8f280746-386b-451d-830b-8eafd2f70164',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_rIqcv8RjR',
  aws_user_pools_web_client_id: '3mtoqofa26pagmb7erisne185d',
  oauth: {},
  Storage: {
    AWSS3: {
      bucket: 'client-file-storage140449-dev',
    },
  },
}

export default awsmobile
