import { Switch } from '@headlessui/react'
import { Dispatch, SetStateAction } from 'react'

const Toggle = ({
  onChange,
  state,
}: {
  onChange?: (v: boolean) => void
  state: [boolean, Dispatch<SetStateAction<boolean>>]
}) => {
  const [enabled, setEnabled] = state

  return (
    <Switch
      checked={enabled}
      onChange={onChange ?? setEnabled}
      className={
        (enabled ? 'bg-stonegrey-1100' : 'bg-gray-200') +
        ' relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-stonegrey-800 focus:ring-offset-2'
      }>
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={
          (enabled ? 'translate-x-5' : 'translate-x-0') +
          ' pointer-events-none m-0.5 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        }
      />
    </Switch>
  )
}

export default Toggle
