import {
  faBagShopping,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { brandInfoSchema } from '../../../hooks/useBrandInfo'

export const AssociatedBrands = ({
  data,
  brandLogos,
}: {
  data: z.infer<typeof brandInfoSchema>
  brandLogos: Record<string, string>
}) => {
  const brand = data.brand

  const getTopBrand = (data: Record<string, number> | undefined) =>
    (data
      ? Object.entries(data).reduce(
          ([acc_key, acc_val], [key, value]) =>
            key !== brand && value > acc_val
              ? [key, value]
              : [acc_key, acc_val],
          ['other', 0]
        )
      : ['other', 0]) as [string, number]
  const topSupermarket = getTopBrand(data.top_shop_dist?.Supermarkets)
  const topStreaming = getTopBrand(data.top_shop_dist?.Streaming)
  const topTakeout = getTopBrand(data.top_shop_dist?.Takeout)

  const getSwitches = (switchData: Record<string, number>) =>
    Object.entries(switchData).sort((a, b) => b[1] - a[1])
  const switchFrom = data.switch_dist?.from
    ? getSwitches(data.switch_dist.from)
    : []
  const switchTo = data.switch_dist?.to ? getSwitches(data.switch_dist.to) : []

  const result = {} as Record<
    string,
    {
      logo: ReactNode
      steamOn?: { value: number }
      shopAt?: { value: number }
      topTakeout?: { value: number }
      switchedFrom?: { value: number }
      switchedTo?: { value: number }
    }
  >

  // format the data so that we can build the markup
  const tops = {
    topSupermarket,
    topStreaming,
    topTakeout,
  }
  Object.keys(tops).forEach(key => {
    const topName = tops[key as keyof typeof tops]
    const associatedBrandName = topName[0]

    if (associatedBrandName === brand) return

    result[associatedBrandName] = {
      logo: (
        <img
          alt={`${brand} logo`}
          src={`https://logo.clearbit.com/${
            associatedBrandName === 'Netflix'
              ? 'netflix.com' // this is badly referenced in the backend
              : brandLogos[associatedBrandName]
          }`}
          className='mr-2 inline-block h-auto w-7'
        />
      ),
    }

    switch (key) {
      case 'topStreaming':
        result[associatedBrandName].steamOn = {
          value: Math.round(tops['topStreaming'][1] * 100),
        }
        break
      case 'topSupermarket':
        result[associatedBrandName].shopAt = {
          value: Math.round(tops['topSupermarket'][1] * 100),
        }
        break
      case 'topTakeout':
        result[associatedBrandName].topTakeout = {
          value: Math.round(tops['topTakeout'][1] * 100),
        }
        break
      default:
        break
    }

    switchTo.forEach(arr => {
      if (result[arr[0]]) {
        result[arr[0]].switchedTo = { value: Math.round(arr[1] * 100) }
      }
    })

    switchFrom.forEach(arr => {
      if (result[arr[0]]) {
        result[arr[0]].switchedFrom = { value: Math.round(arr[1] * 100) }
      }
    })
  })

  const brands = Object.keys(result)
  const streamOnShopAtIcon = (
    <IconWithBackground
      icon={faBagShopping}
      backgroundColorClass='bg-blue-100'
      iconColorClass='text-blue-500'
    />
  )

  return brands.map((brand, index) => {
    const associatedBrand = result[brand]
    return (
      <div className='w-1/3 rounded border-2 border-gray-200 px-3' key={index}>
        <Link to={'../' + brand}>
          <div className='px-2 py-4 text-xl font-bold'>
            {associatedBrand.logo}
            {brand}
          </div>
          {associatedBrand.shopAt && (
            <AssociatedBrand
              icon={streamOnShopAtIcon}
              text={`Shop at ${brand}`}>
              {associatedBrand.shopAt.value}
            </AssociatedBrand>
          )}
          {associatedBrand.topTakeout && (
            <AssociatedBrand
              icon={streamOnShopAtIcon}
              text={`Get takeout from ${brand}`}>
              {associatedBrand.topTakeout.value}
            </AssociatedBrand>
          )}
          {associatedBrand.steamOn && (
            <AssociatedBrand
              icon={streamOnShopAtIcon}
              text={`Stream on ${brand}`}>
              {associatedBrand.steamOn.value}
            </AssociatedBrand>
          )}
          {associatedBrand.switchedFrom && (
            <AssociatedBrand
              icon={
                <IconWithBackground
                  icon={faPlus}
                  backgroundColorClass='bg-green-200'
                  iconColorClass='text-green-700'
                />
              }
              text={`Switched from ${brand}`}>
              {associatedBrand.switchedFrom.value}
            </AssociatedBrand>
          )}
          {associatedBrand.switchedTo && (
            <AssociatedBrand
              icon={
                <IconWithBackground
                  icon={faMinus}
                  backgroundColorClass='bg-red-200'
                  iconColorClass='text-red-700'
                />
              }
              text={`Switched to ${brand}`}>
              {associatedBrand.switchedTo.value}
            </AssociatedBrand>
          )}
        </Link>
      </div>
    )
  })
}

const AssociatedBrand = ({
  children,
  icon,
  text,
}: {
  children: number
  icon: ReactNode
  text: string
}) => {
  return (
    <div className='border-t-2 border-gray-200 px-2 py-4'>
      <div className='flex content-center items-center gap-2'>
        <div className='text-2xl'>{icon}</div>
        <div className='text-2xl font-bold'>{children}%</div>
      </div>
      <div className='text-xs'>{text}</div>
    </div>
  )
}

const IconWithBackground = ({
  icon,
  backgroundColorClass,
  iconColorClass,
}: {
  icon: typeof faBagShopping
  backgroundColorClass: string
  iconColorClass: string
}) => (
  <div
    className={`flex h-6 w-6 flex-col justify-center rounded-full bg-blue-100 text-center ${backgroundColorClass}`}>
    <FontAwesomeIcon
      className={`fa-regular fa-sharp bg-transparent text-xs ${iconColorClass}`}
      icon={icon}
    />
  </div>
)
