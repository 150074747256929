import { faFile } from '@fortawesome/free-solid-svg-icons'
import { ENV } from '../../env'
import { ReportMetadata, useReports } from '../../hooks/useReports'
import Report from './Report'

// types
import { RouteType } from '../routes'

export const useRoutes = (): RouteType[] => {
  const { data: reports, isLoading } = useReports()
  if (isLoading || !reports)
    return [
      'loader',
      {
        component: Report,
        icon: faFile,
        link: ':reportId',
        title: 'Report',
      },
    ]
  return [
    ...(ENV.REACT_APP_ENV !== 'us'
      ? [
          {
            component: Report,
            icon: faFile,
            link: 'free',
            title: '(Free) TV Licensing 2022 Report',
            props: {
              brand: 'TV Licensing',
              reportId: 'free',
              year: 2022,
              infoBoxContent: (
                <>
                  This is your free generated report. If you would like to buy a
                  report for a different brand, please click the "Buy Report"
                  button in the side bar.
                </>
              ),
              payment_status: 'free' as const,
            },
          },
        ]
      : []),
    ...(reports ?? [])
      .map(({ brand, min_date, report_id }: ReportMetadata) => ({
        component: Report,
        icon: faFile,
        link: report_id,
        title: `${brand} ${new Date(min_date).getUTCFullYear()} Report`,
        props: {
          brand,
          reportId: report_id,
          year: new Date(min_date).getUTCFullYear(),
          payment_status: 'paid' as const,
        },
      }))
      .sort(({ title: a }, { title: b }) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      }),
    {
      component: Report,
      icon: faFile,
      link: ':reportId',
      title: 'Report',
    },
  ]
}
