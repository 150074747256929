import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Grid, Input } from 'react-spreadsheet-grid'
import DashboardPage from '../../../content/DashboardPage'

const UserFeedback = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data.length === 0) {
      setLoading(true)
      API.get('clientGateway', '/internal/userfeedback', {}).then(res => {
        setData(res.data)
        setLoading(false)
      })
    }
  }, [data])

  const DisplayFeedback = () => {
    return (
      <div className='mt-4 h-96'>
        <Grid
          isScrollable={true}
          columns={[
            {
              id: 1,
              title: () => (
                <button
                  onClick={() => {
                    let d = data
                    if (
                      new Date(d[0].timestamp) <
                      new Date(d[d.length - 1].timestamp)
                    ) {
                      d = d.sort(
                        (a, b) =>
                          new Date(b['timestamp']) - new Date(a['timestamp'])
                      )
                    } else {
                      d = d.sort(
                        (a, b) =>
                          new Date(a['timestamp']) - new Date(b['timestamp'])
                      )
                    }
                    setData([...d])
                  }}>
                  Date
                </button>
              ),
              value: row => {
                return (
                  <span>
                    {row.timestamp.slice(
                      0,
                      row.timestamp.indexOf('Coordinated') - 2
                    )}
                  </span>
                )
              },
              width: 25,
            },
            {
              id: 2,
              title: () => (
                <button
                  onClick={() => {
                    let d = data
                    if (d[0].message > d[d.length - 1].message) {
                      d = d.sort((a, b) =>
                        a['message'].localeCompare(b['message'])
                      )
                    } else {
                      d = d.sort((a, b) =>
                        b['message'].localeCompare(a['message'])
                      )
                    }
                    setData([...d])
                  }}>
                  Message
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.message}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                    wrap={true}
                  />
                )
              },
            },
            {
              id: 3,
              title: () => (
                <button
                  onClick={() => {
                    let d = data
                    if (d[0].pmx_id > d[d.length - 1].pmx_id) {
                      d = d.sort((a, b) =>
                        a['pmx_id'].localeCompare(b['pmx_id'])
                      )
                    } else {
                      d = d.sort((a, b) =>
                        b['pmx_id'].localeCompare(a['pmx_id'])
                      )
                    }
                    setData([...d])
                  }}>
                  Pmx ID
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.pmx_id}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
              width: 10,
            },
          ]}
          rows={data}
          getRowKey={row => row.id}
        />
      </div>
    )
  }

  return (
    <DashboardPage
      className='UserFeedback'
      title='User Feedback'
      description={`Feedback that panelists have left on the 'Visualise Your Data Page'`}>
      {loading && <div className='mt-2.5'>Loading...</div>}
      {!loading && data.length > 0 && DisplayFeedback()}
    </DashboardPage>
  )
}

export default UserFeedback
