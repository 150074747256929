import { ENV } from '../env'

export const TRIAL_BRAND =
  ENV.REACT_APP_ENV === 'us' ? "McDonald's" : 'TV Licensing'

export const TRIAL_PERSONAS: Record<
  string,
  { age: number; income_bracket?: string; gender: string; region: string }
> =
  ENV.REACT_APP_ENV === 'us'
    ? {
        '2001823': {
          age: 71,
          gender: 'Male',
          region: 'South',
        },
        '17684897': {
          age: 38,
          gender: 'Male',
          region: 'Northeast',
        },
        '20515918': {
          age: 46,
          gender: 'Female',
          region: 'South',
        },
        '11235492': {
          age: 61,
          gender: 'Female',
          region: 'West',
        },
      }
    : {
        'b76f9cdc-9169-460a-bade-a9d809771dec': {
          age: 29,
          gender: 'Female',
          region: 'North West',
        },
        'c43e7c13-5483-41e5-9231-c1fbe17fd334': {
          age: 39,
          gender: 'Male',
          region: 'London',
        },
        '9a82838e-e394-433a-8506-9e89c52fd035': {
          age: 93,
          gender: 'Male',
          region: 'North West',
        },
        'dad74ada-e474-4b50-94f4-93cd65874604': {
          age: 50,
          gender: 'Female',
          region: 'Scotland',
        },
      }
