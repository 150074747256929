import {
  faCakeCandles,
  faChildren,
  faMapLocation,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const UserCardCarousel = ({
  selectedUser,
  selectFunc,
  users,
}: {
  selectedUser?: string
  selectFunc: (x: string) => void
  users: {
    age: string | number
    gender: string
    region: string
    user_id: string
  }[]
}) => (
  <div className='user-card-carousel p-0'>
    <div className='users grid grid-cols-1 gap-3 sm:grid-cols-4'>
      {users.map((user, idx) => (
        <UserCard
          age={user.age}
          key={idx}
          gender={user.gender}
          location={user.region}
          selected={user.user_id === selectedUser}
          selectFunc={() =>
            user.user_id === selectedUser
              ? selectFunc('..')
              : selectFunc((selectedUser ? '.' : '') + './' + user.user_id)
          }
        />
      ))}
    </div>
  </div>
)

export const UserCard = ({
  age,
  gender,
  location,
  selected,
  selectFunc,
}: {
  age: string | number
  gender: string
  location: string
  selected?: boolean
  selectFunc?: () => void
}) => (
  <div
    className={
      'user-card leading:7 rounded-lg border border-gray-200 p-3.5' +
      (selectFunc
        ? ' cursor-pointer hover:border-gray-300 hover:bg-gray-100'
        : '') +
      (selected ? ' bg-gray-100' : '')
    }
    onClick={selectFunc}>
    <div className='flex h-full flex-col justify-around'>
      <div className='flex flex-row flex-wrap items-center justify-center space-x-2 overflow-hidden text-ellipsis text-center text-sm font-bold'>
        {gender && (
          <div>
            <FontAwesomeIcon icon={faChildren} /> {gender}
          </div>
        )}
        {location && (
          <div>
            <FontAwesomeIcon icon={faMapLocation} /> {location}
          </div>
        )}
        {age && (
          <div>
            <FontAwesomeIcon icon={faCakeCandles} />{' '}
            {age !== 'Loading...' ? <>{age} yrs</> : age}
          </div>
        )}
      </div>
    </div>
  </div>
)
