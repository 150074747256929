import API from '@aws-amplify/api'
import { useMutation } from '@tanstack/react-query'

export const useMutationSaveNonce = () =>
  useMutation(
    () => {
      const nonce = Date.now().toString()
      return API.post('clientGateway', '/auth/savenonce', {
        body: {
          nonce,
          referrer_url: document.referrer,
          referrer_param: new URLSearchParams(window.location.search).get(
            'utm_campaign'
          ),
        },
      })
        .then(() => {
          sessionStorage.setItem('nonce', nonce)
          return nonce
        })
        .catch(console.error)
    },
    {
      onSuccess: data => {
        window.location.replace(
          `https://customers.yougov.com/oauth/authorize?scope=openid email profile ygcustomers&response_type=code&client_id=yougov-finance&redirect_uri=${window.location.origin}/auth/&nonce=${data}`
        )
      },
    }
  )

export const useMutationAuthorize = () =>
  useMutation(() => {
    return API.post('clientGateway', '/auth/authorize', {
      body: {
        code: new URLSearchParams(window.location.search).get('code'),
        redirect_uri: window.location.origin + '/auth/',
      },
    }).catch(console.error)
  })
