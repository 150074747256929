const colors = {
  orange: '#FF412C',
  purple: '#9F29FF',
  pink: '#FF2380',
  blue: '#06A6EE',
  green: '#31CAA8',
  yellow: '#FFBA22',
  'darker-purple': '#6600AB',
  'light-pink': '#FF7BB3',
  'dark-blue': '#003CAB',
  teal: '#83DFCB',
  'dark-red': '#C20800',
  'light-purple': '#C57FFF',
  'light-orange': '#FF8D80',
  'light-blue': '#6ACAF5',
  'dark-green': '#006B46',
}

module.exports = { colors }
