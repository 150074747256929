import {
  faCakeCandles,
  faChildren,
  faMapLocation,
  faTemperatureHalf,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { useSample } from '../../hooks/useSample'

// types
import { SampleStates } from '../../types/sample'

const SelectedSample = ({
  sampleStates: {
    natRep: [natRep],
    sampleSize: [sampleSize],
    maxAge: [maxAge],
    maxDate: [maxDate],
    minAge: [minAge],
    minDate: [minDate],
    gender: [gender],
    regions: [regions],
    useCoverageDates: [useCoverageDates],
  },
}: {
  sampleStates: SampleStates
}) => {
  const infos = natRep
    ? [{ icon: faTemperatureHalf, text: 'Nat-Rep' }]
    : [
        { icon: faChildren, text: gender },
        {
          icon: faMapLocation,
          text: regions.length > 0 ? regions.join(', ') : 'All Locations',
        },
        {
          icon: faCakeCandles,
          text: 'Aged ' + (maxAge < 100 ? `${minAge}-${maxAge}` : `${minAge}+`),
        },
      ]

  const { data: sampleData, isLoading } = useSample({
    gender,
    regions,
    maxAge,
    maxDate,
    minAge,
    minDate,
    natRep,
    sampleSize,
    useCoverageDates,
  })

  const sample = isLoading ? {} : sampleData?.sample

  const N = typeof sample === 'object' ? Object.keys(sample).length : undefined

  return (
    <Link to='/dashboard/configure-sample' className='hover:text-black'>
      <div className='text-md mt-2 inline-block max-w-3xl rounded-lg border border-gray-200 px-3 py-3 hover:border-gray-300 hover:bg-gray-100'>
        <div className='flex flex-row flex-wrap justify-center space-x-1'>
          <h6 className='inline-block text-sm'>Sample</h6>
          <div className='inline-block text-sm'>(click to configure)</div>
        </div>
        <div className='my-1 flex flex-row flex-wrap justify-center space-x-4 text-sm font-bold'>
          {infos.map(({ icon, text }) => (
            <div key={text}>
              <FontAwesomeIcon icon={icon} /> {text}
            </div>
          ))}
        </div>
        <div className='mt-1 flex flex-row flex-wrap justify-center space-x-4 text-sm'>
          Sample size: N ={' '}
          {isLoading ? 'Loading...' : N?.toLocaleString() ?? 'Loading...'}
        </div>
      </div>
    </Link>
  )
}

export default SelectedSample
