import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SmallPieChart } from 'ygf_graphs'
import Toggle from '../../components/configure-sample/toggle'
import AudienceSelector, {
  dateObject,
  equalObjects,
} from '../../components/dashboard/AudienceSelector'
import DashboardSection from '../../components/dashboard/DashboardSection'
import GraphConfig from '../../components/dashboard/GraphConfig'
import { LineGraph } from '../../components/dashboard/LineGraph'
import SelectedSample from '../../components/dashboard/SelectedSample'
import Table from '../../components/dashboard/Table'
import { TextLink } from '../../components/shared/TextLink'
import { CURRENCY_SYMBOL, MAX_END_DATE, MIN_START_DATE } from '../../constants'
import { reduceToTen } from '../../helpers/DataReducers'
import {
  BrandData,
  BrandDataRecord,
  useMarketTrendsQuery,
} from '../../hooks/useMarketTrends'
import { useSample } from '../../hooks/useSample'
import DashboardPage from '../content/DashboardPage'
import { useBrandOptionsContext } from '../layout'
import { TRIAL_BRAND } from '../trial-data'

// types
import {
  addMonths,
  fromDate,
  toDate,
  ymDiff,
} from '../../helpers/yearMonthObjectHelper'
import { State } from '../../store/rootReducer'
import { SampleStates } from '../../types/sample'
import { YearMonthObject } from '../../types/year-month-object'

const MarketTrends = ({ sampleStates }: { sampleStates: SampleStates }) => {
  const trial = useSelector(({ trial }: State) => trial)

  const queryParams = useParams()
  const hrefElems = window.location.href.replace(/\/\s*$/, '').split('/')
  const query = hrefElems[hrefElems.length - 1]
    .split('&')
    .filter(q => q.includes('='))
    .reduce(
      (acc, cur) => ({ ...acc, [cur.split('=')[0]]: cur.split('=')[1] }),
      {}
    )
  const [lastQuery, setLastQuery] = useState({})

  const [startDate, setStartDate] = useState(MIN_START_DATE)
  const [endDate, setEndDate] = useState(MAX_END_DATE)

  const brandOptions =
    useBrandOptionsContext()?.brandOptions?.filter(
      ({ brand }) => !trial || brand === TRIAL_BRAND
    ) ?? []

  const [brands, setBrands] = useState<string[]>([])

  const [weighted, setWeighted] = useState(false)

  useEffect(() => {
    const hrefElems = window.location.href.replace(/\/\s*$/, '').split('/')
    const query = Object.fromEntries(
      hrefElems[hrefElems.length - 1]
        .split('&')
        .filter(q => q.includes('='))
        .map(q => q.split('='))
    )

    const newBrands = query?.brands
      ? trial
        ? [TRIAL_BRAND]
        : decodeURIComponent(query?.brands)?.split(',')
      : []

    if (!arraysEqual(brands, newBrands)) setBrands(newBrands)

    if (equalObjects(query, lastQuery)) return
    setLastQuery(query)

    if (typeof query?.startDate === 'string')
      setStartDate(dateObject(query.startDate))
    else setStartDate(MIN_START_DATE)
    if (typeof query?.endDate === 'string')
      setEndDate(dateObject(query.endDate))
    else setEndDate(MAX_END_DATE)
  }, [brands, queryParams, query, lastQuery, trial])

  const { data: sampleData, isLoading: sampleIsLoading } = useSample({
    gender: trial ? 'All Genders' : sampleStates?.gender?.[0],
    maxAge: trial ? 1000 : sampleStates?.maxAge?.[0],
    maxDate: sampleStates?.maxDate?.[0],
    minAge: trial ? 18 : sampleStates?.minAge?.[0],
    minDate: sampleStates?.minDate?.[0],
    natRep: trial ? false : sampleStates?.natRep?.[0],
    regions: trial ? [] : sampleStates?.regions?.[0],
    sampleSize: trial ? 1000 : sampleStates?.sampleSize?.[0],
    useCoverageDates: trial ? false : sampleStates?.useCoverageDates?.[0],
  })

  const sample = sampleData?.sample
  const sample_id = sampleData?.sample_id

  const { data: marketTrendsQueryData, isLoading: dataIsLoading } =
    useMarketTrendsQuery({
      brands,
      sample_id,
    })

  const data = marketTrendsQueryData?.data

  const brandLogos = Object.fromEntries(
    brandOptions.map(({ brand, logo_link }) => [brand, logo_link])
  )

  const hasWeightedData =
    sample &&
    Object.keys(sample).length > 0 &&
    sample[Object.keys(sample)[0]].Weight !== 1

  return (
    <DashboardPage
      title='Market Trends'
      description='Aggregated analysis of spend at selected brands for a given audience.'>
      {trial ? (
        <div className='mt-2 max-w-sm rounded-lg bg-stonegrey-400 p-3 text-sm'>
          <span className='mr-2 rounded bg-stonegrey-200 p-1 px-2 text-xs font-bold uppercase'>
            demo
          </span>
          This is a demo of the Market Trends feature. You only have access to
          the brand {TRIAL_BRAND} with a sample of 1000 consumers. To unlock the
          4,000+ brands that we track across hundreds of sectors, and our full
          sample of 10,000+ consumers,{' '}
          <TextLink
            target='_blank'
            href='https://business.yougov.com/?marketo=demo'>
            book a demo call
          </TextLink>
          .
        </div>
      ) : (
        <SelectedSample sampleStates={sampleStates} />
      )}
      <DashboardSection
        title='Select brands and timeframe'
        description='Select your audience, industry and brands you want to include. Then hit run.'>
        <AudienceSelector
          {...{ brandOptions }}
          {...(sampleData?.params
            ? {
                /* we do addMonths -1 because the sample maxDate is set to the be the first day of the next month (to include all data from the last month we actually want to see), so we do -1 because we want to only show the current month in like the date picker and stuff */
                maxDate: sampleData.params.max_date
                  ? addMonths(
                      fromDate(new Date(sampleData.params.max_date)),
                      -1
                    )
                  : MAX_END_DATE,
                minDate: sampleData.params.min_date
                  ? fromDate(new Date(sampleData.params.min_date))
                  : MIN_START_DATE,
              }
            : {})}
        />
      </DashboardSection>

      <div className='mt-4' />

      {brands.length > 0 &&
        (sampleIsLoading ? (
          'Loading sample...'
        ) : dataIsLoading ? (
          'Loading data...'
        ) : (
          <>
            {hasWeightedData && (
              <DashboardSection
                title='Weighted data'
                description={`If you select the toggle below, we will weight each user's data by a factor so that the user's demographic corresponds to the national average.`}>
                <div className='mb-3 inline-block rounded-lg border px-3 py-2.5'>
                  <div className='flex flex-row'>
                    <div className='mr-5 text-sm leading-8'>Weight data?</div>
                    <div className='mt-1'>
                      <Toggle state={[weighted, setWeighted]} />
                    </div>
                  </div>
                </div>
              </DashboardSection>
            )}
            <div className='w-full bg-white p-6'>
              {data && (
                <Graphs
                  start={startDate}
                  end={endDate}
                  data={data[weighted ? 'weighted' : 'unweighted']}
                  brandLogos={brandLogos}
                />
              )}
            </div>
          </>
        ))}
    </DashboardPage>
  )
}

const getDateIndex = (dataLength: number, ym: YearMonthObject) => {
  const years = dataLength / 12
  const minYear = new Date().getFullYear() - years + 1
  const startDate = { year: minYear, month: 'jan' } as const
  return ymDiff(ym, startDate)
}

const dataReducerBetween =
  (start: YearMonthObject, end: YearMonthObject) =>
  (acc: { name: string; value: number }[], cur: [string, BrandData]) => {
    if (!Array.isArray(acc)) acc = []
    const newValue = { name: cur[0], value: 0 }
    cur[1].monthly_split.amount
      .slice(
        getDateIndex(cur[1].monthly_split.amount.length, start),
        getDateIndex(cur[1].monthly_split.amount.length, end) + 1
      )
      .forEach(val => {
        newValue.value += val
      })
    acc.push(newValue)
    return acc
  }

type BrandValueObject = {
  name: string
  value: number
}

const Performance = ({
  data,
  startDate,
  endDate,
  brandLogos,
}: {
  data: BrandDataRecord
  startDate: YearMonthObject
  endDate: YearMonthObject
  brandLogos: Record<string, string>
}) => {
  const reducedData = Object.entries(data)
    .reduce(dataReducerBetween(startDate, endDate), [])
    .sort((a: BrandValueObject, b: BrandValueObject) => b.value - a.value)

  // const previousPeriodReducedData = Object.entries(data)
  //   .reduce(
  //     dataReducerBetween(
  //       previousPeriod(startDate, endDate)[0],
  //       previousPeriod(startDate, endDate)[1]
  //     ),
  //     []
  //   )
  //   .sort((a: BrandValueObject, b: BrandValueObject) => b.value - a.value)
  // const previousBrandValues = previousPeriodReducedData.map(
  //   (x: BrandValueObject, i: number) => ({ ...x, rank: i })
  // )

  // const showChange =
  //   previousPeriodReducedData
  //     .map((data: BrandValueObject) => data.value)
  //     .reduce((acc: number, cur: number) => acc + cur, 0) > 0

  // const max = reducedData[0].value
  const total = reducedData.reduce(
    (acc: number, cur: BrandValueObject) => acc + cur.value,
    0
  )
  return (
    <>
      <Table
        data={{
          headers: [
            '#',
            'Brand',
            'Market Share',
            'Total Spend',
            // removing change for time being since it is buggy
            // ...(showChange && false
            //   ? [
            //       <>
            //         Percentage change (compared to{' '}
            //         {months[
            //           previousPeriod(startDate, endDate)[0].month
            //         ].substring(0, 3) +
            //           ' ' +
            //           previousPeriod(startDate, endDate)[0].year +
            //           ' - ' +
            //           months[
            //             previousPeriod(startDate, endDate)[1].month
            //           ].substring(0, 3) +
            //           ' ' +
            //           previousPeriod(startDate, endDate)[1].year}
            //         )
            //       </>,
            //     ]
            //   : []),
          ],
          body: reducedData.map((brand: BrandValueObject, i: number) => [
            i + 1,
            <>
              {brandLogos[brand.name] && brandLogos[brand.name] !== '-' && (
                <img
                  alt={`${brand.name} logo`}
                  src={`https://logo.clearbit.com/${brandLogos[brand.name]}`}
                  className='mr-2.5 inline-block h-auto w-7'
                  onError={e =>
                    ((e.target as HTMLOrSVGImageElement).style.display = 'none')
                  }
                />
              )}
              {brand.name}
            </>,
            <>
              {brand.value === total
                ? 100
                : brand.value > 0.95 * total
                ? Math.round((brand.value / total) * 10000) / 100
                : ((brand.value / total) * 100).toPrecision(2)}
              %
            </>,
            <>
              {CURRENCY_SYMBOL}
              {(Math.round(brand.value * 100) / 100).toLocaleString()}
            </>,
            // removing change for time being since it is buggy
            // ...(showChange && false
            //   ? [
            //       <span
            //         className={`${
            //           brand.value > previousBrandValues[brand.name].value
            //             ? 'text-green-500'
            //             : 'text-red-500'
            //         }`}>
            //         {previousBrandValues[brand.name].value > 0 &&
            //           (previousBrandValues[brand.name].value < brand.value
            //             ? '+'
            //             : '') +
            //             Math.round(
            //               ((brand.value -
            //                 previousBrandValues[brand.name].value) /
            //                 previousBrandValues[brand.name].value) *
            //                 1000
            //             ) /
            //               10 +
            //             '%'}
            //       </span>,
            //     ]
            //   : []),
          ]),
        }}
      />
    </>
  )
}

const Graphs = ({
  data,
  start,
  end,
  brandLogos,
}: {
  data: BrandDataRecord
  start: YearMonthObject
  end: YearMonthObject
  brandLogos: Record<string, string>
}) => {
  const unitState = useState<'Sum' | 'Count'>('Sum')
  const stackedState = useState(false)
  const timeFrameState = useState<'1w' | '1m' | '4w'>('1m')
  const unit = unitState[0]

  const minDate = toDate(start)
  const maxDate = toDate(addMonths(end, 1))

  if (!data) return <></>

  return (
    <>
      <DashboardSection title='Market Share Summary'>
        <div className='my-8 flex justify-center'>
          <SmallPieChart
            data={reduceToTen(
              Object.entries(data).reduce(dataReducerBetween(start, end), [])
            )}
            id='pie-chart'
            currency={CURRENCY_SYMBOL}
          />
        </div>
        <Performance
          data={data}
          startDate={start}
          endDate={end}
          brandLogos={brandLogos}
        />
      </DashboardSection>
      <div className='mt-16'>
        <DashboardSection title='Aggregated Spend'>
          <GraphConfig
            showStackedToggle={Object.keys(data).length > 1}
            {...{ unitState, stackedState, timeFrameState }}
          />
          <LineGraph
            data={data}
            id='spend-amount'
            showAmount={unit === 'Sum'}
            stacked={stackedState[0]}
            timeFrame={timeFrameState[0]}
            {...{ minDate, maxDate }}
          />
        </DashboardSection>
      </div>
    </>
  )
}

export default MarketTrends

const arraysEqual = (a: string[], b: string[]) =>
  !!a && !!b && !(a < b || b < a)
