import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import ReactTooltip from 'react-tooltip'

export const FactSheetItem = ({
  info,
  title,
  children,
}: {
  info?: string
  title: string
  children: ReactNode
}) => (
  <div
    key={title}
    className='flex flex-col justify-between overflow-hidden rounded-lg border px-4 py-4'>
    <dd className='mb-6 mt-1 text-2xl font-bold text-gray-900'>{children}</dd>
    <dt className='text-xs font-medium text-gray-500'>
      {title.split(' ').slice(0, -1).join(' ')}{' '}
      <span className='whitespace-nowrap'>
        {title.split(' ').slice(-1)}
        {info && (
          <>
            {' '}
            <FontAwesomeIcon
              data-for={title}
              data-tip={
                '<div class="w-52 whitespace-pre-wrap">' + info + '</div>'
              }
              data-html={true}
              icon={faInfoCircle}
            />
            <ReactTooltip
              className='font-normal'
              effect='solid'
              id={title}
              multiline={true}
              place='bottom'
            />
          </>
        )}
      </span>
    </dt>
  </div>
)

export const FactSheet = ({ children }: { children: ReactNode }) => (
  <dl className='grid grid-cols-1 gap-3 sm:grid-cols-3'>{children}</dl>
)
