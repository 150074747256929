import { AuthState } from '@aws-amplify/ui-components'

const initialState = {
  authState: AuthState.SignedOut,
  user: {},
  userGroups: [],
  hasLoadedUser: false,
  menuState: { isOpen: true, closedSections: [] },
  internalDemographics: {},
  internalDemographicsCoverage: {},
  connectionHubDemogs: process.env.REACT_APP_ENV === 'us' ? false : null,
  internalCategorizedBrands: [],
  internalUnCategorizedBrands: [],
  trial: true,
}

export type User =
  | {
      attributes: {
        email: string
        'custom:brand'?: string
        'custom:APIaccess'?: 'yes' | 'no'
        'custom:uuid'?: string
      }
      username: string
    }
  | object

export type State = {
  authState: AuthState
  user: User
  userGroups: string[]
  hasLoadedUser: boolean
  menuState: { isOpen: boolean; closedSections: string[] }
  internalDemographics: object
  internalDemographicsCoverage: object
  connectionHubDemogs: boolean | null
  internalCategorizedBrands: object[]
  internalUnCategorizedBrands: object[]
  trial: boolean
}

type Action =
  | {
      type: 'changeAuthState'
      payload: AuthState
    }
  | {
      type: 'changeMenuIsOpen' | 'changeTrial'
      payload: boolean
    }
  | {
      type: 'changeClosedMenuSections' | 'changeUserGroups'
      payload: string[]
    }
  | {
      type: 'changeState'
      payload: Partial<State>
    }
  | {
      type: 'changeUser'
      payload: User
    }
  | {
      type: 'changeInternalDemographics' | 'changeInternalDemographicsCoverage'
      payload: object
    }
  | {
      type: 'changeInternalCatBrands' | 'changeInternalUnCatBrands'
      payload: object[]
    }
  | {
      type: 'logout'
    }
  | {
      type: 'changeConnectionHubDemogs'
      payload: boolean
    }

const rootReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'logout':
      return { ...initialState, hasLoadedUser: true }
    case 'changeAuthState':
      return { ...state, authState: action.payload }
    case 'changeMenuIsOpen':
      return {
        ...state,
        menuState: { ...state.menuState, isOpen: action.payload },
      }
    case 'changeClosedMenuSections':
      return {
        ...state,
        menuState: {
          ...state.menuState,
          closedSections: action.payload,
        },
      }
    case 'changeState':
      return {
        ...state,
        ...action.payload,
      }
    case 'changeUser':
      return {
        ...state,
        user: action.payload,
        authState: AuthState.SignedIn,
        hasLoadedUser: true,
        trial: !(
          (action.payload &&
            typeof action.payload === 'object' &&
            'attributes' in action.payload &&
            action.payload.attributes &&
            action.payload.attributes.email.endsWith('@yougov.com')) ||
          (Array.isArray(state.userGroups) &&
            state.userGroups.includes('premium'))
        ),
      }
    case 'changeInternalDemographics':
      return {
        ...state,
        internalDemographics: action.payload,
      }
    case 'changeInternalDemographicsCoverage':
      return {
        ...state,
        internalDemographicsCoverage: action.payload,
      }
    case 'changeConnectionHubDemogs':
      return {
        ...state,
        connectionHubDemogs: action.payload,
      }
    case 'changeInternalCatBrands':
      return {
        ...state,
        internalCategorizedBrands: action.payload,
      }
    case 'changeInternalUnCatBrands':
      return {
        ...state,
        internalUnCategorizedBrands: action.payload,
      }
    case 'changeUserGroups':
      return {
        ...state,
        userGroups: action.payload,
        trial: !(
          ('attributes' in state.user &&
            state.user.attributes.email.endsWith('@yougov.com')) ||
          (Array.isArray(action.payload) && action.payload.includes('premium'))
        ),
      }
    case 'changeTrial':
      return {
        ...state,
        trial: action.payload,
      }
    default:
      return { ...state }
  }
}

export default rootReducer
