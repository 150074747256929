import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { Grid, Input } from 'react-spreadsheet-grid'
import DashboardPage from '../../../content/DashboardPage'

const UserRecord = () => {
  const [pmx_id, setPmxId] = useState('')
  const [email, setEmail] = useState('')
  const [data, setData] = useState({
    accounts: [],
    logs: [],
    deletedAccounts: {},
  })
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const getData = method => {
    if (
      ((pmx_id && method === 'pmxid') || (email && method === 'email')) &&
      !loading
    ) {
      setErrorMessage('')
      setLoading(true)
      setData({
        accounts: [],
        logs: [],
        deletedAccounts: {},
      })
      const params = method === 'pmxid' ? { pmx_id: pmx_id } : { email: email }
      API.get('clientGateway', '/internal/userrecord', {
        queryStringParameters: params,
      })
        .then(res => {
          if (res.error) {
            if (res.error === 'Not Found') {
              setErrorMessage('User Not Found')
            } else {
              setErrorMessage('Something went wrong. Please try again later')
            }
            console.error(res.error)
          } else {
            let data = res
            data['deletedAccounts'] = {}
            let delAccount = 1
            for (let log of data['logs']) {
              if (
                log['account_id'] &&
                data['accounts'].filter(ac => ac.id === log['account_id'])
                  .length === 0 &&
                Object.prototype.hasOwnProperty.call(
                  !data['deletedAccounts'],
                  log['account_id']
                )
              ) {
                data['deletedAccounts'][log['account_id']] =
                  'deleted' + delAccount.toString()
                delAccount += 1
              }
            }
            setData(data)
            if (data['accounts'].length === 0 && data['logs'].length === 0) {
              setErrorMessage('User has not added any accounts yet')
            }
          }
          setLoading(false)
        })
        .catch(e => {
          setErrorMessage('Something went wrong. Please try again later')
          setLoading(false)
          console.error(e)
        })
    }
  }

  const getTodayDate = () => {
    let today = new Date()
    let dd = String(today.getDate()).padStart(2, '0')
    let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    let yyyy = today.getFullYear()
    return mm + '-' + dd + '-' + yyyy
  }

  const DisplayAccounts = () => {
    return (
      <div className='mt-2.5'>
        <span>Accounts:</span>
        <Grid
          columns={[
            {
              id: 1,
              title: () => (
                <button
                  onClick={() => {
                    let accounts = data['accounts']
                    if (
                      accounts.length > 0 &&
                      accounts[0].provider_display_name >
                        accounts[accounts.length - 1].provider_display_name
                    ) {
                      accounts = accounts.sort((a, b) =>
                        a['provider_display_name'].localeCompare(
                          b['provider_display_name']
                        )
                      )
                    } else {
                      accounts = accounts.sort((a, b) =>
                        b['provider_display_name'].localeCompare(
                          a['provider_display_name']
                        )
                      )
                    }
                    setData({
                      accounts: accounts,
                      logs: data['logs'],
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Provider
                </button>
              ),
              value: row => {
                return (
                  <div className='flex flex-row justify-start'>
                    {row.provider_logo_uri !== '-' && (
                      <img
                        className='mr-2 h-4'
                        src={row.provider_logo_uri}
                        alt='provider logo'
                      />
                    )}
                    {row.provider_display_name}
                  </div>
                )
              },
            },
            {
              id: 2,
              title: () => (
                <button
                  onClick={() => {
                    let accounts = data['accounts']
                    if (
                      accounts.length > 0 &&
                      accounts[0].account_type >
                        accounts[accounts.length - 1].account_type
                    ) {
                      accounts = accounts.sort((a, b) =>
                        a['account_type'].localeCompare(b['account_type'])
                      )
                    } else {
                      accounts = accounts.sort((a, b) =>
                        b['account_type'].localeCompare(a['account_type'])
                      )
                    }
                    setData({
                      accounts: accounts,
                      logs: data['logs'],
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Type
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={
                      row.account_type === '-'
                        ? row.card_type
                        : row.account_type
                    }
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
              width: 20,
            },
            {
              id: 3,
              title: () => (
                <button
                  onClick={() => {
                    let accounts = data['accounts']
                    if (
                      accounts.length > 0 &&
                      accounts[0].number > accounts[accounts.length - 1].number
                    ) {
                      accounts = accounts.sort((a, b) =>
                        a['number'].localeCompare(b['number'])
                      )
                    } else {
                      accounts = accounts.sort((a, b) =>
                        b['number'].localeCompare(a['number'])
                      )
                    }
                    setData({
                      accounts: accounts,
                      logs: data['logs'],
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Last Digits
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={
                      row.number === '-'
                        ? row.partial_card_number
                        : row.number.slice(4)
                    }
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
              width: 8,
            },
            {
              id: 4,
              title: () => (
                <button
                  onClick={() => {
                    let accounts = data['accounts']
                    if (
                      accounts.length > 0 &&
                      accounts[0].currency >
                        accounts[accounts.length - 1].currency
                    ) {
                      accounts = accounts.sort((a, b) =>
                        a['currency'].localeCompare(b['currency'])
                      )
                    } else {
                      accounts = accounts.sort((a, b) =>
                        b['currency'].localeCompare(a['currency'])
                      )
                    }
                    setData({
                      accounts: accounts,
                      logs: data['logs'],
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Currency
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.currency}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
              width: 8,
            },
            {
              id: 5,
              title: () => (
                <button
                  onClick={() => {
                    let accounts = data['accounts']
                    if (
                      accounts.length > 0 &&
                      new Date(accounts[0].createdAt) <
                        new Date(accounts[accounts.length - 1].createdAt)
                    ) {
                      accounts = accounts.sort(
                        (a, b) =>
                          new Date(b['createdAt']) - new Date(a['createdAt'])
                      )
                    } else {
                      accounts = accounts.sort(
                        (a, b) =>
                          new Date(a['createdAt']) - new Date(b['createdAt'])
                      )
                    }
                    setData({
                      accounts: accounts,
                      logs: data['logs'],
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Created
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.createdAt}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
              width: 18,
            },
            {
              id: 6,
              title: () => (
                <button
                  onClick={() => {
                    let accounts = data['accounts']
                    if (
                      accounts.length > 0 &&
                      new Date(accounts[0].consent_last_renewed) <
                        new Date(
                          accounts[accounts.length - 1].consent_last_renewed
                        )
                    ) {
                      accounts = accounts.sort(
                        (a, b) =>
                          new Date(b['consent_last_renewed']) -
                          new Date(a['consent_last_renewed'])
                      )
                    } else {
                      accounts = accounts.sort(
                        (a, b) =>
                          new Date(a['consent_last_renewed']) -
                          new Date(b['consent_last_renewed'])
                      )
                    }
                    setData({
                      accounts: accounts,
                      logs: data['logs'],
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Consent Last Renewed
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.consent_last_renewed}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
              width: 18,
            },
            {
              id: 7,
              title: () => (
                <button
                  onClick={() => {
                    let accounts = data['accounts']
                    if (
                      accounts.length > 0 &&
                      accounts[0].expired.toString() >
                        accounts[accounts.length - 1].expired.toString()
                    ) {
                      accounts = accounts.sort((a, b) =>
                        a['expired']
                          .toString()
                          .localeCompare(b['expired'].toString())
                      )
                    } else {
                      accounts = accounts.sort((a, b) =>
                        b['expired']
                          .toString()
                          .localeCompare(a['expired'].toString())
                      )
                    }
                    setData({
                      accounts: accounts,
                      logs: data['logs'],
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Expired
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.expired.toString()}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
              width: 8,
            },
          ]}
          rows={data['accounts']}
          getRowKey={row => row.id}
        />
      </div>
    )
  }
  const DisplayLogs = () => {
    return (
      <div className='mt-4 h-96'>
        <div className='flex flex-row justify-between'>
          <span>Logs:</span>
          <CSVLink
            data={data['logs'].map(l => {
              const ac_idx = data['accounts'].findIndex(
                a => a.id === l['account_id']
              )
              return {
                Date: l['date'],
                Event: l['event'],
                Account:
                  ac_idx > -1
                    ? data['accounts'][ac_idx].provider_display_name +
                      ' ' +
                      (data['accounts'][ac_idx].account_type !== '-'
                        ? data['accounts'][ac_idx].account_type
                        : data['accounts'][ac_idx].card_type) +
                      ' ' +
                      (data['accounts'][ac_idx].number === '-'
                        ? data['accounts'][ac_idx].partial_card_number
                        : data['accounts'][ac_idx].number.slice(4))
                    : data['deletedAccounts'][l['account_id']]
                    ? data['deletedAccounts'][l['account_id']]
                    : '',
              }
            })}
            filename={pmx_id + '_logs_' + getTodayDate() + '.csv'}
            className='underline'>
            Download CSV
          </CSVLink>
        </div>
        <Grid
          isScrollable
          columns={[
            {
              id: 1,
              title: () => (
                <button
                  onClick={() => {
                    let logs = data['logs']
                    if (
                      logs.length > 0 &&
                      new Date(logs[0].date) >
                        new Date(logs[logs.length - 1].date)
                    ) {
                      logs = logs.sort(
                        (a, b) => new Date(a['date']) - new Date(b['date'])
                      )
                    } else {
                      logs = logs.sort(
                        (a, b) => new Date(b['date']) - new Date(a['date'])
                      )
                    }
                    setData({
                      accounts: data['accounts'],
                      logs: logs,
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Date
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.date}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
            },
            {
              id: 2,
              title: () => (
                <button
                  onClick={() => {
                    let logs = data['logs']
                    if (
                      logs.length > 0 &&
                      logs[0].event > logs[logs.length - 1].event
                    ) {
                      logs = logs.sort((a, b) =>
                        a['event'].localeCompare(b['event'])
                      )
                    } else {
                      logs = logs.sort((a, b) =>
                        b['event'].localeCompare(a['event'])
                      )
                    }
                    setData({
                      accounts: data['accounts'],
                      logs: logs,
                      deletedAccounts: data['deletedAccounts'],
                    })
                  }}>
                  Event
                </button>
              ),
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.event}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
            },
            {
              id: 3,
              title: () => <span className='font-normal'>Account</span>,
              value: (row, { focus }) => {
                let acc = data['accounts'].filter(
                  ac => ac.id === row.account_id
                )
                let delacc = data['deletedAccounts'][row.account_id]
                return (
                  <Input
                    value={
                      acc.length > 0
                        ? acc[0].provider_display_name +
                          ' ' +
                          (acc[0].account_type !== '-'
                            ? acc[0].account_type
                            : acc[0].card_type) +
                          ' ' +
                          (acc[0].number === '-'
                            ? acc[0].partial_card_number
                            : acc[0].number.slice(4))
                        : delacc
                        ? delacc
                        : ''
                    }
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={() => {}}
                  />
                )
              },
            },
          ]}
          rows={data['logs']}
          getRowKey={row => row.id}
        />
      </div>
    )
  }

  return (
    <DashboardPage
      className='UserRecord'
      title='User Record'
      description={
        'Tool to see which accounts a panelist has added and their interaction history with our service.'
      }>
      <div className='mt-2.5 flex flex-col justify-start'>
        <span>
          For more information on the current point rewarding rules, please
          refer to this{' '}
          <a
            className='text-blue-500'
            href={
              'https://confluence.yougov.net/pages/viewpage.action?spaceKey=YGPD&title=Documentation'
            }>
            confluence page
          </a>
        </span>
      </div>
      <div className={'flex flex-row items-center justify-start'}>
        <div className='mt-4 flex h-12 w-full items-center justify-start'>
          <span className='font-bold'>Enter pmx_id to search for: </span>
          <input
            className='ml-4 h-5/6 border'
            onChange={event => {
              setPmxId(event.target.value.replace(/\D/g, ''))
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                getData('pmxid')
              }
            }}
            value={pmx_id}
            placeholder='pmx_id'
          />
          <button className='ml-4' onClick={() => getData('pmxid')}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <div className='mt-4 flex h-12 w-full items-center justify-start'>
          <span className='font-bold'>or search by email:</span>
          <input
            className='w-50 ml-4 h-5/6 border'
            onChange={event => {
              setEmail(event.target.value.replace(' ', ''))
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                getData('email')
              }
            }}
            value={email}
            placeholder='email'
          />
          <button className='ml-4' onClick={() => getData('email')}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      {loading && <div className='mt-2.5'>Loading...</div>}
      {errorMessage && <div className='mt-2.5'>{errorMessage}</div>}
      {!loading && data['accounts'].length > 0 && DisplayAccounts()}
      {!loading && data['logs'].length > 0 && DisplayLogs()}
    </DashboardPage>
  )
}

export default UserRecord
