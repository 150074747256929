import API from '@aws-amplify/api'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { z, ZodSchema } from 'zod'
import { freeData } from '../dashboard/reports/freeData'

// types
import { State } from '../store/rootReducer'

const useReportAPI = <T extends ZodSchema>(args: ReportApiArgsType<T>) => {
  const user = useSelector(({ user }: State) => user)
  const user_id = 'username' in user ? user.username : ''
  const email = 'attributes' in user ? user.attributes.email : ''

  const queryStringParameters = {
    user_id,
    email,
    ...('enabled' in args ? { report_id: args.reportId } : {}),
  }
  return useQuery(
    ['report', user_id, email, args],
    async () => {
      const res =
        'reportId' in args && args.reportId === 'free'
          ? { data: [freeData] }
          : await API.get('clientGateway', '/data/reportData', {
              queryStringParameters,
            })
      return args.validator.parse(res.data)
    },
    args.enabled !== undefined
      ? { enabled: args.enabled, refetchInterval: args.refetchInterval }
      : {}
  )
}

export const useReport = (reportId: string) =>
  useReportAPI({
    reportId,
    enabled: reportId !== '',
    refetchInterval: data => {
      if (data === undefined || (reportId && data[0].status === 'running'))
        return 1000
      return Infinity
    },
    validator: reportsSchema,
  })

export const useReports = () =>
  useReportAPI({ validator: purchasedReportMetadatasSchema })

export const createReportReturnSchema = z.object({
  checkout_id: z.string(),
  report_id: z.string(),
  payment_status: z.string(),
})

export const useCreateReport = ({
  email,
  enabled,
  sample_id,
  brand,
  uuid,
}: {
  email: string
  enabled: boolean
  sample_id: string | undefined
  brand: string
  uuid: string
}) =>
  useQuery(
    ['createReport', email, sample_id, brand],
    async () =>
      createReportReturnSchema.parse(
        await API.post('clientGateway', '/data/report', {
          body: {
            email,
            sample_id,
            brand,
            uuid,
          },
        })
      ),
    {
      enabled: !!sample_id && enabled,
    }
  )

const confirmReportPaymentSchema = z.object({
  payment_status: z.union([
    z.literal('created'),
    z.literal('address_submitted'),
    z.literal('payment_initiated'),
    z.literal('payment_processing'),
    z.literal('payment_success'),
    z.literal('payment_fail'),
  ]),
})

export const useConfirmReportPayment = ({
  report_id,
  checkout_id,
  paymentModalHasClosed,
}: {
  report_id: string | undefined
  checkout_id: string | undefined
  paymentModalHasClosed: boolean
}) =>
  useQuery(
    ['confirmReportPayment', report_id, checkout_id, paymentModalHasClosed],
    async () =>
      confirmReportPaymentSchema.parse(
        await API.post('clientGateway', '/data/confirmReportPayment', {
          body: {
            report_id,
            checkout_id,
          },
        })
      ).payment_status,
    {
      enabled: !!report_id && !!checkout_id && paymentModalHasClosed,
      refetchInterval: data => {
        if (
          !data ||
          !(typeof data === 'string') ||
          (data !== 'created' &&
            data !== 'payment_fail' &&
            data !== 'payment_success')
        )
          return 1000
        return Infinity
      },
    }
  )

// type used when fetching a single report
const reportSchema = z.object({
  age_dist: z.record(z.number()).optional(),
  avg_amount: z
    .union([z.number(), z.string().transform(x => parseInt(x))])
    .optional(),
  avg_freq_days: z
    .union([z.number(), z.string().transform(x => parseInt(x))])
    .optional(),
  avg_total_month: z
    .union([z.number(), z.string().transform(x => parseInt(x))])
    .optional(),
  avg_nr_trans_month: z
    .union([z.number(), z.string().transform(x => parseInt(x))])
    .optional(),
  brand: z.string(),
  email: z.string(),
  four_weekly_counts: z.record(z.array(z.number())).nullable().optional(),
  four_weekly_totals: z.record(z.array(z.number())).optional(),
  gend_dist: z.record(z.number()).optional(),
  monthly_counts: z.record(z.array(z.number())).nullable().optional(),
  monthly_totals: z.record(z.array(z.number())).optional(),
  nr_trans: z
    .union([z.number(), z.string().transform(x => parseInt(x))])
    .optional(),
  nr_users: z
    .union([z.number(), z.string().transform(x => parseInt(x))])
    .optional(),
  payment_status: z.string(),
  region_dist: z.record(z.number()).optional(),
  report_id: z.string(),
  sample_id: z.string(),
  status: z.string(),
  switch_dist: z
    .object({
      from: z.record(z.number()),
      to: z.record(z.number()),
    })
    .nullable()
    .optional(),
  timestamp: z.string().datetime(),
  top_shop_dist: z.record(z.record(z.number())).optional(),
  weekly_counts: z.record(z.array(z.number())).nullable().optional(),
  weekly_totals: z.record(z.array(z.number())).optional(),
})
export type Report = z.infer<typeof reportSchema>
const reportsSchema = z.array(reportSchema).nonempty()

// type used when listing all a users reports
const reportMetadataSchema = reportSchema
  .pick({
    brand: true,
    report_id: true,
    sample_id: true,
    timestamp: true,
    payment_status: true,
  })
  .extend({
    max_date: z.string().datetime(),
    min_date: z.string().datetime(),
  })
export type ReportMetadata = z.infer<typeof reportMetadataSchema>
const purchasedReportMetadatasSchema = z
  .array(reportMetadataSchema)
  .transform(reports =>
    reports.filter(r => r.payment_status === 'payment_success')
  )

type ReportApiArgsType<T extends ZodSchema> = {
  validator: T
} & (
  | {
      enabled?: never
      refetchInterval?: never
      reportId?: never
    }
  | {
      enabled: boolean
      refetchInterval: (x: undefined | z.infer<T>) => number
      reportId: string | undefined
    }
)
