import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'

const Select = ({ allowMultiple, options, state: [selected, setSelected] }) => (
  <Listbox value={selected} onChange={setSelected} multiple={allowMultiple}>
    {({ open }) => (
      <div className='relative'>
        <Listbox.Button className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'>
          <span className='block truncate'>
            {allowMultiple
              ? selected.length > 0
                ? selected.join(', ')
                : 'Select...'
              : selected}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <SelectorIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </Listbox.Button>

        <Transition
          show={open}
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <Listbox.Options
            unmount={false}
            className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {options.map(option => {
              const optionSelected = selected.includes(option)
              return (
                <Listbox.Option
                  key={option}
                  className={({ active }) =>
                    (active ? 'bg-indigo-600 text-white' : 'text-gray-900') +
                    ' relative cursor-default select-none py-2 pl-8 pr-4'
                  }
                  value={option}>
                  <span
                    className={
                      (optionSelected ? 'font-semibold' : 'font-normal') +
                      ' block truncate'
                    }>
                    {option}
                  </span>

                  {optionSelected && (
                    <span
                      className={
                        'absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600'
                      }>
                      <CheckIcon className='h-5 w-5' aria-hidden='true' />
                    </span>
                  )}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
)

export default Select
