import {
  faArrowDown,
  faArrowUp,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SmallPieChart } from 'ygf_graphs'
import DashboardSection from '../../../components/dashboard/DashboardSection'
import LightWeightChartWrapper from '../../../components/dashboard/LightWeightChartWrapper'
import { CURRENCY_SYMBOL } from '../../../constants'
import {
  useBrandQuickInsights,
  useSectorQuickInsights,
} from '../../../hooks/useInsights'
import { FactSheet, FactSheetItem } from '../../components/FactSheet'
import DashboardPage from '../../content/DashboardPage'

const ArrowIcon = ({ up }: { up: boolean }) => (
  <FontAwesomeIcon
    className={up ? 'text-green-500' : 'text-black'}
    icon={up ? faArrowUp : faArrowDown}
  />
)

const priceChangeDisplayFunc = (v: number) => (
  <>
    {v > 0 ? <ArrowIcon up={true} /> : v < 0 && <ArrowIcon up={false} />}{' '}
    {v > 0 && '+'}
    {Number(v) > 10 ? Math.round(Number(v)) : Number(v).toPrecision(2)}%
  </>
)

const MyBrandInsights = ({ brand }: { brand: string }) => {
  const header = `${brand} Quick Insights`

  const {
    data: brandData,
    isLoading,
    isFetching: brandFetching,
  } = useBrandQuickInsights(brand)

  const { factSheetData, marketShareData, this_month } = brandData ?? {
    factSheetData: null,
    marketShareData: null,
    this_month: null,
  }

  const { data: sectorData, isFetching: sectorFetching } =
    useSectorQuickInsights(marketShareData)

  if (
    !brand ||
    (!(brandFetching || isLoading) &&
      (!factSheetData?.spendDelta || !factSheetData?.industrySpendDelta))
  )
    return <>Something went wrong.</>

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const marketShareDataWithOther = Array.isArray(marketShareData)
    ? [...marketShareData]
    : []
  marketShareDataWithOther.sort((a, b) => b.value - a.value)
  while (marketShareDataWithOther.length > 10) {
    const last = marketShareDataWithOther.pop()
    const secondLast = marketShareDataWithOther.pop()
    marketShareDataWithOther.push({
      name: 'Other',
      value: last.value + secondLast.value,
    })
  }

  return (
    <DashboardPage
      description='Some quick insights about your brand.'
      info='For more in depth analysis, we invite you to look at our other products in the menu or by clicking any of the "See more" buttons below.'
      title={header}>
      <DashboardSection
        description={`Growth in ${months[this_month]} for ${brand} and its sector.`}
        seeMore={`/dashboard/brand-profiles/${brand}`}
        title={`${brand} vs. sector growth`}>
        {brandFetching || isLoading ? (
          <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
          <FactSheet>
            <FactSheetItem title={`${brand} Monthly Spend`}>
              {priceChangeDisplayFunc(factSheetData.spendDelta)}
            </FactSheetItem>
            <FactSheetItem
              title={`${factSheetData?.category} Sector Monthly Spend`}>
              {priceChangeDisplayFunc(factSheetData.industrySpendDelta)}
            </FactSheetItem>
            <FactSheetItem
              title={`${brand} vs ${factSheetData?.category} Sector`}>
              {priceChangeDisplayFunc(
                factSheetData.spendDelta - factSheetData.industrySpendDelta
              )}
            </FactSheetItem>
          </FactSheet>
        )}
      </DashboardSection>
      <DashboardSection
        description={`${brand}'s market share within the sector for ${months[this_month]}.`}
        seeMore={
          marketShareData !== null
            ? `/dashboard/market-trends/brands=${marketShareData
                .map((b: (typeof marketShareData)[number]) => b.name)
                .join(',')}`
            : undefined
        }
        title='Market share'>
        {brandFetching || isLoading ? (
          <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
          <SmallPieChart
            currency={CURRENCY_SYMBOL}
            data={marketShareDataWithOther}
            id='pie-chart'
          />
        )}
      </DashboardSection>
      <DashboardSection
        description='Sales growth for Disney+ and other sector brands.'
        seeMore={
          marketShareData !== null
            ? `/dashboard/market-trends/brands=${marketShareData
                .map((b: (typeof marketShareData)[number]) => b.name)
                .join(',')}`
            : undefined
        }
        title='Sector sales growth'>
        {sectorFetching || !sectorData ? (
          <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : !sectorData ? (
          <></>
        ) : (
          <LightWeightChartWrapper
            stacked={false}
            data={sectorData}
            id='age-chart'
          />
        )}
      </DashboardSection>
    </DashboardPage>
  )
}

export default MyBrandInsights
