import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { CSVLink } from 'react-csv'
import { useSelector } from 'react-redux'
import store from '../../../../store/store'
import DashboardPage from '../../../content/DashboardPage'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const Demographics = () => {
  const data = useSelector(({ internalDemographics }) => internalDemographics)
  const coverageData = useSelector(
    ({ internalDemographicsCoverage }) => internalDemographicsCoverage
  )
  const connectionHubDemogs = useSelector(
    ({ connectionHubDemogs }) => connectionHubDemogs
  )
  const [loading, setLoading] = useState(false)
  const csvLink1 = useRef()
  const csvLink2 = useRef()

  useEffect(() => {
    if (Object.keys(data).length === 0 && connectionHubDemogs !== null) {
      setLoading(true)
      API.get('clientGateway', '/internal/demographics', {
        queryStringParameters: { conhub: connectionHubDemogs },
      })
        .then(res => {
          store.dispatch({
            type: 'changeInternalDemographics',
            payload: res,
          })
          setLoading(false)
        })
        .catch(e => console.error(e))
    }
  }, [data, connectionHubDemogs])

  useEffect(() => {
    if (
      Object.keys(coverageData).length === 0 &&
      connectionHubDemogs === false
    ) {
      API.get('clientGateway', '/internal/demographics', {
        queryStringParameters: {
          mode: 'coverage',
          conhub: connectionHubDemogs,
        },
      })
        .then(res => {
          store.dispatch({
            type: 'changeInternalDemographicsCoverage',
            payload: res,
          })
        })
        .catch(e => console.error(e))
    }
  }, [coverageData, connectionHubDemogs])

  const reload = () => {
    store.dispatch({
      type: 'changeInternalDemographics',
      payload: {},
    })
    store.dispatch({
      type: 'changeInternalDemographicsCoverage',
      payload: {},
    })
  }

  return (
    <DashboardPage
      className='Demographics'
      title='Sample Demographics'
      description={'Tool to view demographic breakdown of invited panel'}>
      {process.env.REACT_APP_ENV !== 'us' && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            gap: 10,
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          Select Panel:
          <button
            style={{
              padding: 6,
              borderRadius: 10,
              backgroundColor:
                connectionHubDemogs === true ? 'gray' : 'rgb(230,230,230)',
              fontWeight: connectionHubDemogs === true ? 'bold' : 'normal',
            }}
            onClick={() => {
              if (connectionHubDemogs !== true) {
                store.dispatch({
                  type: 'changeConnectionHubDemogs',
                  payload: true,
                })
                reload()
              }
            }}>
            Connection Hub
          </button>
          <button
            style={{
              padding: 6,
              borderRadius: 10,
              backgroundColor:
                connectionHubDemogs === false ? 'gray' : 'rgb(230,230,230)',
              fontWeight: connectionHubDemogs === false ? 'bold' : 'normal',
            }}
            onClick={() => {
              if (connectionHubDemogs !== false) {
                store.dispatch({
                  type: 'changeConnectionHubDemogs',
                  payload: false,
                })
                reload()
              }
            }}>
            Finance
          </button>
        </div>
      )}
      {loading && <div className='mt-2.5'>Loading...</div>}
      {!loading && connectionHubDemogs !== null && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <button
            style={{ justifySelf: 'flex-end', marginTop: -30 }}
            onClick={() => reload()}>
            <FontAwesomeIcon style={{ width: 30 }} icon={faArrowsRotate} />
          </button>
        </div>
      )}
      <div style={{ height: 10 }}>{}</div>
      {!loading &&
        Object.keys(data).length > 0 &&
        Object.keys(data['totals']).length > 0 &&
        Object.keys(data['totals']).map((total, idx) => (
          <div key={idx} className='mt-1'>
            <span>{total + ': ' + data['totals'][total]}</span>
          </div>
        ))}
      {!loading &&
        Object.keys(data).length > 0 &&
        (data['inactive_users'].length > 0 ||
          data['only_inactive_users'].length > 0) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 15,
            }}>
            {data['inactive_users'].length > 0 && (
              <div
                style={{
                  width: 300,
                  backgroundColor: 'lightgray',
                  borderRadius: 10,
                  padding: 3,
                  marginRight: 15,
                }}>
                <button
                  style={{ width: '100%' }}
                  onClick={() => csvLink1.current.link.click()}>
                  Download pmx_ids of users with at least one inactive account
                </button>
                <CSVLink
                  ref={csvLink1}
                  filename={'inactive_' + new Date().toLocaleString() + '.csv'}
                  data={data['inactive_users'].map(u => {
                    return { pmxid: u }
                  })}></CSVLink>
              </div>
            )}
            {data['only_inactive_users'].length > 0 && (
              <div
                style={{
                  width: 300,
                  backgroundColor: 'lightgray',
                  borderRadius: 10,
                  padding: 3,
                }}>
                <button
                  style={{ width: '100%' }}
                  onClick={() => csvLink2.current.link.click()}>
                  Download pmx_ids of users with only inactive accounts
                </button>
                <CSVLink
                  ref={csvLink2}
                  filename={
                    'only_inactive_' + new Date().toLocaleString() + '.csv'
                  }
                  data={data['only_inactive_users'].map(u => {
                    return { pmxid: u }
                  })}></CSVLink>
              </div>
            )}
          </div>
        )}
      {!loading &&
        Object.keys(data).length > 0 &&
        Object.keys(data.demogs).length > 0 && (
          <div className='pt-6'>
            <span className='text-xl font-bold'>Demographic breakdowns:</span>
          </div>
        )}
      {!loading &&
        Object.keys(data).length > 0 &&
        Object.keys(data['demogs']).length > 0 &&
        Object.keys(data['demogs']).map(demog => {
          return (
            <table key={demog} className='border-1 my-2.5 border border-black'>
              <thead className='border-1 border border-black'>
                <tr>
                  <th>{demog}</th>
                  <th>Target (%)</th>
                  <th>Value (%)</th>
                  <th>Diff</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {data['demogs'][demog]
                  .sort((a, b) => a['diff'] - b['diff'])
                  .map((d, idx) => (
                    <tr key={idx}>
                      <td className='px-2'>{d[demog]}</td>
                      <td className='px-2'>{d['target']}</td>
                      <td className='px-2'>{d['value']}</td>
                      <td className='px-2'>{d['diff']}</td>
                      <td className='px-2'>{d['count']}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )
        })}
      {!loading && Object.keys(coverageData).length > 0 && (
        <div className='w-full'>
          <Bar
            data={{
              labels: coverageData['dates'],
              datasets: [
                {
                  label: 'Coverage Data',
                  backgroundColor: 'black',
                  data: coverageData['counts'],
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  ticks: { beginAtZero: true },
                  title: {
                    display: true,
                    text: 'Nr of users with transactions',
                  },
                },
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: 'Account Coverage over time',
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      )}
    </DashboardPage>
  )
}

export default Demographics
