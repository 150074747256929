import { ReactNode } from 'react'
import { SectionHeading } from './SectionHeading'

const Step = ({
  continueButtonText,
  children,
  handleBackClick,
  handleContinueClick,
  showBack = true,
  showContinue = true,
  step,
  title,
  totalSteps,
}: {
  continueButtonText?: string
  children: ReactNode
  handleBackClick?: () => void
  handleContinueClick: () => void
  showBack?: boolean
  showContinue?: boolean
  step: number
  title: string
  totalSteps: number
}) => (
  <>
    <SectionHeading number={step} total={totalSteps} className='mt-8'>
      {title}
    </SectionHeading>

    <h2 className='mb-4 mt-4 block text-6xl font-bold tracking-tighter text-black'>
      {title}
    </h2>

    <div>{children}</div>

    {showContinue && (
      <div className='mt-10'>
        <button
          onClick={handleContinueClick}
          className='w-full rounded-md border border-transparent bg-black px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50'>
          {continueButtonText ?? 'Continue'}
        </button>
      </div>
    )}

    {handleBackClick && showBack && (
      <div className='mt-6 text-center text-sm text-gray-500'>
        <span
          onClick={handleBackClick}
          className='ml-1 cursor-pointer font-medium text-gray-600 hover:text-gray-500'>
          <span aria-hidden='true'>&larr; </span>
          Go back
        </span>
      </div>
    )}
  </>
)

export default Step
