import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Redirect from '../helpers/Redirect'

import Footer from '../components/shared/Footer'
import NavBar from '../components/shared/NavBar'

const NewSignUpPage = () => (
  <Redirect ifAuthStateIs={true} to={'/dashboard'}>
    <div className='d-flex flex-column justify-content-between min-h-screen'>
      <NavBar />
      <Container className='mb-24 mt-12 max-w-md rounded border p-10 shadow-sm'>
        <h2 className='text-lg'>Welcome to YouGov Finance!</h2>
        <p className='text-muted'>
          Please sign in below to access the dashboard.
        </p>
        <div className='mt-2 flex justify-center'>
          <Link
            to='/login'
            className='inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-gradient-to-br from-yougov-orange to-yougov-pink px-4 py-2 text-base font-bold text-white shadow-sm'>
            Sign in
          </Link>
        </div>
      </Container>
      <Footer />
    </div>
  </Redirect>
)

export default NewSignUpPage
