const users_by_bank = [
  {
    bank: 'Amex',
    count: 26,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/amex.svg',
  },
  {
    bank: 'Bank of Scotland',
    count: 41,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/bos.svg',
  },
  {
    bank: 'Barclaycard',
    count: 8,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclaycard.svg',
  },
  {
    bank: 'Barclays',
    count: 200,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclays.svg',
  },
  {
    bank: 'Barclays Business',
    count: 9,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclays.svg',
  },
  {
    bank: 'Capital One',
    count: 12,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/capital-one.svg',
  },
  {
    bank: 'Danske Bank',
    count: 1,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/danske.svg',
  },
  {
    bank: 'HSBC',
    count: 154,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/hsbc.svg',
  },
  {
    bank: 'Halifax',
    count: 205,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/halifax.svg',
  },
  {
    bank: 'Lloyds',
    count: 164,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/lloyds.svg',
  },
  {
    bank: 'M&S Bank',
    count: 6,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/ms.svg',
  },
  {
    bank: 'MBNA',
    count: 4,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/mbna.svg',
  },
  {
    bank: 'Monzo',
    count: 190,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/monzo.svg',
  },
  {
    bank: 'NatWest',
    count: 262,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/natwest.svg',
  },
  {
    bank: 'Nationwide',
    count: 252,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/nationwide.svg',
  },
  {
    bank: 'Revolut',
    count: 39,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/revolut.svg',
  },
  {
    bank: 'Royal Bank of Scotland',
    count: 60,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/rbs.svg',
  },
  {
    bank: 'Santander',
    count: 176,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/santander.svg',
  },
  {
    bank: 'Starling',
    count: 66,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/starling.svg',
  },
  {
    bank: 'TSB',
    count: 46,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tsb.svg',
  },
  {
    bank: 'Tesco Bank',
    count: 20,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tesco.svg',
  },
  {
    bank: 'Tide',
    count: 4,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tide.svg',
  },
  {
    bank: 'Ulster Bank',
    count: 3,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/ulster.svg',
  },
  {
    bank: 'Virgin Money',
    count: 5,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/virgin-money.svg',
  },
  {
    bank: 'Wise',
    count: 6,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/global/logos/transferwise.svg',
  },
  {
    bank: 'first direct',
    count: 96,
    logoLink:
      'https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/first-direct.svg',
  },
]

export default users_by_bank

export const total = 1744
