export const supermarkets = {
  DEBIT: {
    NatWest: 925510,
    Starling: 119508.34375,
    'Barclays Business': 44015.26171875,
    Lloyds: 439612.9375,
    Barclays: 780340.125,
    Tide: 133.8500061035156,
    Wise: 540.2799682617188,
    'Royal Bank of Scotland': 285421.34375,
    HSBC: 490053.65625,
    Santander: 380438.125,
    'Tesco Bank': 17051.37109375,
    Revolut: 44698.8125,
    'first direct': 444595.375,
    'Bank of Scotland': 156315.90625,
    Halifax: 619134.5,
    Monzo: 393223.46875,
    'Ulster Bank': 14896.060546875,
    Nationwide: 536095.4375,
    TSB: 89323.453125,
  },
  CREDIT: {
    AMEX: 150641.8125,
    MASTERCARD: 113095.0625,
    VISA: 14394.2099609375,
  },
}

export const online = {
  DEBIT: {
    NatWest: 271515.0625,
    Starling: 38310.00390625,
    'Barclays Business': 9060.6689453125,
    Lloyds: 143701.765625,
    Barclays: 193375.875,
    'Royal Bank of Scotland': 52793.41015625,
    HSBC: 121634.3671875,
    Santander: 140088.03125,
    'Tesco Bank': 630,
    Revolut: 30086.599609375,
    'first direct': 99750.2578125,
    'Bank of Scotland': 51201.08203125,
    Halifax: 177000.59375,
    'Danske Bank': 124.98999786376953,
    Monzo: 150366.78125,
    'Ulster Bank': 1476,
    Nationwide: 194886.546875,
    TSB: 33786.16015625,
  },
  CREDIT: {
    AMEX: 52130.3828125,
    MASTERCARD: 46711.390625,
    VISA: 3416.199951171875,
  },
}

export const transport = {
  DEBIT: {
    'Bank of Scotland': 552.0900268554688,
    Barclays: 11348.9990234375,
    'Barclays Business': 521.8900146484375,
    'first direct': 5532.91015625,
    Halifax: 9084.7294921875,
    HSBC: 11313.140625,
    Lloyds: 11373.4912109375,
    Monzo: 24203.12109375,
    Nationwide: 11905.0693359375,
    NatWest: 20714.322265625,
    Revolut: 1054.3800048828125,
    'Royal Bank of Scotland': 7093.30029296875,
    Santander: 10312.708984375,
    Starling: 3532.199951171875,
    TSB: 5021.60009765625,
    Wise: 15.329999923706055,
  },
  CREDIT: {
    AMEX: 3237.43994140625,
    MASTERCARD: 3731.10986328125,
    VISA: 544.77001953125,
  },
}

export const highStreetRetail = {
  DEBIT: {
    NatWest: 276702.6875,
    Starling: 20808.71875,
    'Barclays Business': 7192.5703125,
    Lloyds: 134204.703125,
    Barclays: 288931,
    Wise: 178.89999389648438,
    'Royal Bank of Scotland': 62147.6640625,
    HSBC: 132848.328125,
    Santander: 100054.4296875,
    'Tesco Bank': 930.47998046875,
    Revolut: 7095.7705078125,
    'first direct': 203905.203125,
    'Bank of Scotland': 45622.8125,
    Halifax: 154918.9375,
    Monzo: 143510.9375,
    'Ulster Bank': 2696.229248046875,
    Nationwide: 132129.359375,
    TSB: 33799.76953125,
  },
  CREDIT: { AMEX: 37318.375, MASTERCARD: 37211.30078125, VISA: 6354.4296875 },
}

export const gamblingCasinos = {
  DEBIT: {
    NatWest: 198905.5,
    Starling: 313770.25,
    'Barclays Business': 1352.260009765625,
    Lloyds: 95398.8671875,
    Barclays: 115441.65625,
    Wise: 20,
    'Royal Bank of Scotland': 43073.984375,
    HSBC: 119963.9609375,
    Santander: 90867.96875,
    Revolut: 6004.609375,
    'first direct': 48966.83984375,
    'Bank of Scotland': 18396.130859375,
    Halifax: 88784.546875,
    'Danske Bank': 5,
    Monzo: 113365.5625,
    'Ulster Bank': 1124.10009765625,
    Nationwide: 98732.6171875,
    TSB: 17048.880859375,
  },
  CREDIT: { MASTERCARD: 36.54999923706055 },
}

export const amazon = {
  DEBIT: {
    NatWest: 214430.796875,
    Starling: 33027.23828125,
    'Barclays Business': 7232.81982421875,
    Lloyds: 116938.59375,
    Barclays: 166823.140625,
    'Royal Bank of Scotland': 41933.69140625,
    HSBC: 102855.78125,
    Santander: 118665.9375,
    'Tesco Bank': 398.6000061035156,
    Revolut: 19128.2890625,
    'first direct': 83372.1640625,
    'Bank of Scotland': 45798.578125,
    Halifax: 142291.375,
    'Danske Bank': 64.98999786376953,
    Monzo: 133868.90625,
    'Ulster Bank': 1409,
    Nationwide: 144142.34375,
    TSB: 29635.169921875,
  },
  CREDIT: {
    AMEX: 49393.70703125,
    MASTERCARD: 36494.05859375,
    VISA: 2013.679931640625,
  },
}

export const netflix = {
  DEBIT: {
    'Bank of Scotland': 2463.9296875,
    Barclays: 9266.87890625,
    'Barclays Business': 559.449951171875,
    'Danske Bank': 5.989999771118164,
    'first direct': 2776.109619140625,
    Halifax: 6642.05859375,
    HSBC: 5756.06982421875,
    Lloyds: 5792.65966796875,
    Monzo: 4941.5791015625,
    Nationwide: 5935.6494140625,
    NatWest: 9595.240234375,
    Revolut: 298.6300048828125,
    'Royal Bank of Scotland': 3728.679443359375,
    Santander: 4754.60986328125,
    Starling: 1398.1998291015625,
    TSB: 1240.699951171875,
    'Ulster Bank': 249.72994995117188,
  },
  CREDIT: {
    AMEX: 1251.5899658203125,
    MASTERCARD: 1175.68994140625,
    VISA: 105.88999938964844,
  },
}

export const uber = {
  DEBIT: {
    'Bank of Scotland': 369.59002685546875,
    Barclays: 6547.26025390625,
    'Barclays Business': 284.489990234375,
    'first direct': 2227.2900390625,
    Halifax: 5724.28076171875,
    HSBC: 4138.81982421875,
    Lloyds: 4325.9404296875,
    Monzo: 11761.2490234375,
    Nationwide: 6296.06005859375,
    NatWest: 11083.9912109375,
    Revolut: 463.0799865722656,
    'Royal Bank of Scotland': 4402.650390625,
    Santander: 3858.8701171875,
    Starling: 1832.159912109375,
    TSB: 3232.22021484375,
    Wise: 15.329999923706055,
  },
  CREDIT: {
    AMEX: 1506,
    MASTERCARD: 1764.8702392578125,
    VISA: 47.31999969482422,
  },
}
