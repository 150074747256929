import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from 'react-spreadsheet-grid'
import store from '../../../../store/store'

import DashboardSection from '../../../../components/dashboard/DashboardSection'
import MultiSelector from '../../../../components/dashboard/MultiSelector'
import DashboardPage from '../../../content/DashboardPage'
import MistakeSubmission from './mistakeSubmission'
import MonthlyDataReview from './monthlyReview'

const CategoryViz = () => {
  const user = useSelector(({ user }) => user)
  const data = useSelector(
    ({ internalCategorizedBrands }) => internalCategorizedBrands
  )
  const unCatData = useSelector(
    ({ internalUnCategorizedBrands }) => internalUnCategorizedBrands
  )

  const [selBrand, setSelBrand] = useState({})
  const [selBrandData, setSelBrandData] = useState({})
  const [lastCalledBrand, setLastCalledBrand] = useState('')

  const [viewUnCatData, setViewUnCatData] = useState(false)
  const [unCatOffset, setUnCatOffset] = useState(0)
  const [maxOffset, setMaxOffset] = useState(15)

  const [loading, setLoading] = useState(false)
  const [loadingBrand, setLoadingBrand] = useState(false)
  const [loadingUnCat, setLoadingUnCat] = useState(false)

  useEffect(() => {
    if (data.length === 0) {
      setLoading(true)
      API.get('clientGateway', '/internal/categoryviz', {})
        .then(res => {
          store.dispatch({
            type: 'changeInternalCatBrands',
            payload: res,
          })
          setLoading(false)
        })
        .catch(e => console.error(e))
    }
  }, [data])

  async function getData() {
    let offsets = [0]
    for (let i = 50000; i < selBrand.count; i += 50000) {
      offsets.push(i)
    }
    let brandData = {}
    await Promise.all(
      offsets.map(off => {
        return API.get('clientGateway', '/internal/categoryviz', {
          queryStringParameters: {
            brand: selBrand.brand,
            offset: off,
          },
        })
          .then(res => {
            if (Object.keys(brandData) > 0) {
              brandData['transactions'].append(res.transactions)
            } else {
              brandData = res
            }
            setLastCalledBrand(selBrand.brand)
            return Promise.resolve()
          })
          .catch(e => console.error(e))
      })
    )
    setSelBrandData(brandData)
    setLoadingBrand(false)
  }

  useEffect(() => {
    if (
      Object.keys(selBrand).length > 0 &&
      selBrand.brand !== lastCalledBrand
    ) {
      setLoadingBrand(true)
      getData()
    }
    // eslint-disable-next-line
  }, [selBrand, lastCalledBrand])

  useEffect(() => {
    if (
      viewUnCatData &&
      unCatData.length < unCatOffset * 50000 + 1 &&
      unCatOffset < maxOffset
    ) {
      setLoadingUnCat(true)
      let brandData = unCatData
      API.get('clientGateway', '/internal/categoryviz', {
        queryStringParameters: {
          uncategorized: true,
          offset: unCatOffset,
        },
      })
        .then(res => {
          if (res.length < 50000) {
            console.error('less than 50000')
            setMaxOffset(unCatOffset)
          }
          brandData = brandData.concat(res)
          store.dispatch({
            type: 'changeInternalUnCatBrands',
            payload: brandData,
          })
          setLoadingUnCat(false)
        })
        .catch(e => console.error(e))
    }
  }, [unCatOffset, viewUnCatData, maxOffset, unCatData])

  const DisplayGrid = () => {
    return (
      <div className='mt-4 flex flex-col'>
        <span className='text-xl font-bold'>Categorised Brands</span>
        <span className='text-sm'>
          Click on a brand to view its transactions
        </span>
        <div className='h-96'>
          <Grid
            isScrollable
            columns={[
              {
                title: () => (
                  <button
                    onClick={() => {
                      if (data[0][0] > data[data.length - 1][0]) {
                        store.dispatch({
                          type: 'changeInternalCatBrands',
                          payload: [
                            ...data.sort((a, b) => a[0].localeCompare(b[0])),
                          ],
                        })
                      } else {
                        store.dispatch({
                          type: 'changeInternalCatBrands',
                          payload: [
                            ...data.sort((a, b) => b[0].localeCompare(a[0])),
                          ],
                        })
                      }
                    }}
                    className='font-bold'>
                    Brand
                  </button>
                ),
                value: row => {
                  return (
                    <div className='flex w-full flex-row justify-start'>
                      {row.logo_link !== '-' && (
                        <img
                          alt={`${row.brand} logo`}
                          className='mr-2 h-4'
                          src={'https://logo.clearbit.com/' + row.logo_link}
                        />
                      )}
                      {row.brand}
                    </div>
                  )
                },
              },
              {
                title: () => (
                  <button
                    onClick={() => {
                      if (data[0][1][0] > data[data.length - 1][1][0]) {
                        store.dispatch({
                          type: 'changeInternalCatBrands',
                          payload: [
                            ...data.sort((a, b) =>
                              a[1][0].localeCompare(b[1][0])
                            ),
                          ],
                        })
                      } else {
                        store.dispatch({
                          type: 'changeInternalCatBrands',
                          payload: [
                            ...data.sort((a, b) =>
                              b[1][0].localeCompare(a[1][0])
                            ),
                          ],
                        })
                      }
                    }}
                    className='font-bold'>
                    Category
                  </button>
                ),
                value: row => {
                  return <span>{row.category}</span>
                },
              },
              {
                title: () => (
                  <button
                    onClick={() => {
                      if (data[0][2] > data[data.length - 1][2]) {
                        store.dispatch({
                          type: 'changeInternalCatBrands',
                          payload: [...data.sort((a, b) => a[2] - b[2])],
                        })
                      } else {
                        store.dispatch({
                          type: 'changeInternalCatBrands',
                          payload: [...data.sort((a, b) => b[2] - a[2])],
                        })
                      }
                    }}
                    className='font-bold'>
                    TransactionCount
                  </button>
                ),
                value: row => {
                  return <span>{row.count}</span>
                },
              },
            ]}
            rows={data.map(m => {
              return {
                brand: m[0],
                category: m[1][0],
                count: m[2],
                logo_link: m[3],
              }
            })}
            getRowKey={row => row.brand}
            onCellClick={row => {
              if (row.count) {
                setSelBrand(row)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const DisplayBrandGrid = () => {
    return (
      <div className='mt-4'>
        <div className='flex w-full flex-row justify-start'>
          {selBrand.logo_link !== '-' && (
            <img
              alt={`${selBrand.brand} logo`}
              className='mr-2 h-4'
              src={'https://logo.clearbit.com/' + selBrand.logo_link}
            />
          )}
          {selBrand.brand}
          {'     |     '}
          {selBrand.category}
          {'     |     '}
          {selBrand.count}
          {' transactions'}
        </div>
        <div className='h-96'>
          <Grid
            isScrollable
            columns={[
              {
                title: () => (
                  <button
                    onClick={() => {
                      let data = selBrandData
                      data.transactions = data.transactions.sort((a, b) =>
                        a[2].localeCompare(b[2])
                      )
                      setSelBrandData({ ...data })
                    }}>
                    Description
                  </button>
                ),
                value: row => {
                  return <span>{row.description}</span>
                },
              },
              {
                title: () => (
                  <button
                    onClick={() => {
                      let data = selBrandData
                      data.transactions = data.transactions.sort((a, b) => {
                        if (a[3] === null) a[3] = ''
                        if (b[3] === null) b[3] = ''
                        return b[3].localeCompare(a[3])
                      })
                      setSelBrandData({ ...data })
                    }}>
                    Merchant Name
                  </button>
                ),
                value: row => {
                  return <span>{row.merchant_name}</span>
                },
              },
              {
                title: () => (
                  <button
                    onClick={() => {
                      let data = selBrandData
                      data.transactions = data.transactions.sort((a, b) => {
                        if (a[4] === null) a[4] = ''
                        if (b[4] === null) b[4] = ''
                        return b[4].localeCompare(a[4])
                      })
                      setSelBrandData({ ...data })
                    }}>
                    Provider Merchant Name
                  </button>
                ),
                value: row => {
                  return <span>{row.provider_merchant_name}</span>
                },
              },
              {
                title: () => (
                  <button
                    onClick={() => {
                      let data = selBrandData
                      data.transactions = data.transactions.sort((a, b) => {
                        if (a[5] === null) a[5] = ''
                        if (b[5] === null) b[5] = ''
                        return b[5].localeCompare(a[5])
                      })
                      setSelBrandData({ ...data })
                    }}>
                    Counter Party Preferred Name
                  </button>
                ),
                value: row => {
                  return <span>{row.counter_party_preferred_name}</span>
                },
              },
              {
                title: () => (
                  <button
                    onClick={() => {
                      let data = selBrandData
                      data.transactions = data.transactions.sort((a, b) => {
                        return a[6] - b[6]
                      })
                      setSelBrandData({ ...data })
                    }}>
                    Amount
                  </button>
                ),
                value: row => {
                  return <span>{row.amount}</span>
                },
              },
            ]}
            rows={selBrandData['transactions'].map(m => {
              return {
                id: m[0],
                description: m[2],
                merchant_name: m[3],
                provider_merchant_name: m[4],
                counter_party_preferred_name: m[5],
                amount: m[6],
              }
            })}
            getRowKey={row => row.id}
          />
        </div>
      </div>
    )
  }

  const DisplayUnCatGrid = () => {
    return (
      <div className='mt-4 flex-col'>
        <div className='flex flex-row justify-between'>
          <span className='text-xl font-bold'>Un-Categorized Brands</span>
          <div className='flex flex-row'>
            {unCatOffset > 0 && !loadingUnCat && (
              <button
                onClick={() => {
                  setUnCatOffset(unCatOffset - 1)
                }}>
                Previous Page
              </button>
            )}
            {loadingUnCat ? (
              <span>Loading...</span>
            ) : (
              unCatOffset !== maxOffset && (
                <button
                  onClick={() => {
                    setUnCatOffset(unCatOffset + 1)
                  }}
                  className='ml-5'>
                  Next Page
                </button>
              )
            )}
          </div>
        </div>
        <div className='h-96'>
          <Grid
            isScrollable
            columns={[
              {
                title: () => <span>Brand</span>,
                value: row => {
                  return <span>{row.brand}</span>
                },
              },
              {
                title: () => <span>Transaction Count</span>,
                value: row => {
                  return <span>{row.count}</span>
                },
              },
            ]}
            rows={unCatData
              .slice(unCatOffset * 50000, (unCatOffset + 1) * 50000)
              .map(m => {
                return { brand: m[0], count: m[1] }
              })}
            getRowKey={row => row.brand}
          />
        </div>
      </div>
    )
  }

  return (
    <DashboardPage
      className={'Categoryviz'}
      description={
        'Tool to browse payment categorisation and notice potential mistakes'
      }
      title='Categorisation Data'>
      {loading && <div className='mt-2.5'>Loading...</div>}
      <DashboardSection>
        {data.length > 0 && DisplayGrid()}
        <div className='flex w-full flex-row items-center justify-start'>
          <span className='text-sm'>Or search for a brand</span>
          <div>
            <MultiSelector
              title='Brand'
              options={data.map(d => d[0])}
              selected={[]}
              select={val => {
                const idx = data.findIndex(a => a[0] === val)
                setSelBrand({
                  brand: data[idx][0],
                  category: data[idx][1][0],
                  count: data[idx][2],
                  logo_link: data[idx][3],
                })
              }}
              unselect={() => {}}
              placeholder='Search brands...'
            />
          </div>
          <span>{selBrand.brand}</span>
        </div>
        {loadingBrand ? (
          <div className='mt-4'>Loading...</div>
        ) : (
          Object.keys(selBrandData).length > 0 && DisplayBrandGrid()
        )}
        <div>
          {unCatData.length === 0 &&
            !loading &&
            (loadingUnCat ? (
              <div className='mt-4'>Loading...</div>
            ) : (
              <button
                className='mt-4 font-bold'
                onClick={() => setViewUnCatData(true)}>
                View Un-categorized Brands
              </button>
            ))}
          {unCatData.length > 0 && DisplayUnCatGrid()}
        </div>
        {!loading && (
          <MistakeSubmission user={user} brands={data.map(d => d[0])} />
        )}
        {!loading && <MonthlyDataReview />}
      </DashboardSection>
    </DashboardPage>
  )
}

export default CategoryViz
