import Amplify from '@aws-amplify/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'

import App from './App'

import devconfig from './config/dev'
import prodconfig from './config/prod'
import usconfig from './config/us'
import store from './store/store'

import { ENV } from './env'

const config =
  ENV.REACT_APP_ENV === 'dev'
    ? devconfig
    : ENV.REACT_APP_ENV === 'us'
    ? usconfig
    : prodconfig

Amplify.configure({
  ...config,
  API: {
    endpoints: [
      {
        name: 'clientGateway',
        endpoint: config.aws_cloud_logic_custom.endpoint,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: config.Storage.AWSS3.bucket,
      region: 'eu-west-2', //OPTIONAL -  Amazon service region
      customPrefix: { public: 'public/' },
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
