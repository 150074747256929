import { API, Storage } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Input } from 'react-spreadsheet-grid'
import crossMarkLogo from '../../../../assets/icons8-cross-mark-96.png'
import checkMarkLogo from '../../../../assets/icons8-done-96.png'
import newIcon from '../../../../assets/new-icon-transparent-16.jpg'
import DashboardSection from '../../../../components/dashboard/DashboardSection'
import { ENV } from '../../../../env'

function MonthlyDataReview() {
  const user = useSelector(({ user }) => user)
  const [signedUp, setSignedUp] = useState({})
  const [justSignedUp, setJustSignedUp] = useState(false)
  const [reviews, setReviews] = useState([])
  const [loadingReviews, setLoadingReviews] = useState(false)
  const [saving, setSaving] = useState(false)
  const [mode, setMode] = useState('')
  const [hasFinishedBefore, setHasFinishedBefore] = useState(false)
  const [finished, setFinished] = useState(false)

  const env = ENV.REACT_APP_ENV

  useEffect(() => {
    if (Object.keys(signedUp).length === 0) {
      Storage.get(`frontend_files/monthly_review_signups_${env}.json`)
        .then(data => {
          return fetch(data)
        })
        .then(res => {
          return res.json()
        })
        .then(res_j => {
          setSignedUp(res_j)
        })
        .finally(() => {
          setLoadingReviews(true)
        })
    }
  }, [env, signedUp])

  useEffect(() => {
    if (loadingReviews || saving) {
      let init = {
        body: {
          user_id: user.username,
          email: user.attributes.email,
        },
      }
      if (mode) {
        if (mode === 'finish' && reviews.filter(r => !r.checked).length > 0) {
          alert('You have not finished checking all of the brands')
          setLoadingReviews(false)
          setMode('')
          return
        }
        init['body']['reviews'] = reviews
        init['body']['mode'] = mode
      }
      if (saving && reviews.length > 0) {
        init['body']['reviews'] = reviews
      }
      API.post('clientGateway', '/internal/monthlyreview', init)
        .then(res => {
          setReviews(res['reviews'])
          setHasFinishedBefore(res['has_finished'])
          if (res['mode'] && res['mode'] === 'finish') {
            let newSignUps = { ...signedUp }
            newSignUps['completions'][user.attributes.email]['reviews'].push(
              getThisMonth()
            )
            Storage.put(
              `frontend_files/monthly_review_signups_${env}.json`,
              newSignUps,
              { contentType: 'application/json' }
            ).then(() => {})
            setFinished(true)
          }
          setMode('')
          setSaving(false)
          setLoadingReviews(false)
        })
        .catch(e => console.error(e))
    }
    // eslint-disable-next-line
  }, [loadingReviews, saving])

  const signUp = () => {
    let newSignUps = { ...signedUp }
    newSignUps['signups'].push(user.attributes.email)
    newSignUps['completions'][user.attributes.email] = {
      reviews: [],
    }
    Storage.put(
      `frontend_files/monthly_review_signups_${env}.json`,
      newSignUps,
      { contentType: 'application/json' }
    )
      .then(() => {
        setSignedUp([])
      })
      .finally(() => {
        setJustSignedUp(true)
        setLoadingReviews(true)
      })
  }

  const getThisMonth = () => {
    const today = new Date()
    return today.getFullYear() + '-' + (today.getMonth() + 1)
  }

  const DisplayBrands = () => {
    return (
      <div className='mt-12 w-full'>
        <div className='flex w-full flex-row items-center justify-between'>
          <div className='mb-3 flex flex-col'>
            <span className='text-xl font-bold'>Brands for you to review</span>
            <span className='text-sm'>
              Use the third column to write down anything that is wrong with a
              brand's classification and check the checkbox when you are done
              reviewing it. You can leave the field empty if everything is fine.
              Click submit to save your results, and finish when you are done
              with your review.
            </span>
          </div>
          <div className='flex flex-row justify-between'>
            {saving ? (
              <span className='ml-2'>Saving...</span>
            ) : (
              <button
                className='ml-2 rounded bg-stonegrey-300 px-3 py-1'
                onClick={() => setSaving(true)}>
                Save
              </button>
            )}
            <button
              className='ml-2 rounded bg-stonegrey-300 px-3 py-1'
              onClick={() => {
                setMode('finish')
                setLoadingReviews(true)
              }}>
              Finish
            </button>
          </div>
        </div>
        <Grid
          isScrollable
          focusOnSingleClick
          columns={[
            {
              id: 1,
              title: () => <span>Category</span>,
              value: row => {
                return <span>{row.category}</span>
              },
              width: 20,
            },
            {
              id: 2,
              title: () => <span>Brand</span>,
              value: row => {
                return <span>{row.brand}</span>
              },
              width: 25,
            },
            {
              id: 3,
              title: () => <span>Comments</span>,
              value: (row, { focus }) => {
                return (
                  <Input
                    value={row.comments}
                    focus={focus}
                    selectTextOnFocus={true}
                    onChange={val => {
                      let newReviews = [...reviews]
                      newReviews[newReviews.findIndex(a => a.id === row.id)][
                        'comments'
                      ] = val
                      setReviews(newReviews)
                    }}
                  />
                )
              },
            },
            {
              id: 4,
              title: () => <span>Checked?</span>,
              value: row => {
                return <>{row.checked ? '✅' : '❌'}</>
              },
              width: 7,
            },
          ]}
          rows={reviews}
          getRowKey={row => row.brand}
          onCellClick={row => {
            setReviews(r => {
              r = [...r]
              const idx = r.findIndex(a => a.id === row.id)
              r[idx]['checked'] = !r[idx]['checked']
              return r
            })
          }}
        />
      </div>
    )
  }

  return (
    <DashboardSection>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center'>
          <span className='text-xl font-bold'>Monthly Data Review</span>
          <img className='ml-2 w-10 rounded' alt={'mark'} src={newIcon} />
        </div>
        <span className='mt-3 text-sm'>
          Monthly data review of the YouGov Finance dataset for quality
          checking. Each month you will be given 10 randomly selected brands
          from a sector and asked to go through their transactions with the
          tables above. The aim is to find payments that should not have been
          classified as those brands or anything else that you spot is wrong
          such as a logo missing, or you think the brand should be removed as no
          payments are tracked or it is sensitive. The results of the check will
          be reviewed each month by the team.
        </span>
        {justSignedUp && (
          <span className='mt-3'>Thank you for signing up!</span>
        )}
        {Object.keys(signedUp).length > 0 && (
          <div className='mt-3 flex w-full flex-col'>
            <span className='font-bold'>Hall of Fame</span>
            <div className='flex flex-row'>
              <span className='w-3/5 text-sm font-bold'>Email</span>
              {['Reviews completed', 'Completed this month'].map(s => (
                <span key={s} className='w-1/5 text-center text-sm font-bold'>
                  {s}
                </span>
              ))}
            </div>
            {signedUp['signups'].map(s => (
              <div key={s} className='flex flex-row'>
                <span className='w-3/5 text-sm'>{s}</span>
                <span className='w-1/5 text-center text-sm'>
                  {signedUp['completions'][s]['reviews'].length}
                </span>
                <div className='flex w-1/5 justify-center'>
                  <img
                    className='w-5'
                    alt={'mark'}
                    src={
                      signedUp['completions'][s]['reviews'].indexOf(
                        getThisMonth()
                      ) > -1
                        ? checkMarkLogo
                        : crossMarkLogo
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {Object.keys(signedUp).length > 0 &&
          (signedUp['signups'].indexOf(user.attributes.email) < 0 ? (
            <div className='mt-10 flex w-full flex-row items-center justify-center'>
              <span>
                You are not currently signed up for the monthly review. Would
                you like to join? It helps us a lot.
              </span>
              <button
                className='ml-2 rounded bg-stonegrey-300 px-3 py-1'
                onClick={() => signUp()}>
                Sign up
              </button>
            </div>
          ) : loadingReviews ? (
            <div className='mt-2.5'>Loading...</div>
          ) : reviews.length === 0 ? (
            <div className='mt-10 flex w-full flex-row items-center justify-center'>
              <span>
                {hasFinishedBefore
                  ? `${
                      finished
                        ? 'Thank you for completing the review! '
                        : "You're a star and have already completed the review this month."
                    } Feeling eager? Complete another one:`
                  : 'You have not started your monthly review yet.'}
              </span>
              <button
                className='ml-2 rounded bg-stonegrey-300 px-3 py-1'
                onClick={() => {
                  setMode('start')
                  setLoadingReviews(true)
                }}>
                Start
              </button>
            </div>
          ) : (
            DisplayBrands()
          ))}
      </div>
    </DashboardSection>
  )
}

export default MonthlyDataReview
