import { YearMonthObject } from '../types/year-month-object'

export const monthAbbrs = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
] as const

export const addMonths = (
  ym: YearMonthObject,
  months: number
): YearMonthObject => {
  const date = toDate(ym)
  date.setUTCMonth(date.getUTCMonth() + months)
  return fromDate(date)
}

export const fromDate = (date: Date): YearMonthObject => ({
  year: date.getUTCFullYear(),
  month: monthAbbrs[date.getUTCMonth()],
})

export const toDate = (ym: YearMonthObject): Date =>
  new Date(Date.UTC(ym.year, monthAbbrs.indexOf(ym.month)))

export const ymDiff = (a: YearMonthObject, b: YearMonthObject): number =>
  (a.year - b.year) * 12 +
  monthAbbrs.indexOf(a.month) -
  monthAbbrs.indexOf(b.month)
