import { API, Auth } from 'aws-amplify'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import DashboardSection from '../../../components/dashboard/DashboardSection'
import DashboardPage from '../../content/DashboardPage'

const APIPage = () => {
  const user = useSelector(({ user }) => user)
  const [client_id, setClient_id] = useState(
    user.attributes['custom:APIclientId'] ?? ''
  )
  const [client_secret, setClient_secret] = useState('--------------------')
  const [api_key_id, setApi_key_id] = useState(
    user.attributes['custom:APIkeyId'] ?? ''
  )
  const [api_key, setApi_key] = useState('--------------------')

  const appClient = () => {
    return Auth.currentSession().then(sess => {
      const init = {
        headers: {
          Authorization: sess.getIdToken().getJwtToken(),
        },
        body: {
          client_id: client_id,
        },
      }
      return API.post('clientGateway', '/auth/appclient', init).then(res => {
        if (res.client_secret) {
          setClient_id(res.client_id)
          setClient_secret(res.client_secret)
        }
      })
    })
  }

  const apiKey = () => {
    return Auth.currentSession().then(sess => {
      const init = {
        headers: {
          Authorization: sess.getIdToken().getJwtToken(),
        },
        body: {
          api_key_id: api_key_id,
        },
      }
      return API.post('clientGateway', '/auth/apikey', init).then(res => {
        if (res.api_key) {
          setApi_key_id(res.api_key_id)
          setApi_key(res.api_key)
        }
      })
    })
  }

  return (
    <DashboardPage
      title='YouGov Finance API'
      description='How to authenticate with our APIs'>
      <DashboardSection title='Introduction'>
        <div className='d-flex flex-column'>
          <span>
            The API is organized around REST, uses standard HTTP response codes,
            OAuth 2.0 and API key authentication and returns data in JSON
            format. Please see the information below to authorize your requests.
            A full technical breakdown of our API endpoints can be found under
            our API Reference:
          </span>
          <a
            href={'https://yougovfinance.github.io/'}
            className='align-self-center mt-3 w-fit rounded-lg bg-gray-400 p-2'>
            API Reference
          </a>
        </div>
      </DashboardSection>
      <DashboardSection title='Your Credentials'>
        <span>
          These are your credentials that are needed to access our endpoints.
        </span>
        <div className='mb-3 mt-3 flex w-full justify-start rounded-lg bg-gray-200 p-4'>
          <div className='flex w-1/2 flex-col justify-end'>
            <span className='font-bold'>client_id</span>
            <div className='border-1 h-12 rounded border-black p-2'>
              <span>{client_id}</span>
            </div>
            <span className='mt-2 font-bold'>client_secret</span>
            <div className='border-1 h-12 rounded border-black p-2'>
              <span>{client_secret}</span>
            </div>
            <button
              className='mt-2.5 w-fit self-center rounded-lg bg-gray-300 p-2'
              onClick={() => appClient()}>
              {client_id ? 'Regenerate Credentials' : 'Create Credentials'}
            </button>
          </div>
          <div className='ml-4 flex w-1/2 flex-col justify-end'>
            <span className='font-bold'>api_key</span>
            <div className='border-1 h-12 rounded border-black p-2'>
              <span>{api_key}</span>
            </div>
            <button
              className='mt-2.5 w-fit self-center rounded-lg bg-gray-300 p-2'
              onClick={() => apiKey()}>
              {api_key_id ? 'Regenerate API Key' : 'Create API Key'}
            </button>
          </div>
        </div>
        The client_secret and api_key must be kept secret and not shared on
        publicly accessible websites or repos. If lost or forgotten, they can be
        regenerated, but please not that this invalidates the previous
        credentials.
      </DashboardSection>
      <DashboardSection title='Authentication'>
        <span>
          All endpoints can be accessed with an access_token and the api_key. An
          access token must be obtained with your the client_id and
          client_secret prior to accessing our products. To retrieve it, perform
          the following request:
        </span>
        <div className='mb-3 mt-3 flex w-fit flex-col justify-start rounded-lg bg-gray-200 p-4'>
          <p className='font-bold'>
            POST
            https://yougovfinance.auth.eu-west-2.amazoncognito.com/oauth2/token
          </p>
          Headers:
          <div className='pl-4'>
            <p>Content-Type: application/x-www-form-urlencoded {'\n'}</p>
            <p>
              Authorization - Basic{' '}
              <span className='font-bold italic'>
                Base64Encode(client_id:client_secret)
              </span>
            </p>
          </div>
          Body:
          <div className='pl-4'>
            <p>grant_type: client_credentials</p>
            <p>
              client_id: <span className='font-bold italic'>{client_id}</span>
            </p>
            <p>scope: ygf/transactions</p>
          </div>
        </div>
        Each access token is valid for one hour, but can be renewed by repeating
        the request.
        <span>
          {'\n'}The various endpoints can then be accessed by adding the
          following headers to your requests:
        </span>
        <div className='mb-3 mt-3 flex w-fit flex-col justify-start rounded-lg bg-gray-200 p-4'>
          <p>Content-Type - application/json</p>
          <p>
            Authorization -{' '}
            <span className='font-bold italic'>access_token</span>
          </p>
          <p>
            x-api-key - <span className='font-bold italic'>api_key</span>
          </p>
        </div>
      </DashboardSection>
    </DashboardPage>
  )
}

export default APIPage
