import {
  faArrowDownUpAcrossLine,
  faBuilding,
  faChartLine,
  faCog,
  faComment,
  faCreditCard,
  faFileCirclePlus,
  faFolderOpen,
  faFolderTree,
  faLock,
  faNetworkWired,
  faPeopleGroup,
  faUniversity,
  faUserClock,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons'
import { ComponentProps, FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import Flag from 'react-world-flags'
import { Icons_Actions_Add } from '../assets/icons/Icons_Actions_Add'
import { Button } from '../components/shared/Button'
import { ENV } from '../env'
import Page404 from './404'

// Layout
import DashboardLayout from './layout'

// Dashboard Parent Page
import DashboardParentPage from './content/DashboardParentPage'

// Sample Products
import ConfigureSample from './ConfigureSample'
import ConsumerPersonas from './products/ConsumerPersonas'
import MarketTrends from './products/MarketTrends'

// Brand Profiles
import BrandProfiles from './products/BrandProfiles'

// Reports
import BuyReport from './reports/BuyReport'
import Report from './reports/Report'
import { useRoutes as useReportsRoutes } from './reports/routes'

// Internal
import APIPage from './products/APIPage'
import BankProfiles from './products/BankProfiles'
import CategoryViz from './products/Internal/CategoryViz/'
import Demographics from './products/Internal/Demographics/'
import UserFeedback from './products/Internal/UserFeedback/'
import UserRecord from './products/Internal/UserRecord/'
import UserRecordPlaid from './products/Internal/UserRecordPlaid/'
import MyBrandInsights from './products/MyBrandInsights'
import PaymentProviders from './products/PaymentProviders'

// types
import { State } from '../store/rootReducer'
import { Gender, SampleStates } from '../types/sample'

import { MAX_END_DATE } from '../constants'

const SHOW_REPORTS_ROUTES = ENV.REACT_APP_FEATURE_FLAG_REPORTS === 'true'

type AllProps =
  | ComponentProps<typeof MyBrandInsights>
  | ComponentProps<typeof Report>

// FCs is a union of FCs with the different members of AllProps
type RouteComponent = AllProps extends infer T
  ? T extends object
    ? FC<T>
    : never
  : never
type RouteComponentWithSampleStates = AllProps extends infer T
  ? T extends object
    ? FC<T & { sampleStates: SampleStates }>
    : never
  : never
type RouteComponentWithRoutingData = AllProps extends infer T
  ? T extends object
    ? FC<T & { routingData: RouteType }>
    : never
  : never
type RouteComponentWithBoth = AllProps extends infer T
  ? T extends object
    ? FC<T & { sampleStates: SampleStates; routingData: RouteType }>
    : never
  : never

export type ActualRouteType = {
  icon: typeof faArrowDownUpAcrossLine
  link: string
  title: string
  subroutes?: RouteType[]
} & (
  | {
      component: RouteComponent
      props?: AllProps
      passSampleStates?: false
      passRoutingData?: false
    }
  | {
      component: RouteComponentWithSampleStates
      props?: AllProps
      passSampleStates: true
      passRoutingData?: false
    }
  | {
      component: RouteComponentWithRoutingData
      props?: AllProps
      passSampleStates?: false
      passRoutingData: true
    }
  | {
      component: RouteComponentWithBoth
      props?: AllProps
      passSampleStates: true
      passRoutingData: true
    }
)

export type RouteType = ActualRouteType | 'divider' | 'loader'

const useRoutes = (
  brand: string | undefined,
  APIaccess: 'yes' | 'no',
  groups: string[],
  trial: boolean
) => {
  const reportRoutes = useReportsRoutes()
  return [
    ...(brand != null
      ? [
          {
            component: MyBrandInsights,
            icon: faArrowDownUpAcrossLine,
            link: 'my-brand-insights',
            title: `${brand} Quick Insights`,
            props: { brand },
          },
          'divider' as const,
        ]
      : []),
    ...(!trial
      ? [
          {
            component: ConfigureSample,
            icon: faCog,
            link: 'configure-sample',
            title: 'Configure Sample',
            passSampleStates: true as const,
          },
        ]
      : []),
    {
      component: ConsumerPersonas,
      icon: faUserTag,
      link: 'consumer-personas',
      title: 'Consumer Personas',
      passSampleStates: true as const,
    },
    {
      component: MarketTrends,
      icon: faChartLine,
      link: 'market-trends',
      title: 'Market Trends',
      passSampleStates: true as const,
    },
    'divider' as const,
    {
      component: BrandProfiles,
      icon: faBuilding,
      link: 'brand-profiles',
      title: 'Brand Profiles',
    },
    ...(SHOW_REPORTS_ROUTES
      ? [
          'divider' as const,
          {
            component: DashboardParentPage,
            icon: faFolderOpen,
            link: 'report',
            title: 'Reports',
            subroutes: reportRoutes,
            passRoutingData: true as const,
            props: {
              containerClassName: 'gap-5',
              renderLink: (r: RouteType) => {
                if (r === 'divider' || r === 'loader') return null
                const payment_status =
                  'props' in r &&
                  r.props &&
                  'payment_status' in r.props &&
                  typeof r.props.payment_status === 'string'
                    ? r.props.payment_status
                    : 'paid'
                const dark_color_bg =
                  payment_status === 'paid'
                    ? 'bg-blueberry-500'
                    : 'bg-avocado-500'
                const light_color_bg =
                  payment_status === 'paid'
                    ? 'bg-blueberry-100'
                    : 'bg-avocado-100'
                return (
                  <Link
                    to={r.link}
                    key={r.title}
                    className='flex flex-col overflow-hidden rounded-md border drop-shadow-sm'>
                    <div className='min-h-28 flex-grow bg-white p-3'>
                      <span
                        className={`text-xs font-light capitalize text-white ${dark_color_bg} rounded-sm px-2 py-1`}>
                        {payment_status}
                      </span>
                      <div className='mt-3 font-semibold'>{r.title}</div>
                    </div>
                    <div className={`p-3 text-xs ${light_color_bg}`}>
                      <div className='flex flex-row'>
                        <div className='flex-0 w-4 justify-center overflow-hidden rounded-full'>
                          <div className='-ml-2 w-8 overflow-hidden bg-red-200'>
                            <Flag code='GB' style={{ height: 16 }} />
                          </div>
                        </div>
                        <div className='ml-2 flex flex-grow flex-row justify-between leading-4'>
                          <div>UK</div>
                          <div className='font-semibold'>Nat Rep</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              },
              topButtons: (
                <Button
                  as='link'
                  Icon={Icons_Actions_Add}
                  iconPosition='left'
                  size='sm'
                  to='/dashboard/buy-report'>
                  Buy Report
                </Button>
              ),
            },
          },
          {
            component: BuyReport,
            icon: faFileCirclePlus,
            link: 'buy-report',
            title: 'Buy Report',
            type: 'button',
          },
        ]
      : []),
    ...(APIaccess != null && APIaccess === 'yes'
      ? [
          'divider' as const,
          {
            component: APIPage,
            icon: faNetworkWired,
            link: 'api',
            title: 'API',
          },
        ]
      : []),
    ...(groups && groups.includes('internal')
      ? [
          'divider' as const,
          {
            component: DashboardParentPage,
            icon: faLock,
            link: 'internal',
            title: 'Internal Pages',
            passRoutingData: true as const,
            subroutes:
              ENV.REACT_APP_ENV !== 'us'
                ? [
                    {
                      component: Demographics,
                      icon: faPeopleGroup,
                      link: 'demographics',
                      title: 'Demographics',
                    },
                    {
                      component: CategoryViz,
                      icon: faFolderTree,
                      link: 'categorisation',
                      title: 'Categorisation',
                    },
                    {
                      component: UserRecord,
                      icon: faUserClock,
                      link: 'userrecord',
                      title: 'User Record',
                    },
                    {
                      component: UserRecordPlaid,
                      icon: faUserClock,
                      link: 'connectionhubuserrecord',
                      title: 'Connection Hub User Record',
                    },
                    {
                      component: UserFeedback,
                      icon: faComment,
                      link: 'userfeedback',
                      title: 'User Feedback',
                    },
                    {
                      component: PaymentProviders,
                      icon: faCreditCard,
                      link: 'payment-providers',
                      title: 'Payment Providers',
                    },
                    {
                      component: BankProfiles,
                      icon: faUniversity,
                      link: 'bank-profiles',
                      title: 'Bank Profiles',
                    },
                  ]
                : [
                    {
                      component: Demographics,
                      icon: faPeopleGroup,
                      link: 'demographics',
                      title: 'Demographics',
                    },
                    {
                      component: CategoryViz,
                      icon: faFolderTree,
                      link: 'categorisation',
                      title: 'Categorisation',
                    },
                  ],
          },
        ]
      : []),
  ]
}

// This is a function and not a Component Function because you can't have a custom component as a child of a Route
const dashboardRoute = (route: ActualRouteType, sampleStates: SampleStates) => (
  <Route key={route.link} path={route.link}>
    <Route
      index
      element={
        // @ts-expect-error - I don't know how to fix this. The problem is that I am trying to pass a specific type of route that has props that match the component, but when I pass an array of routes, we lose the specificity of the route type, which means it doesn't know that the props are valid for the component. It works at runtime, and I know that the routes in the useRoutes have valid props associated to their components (I think), so I will leave this as is for now. Maybe a future ts wizard can fix this.
        <route.component
          {...('props' in route ? route.props : {})}
          sampleStates={sampleStates}
          routingData={route}
        />
      }
    />
    {'subroutes' in route &&
      route.subroutes &&
      (
        route.subroutes.filter(
          (r: RouteType) => r !== 'divider' && r !== 'loader'
        ) as ActualRouteType[]
      ).map(child => dashboardRoute(child, sampleStates))}
    <Route
      path=':query'
      element={
        // @ts-expect-error Same as above
        <route.component
          {...('props' in route ? route.props : {})}
          sampleStates={sampleStates}
          routingData={route}
        />
      }
    />
  </Route>
)

export default function DashboardRoutes() {
  const hasLoadedUser = useSelector(({ hasLoadedUser }: State) => hasLoadedUser)
  const { brand, email } = useSelector(({ user }: State) =>
    'attributes' in user
      ? {
          email: user.attributes.email,
          brand: user.attributes?.['custom:brand'],
        }
      : { email: undefined, brand: undefined }
  )
  const APIaccess = useSelector(({ user }: State) =>
    'attributes' in user ? user.attributes?.['custom:APIaccess'] ?? 'no' : 'no'
  )
  const { trial, userGroups } = useSelector(({ trial, userGroups }: State) => ({
    trial,
    userGroups,
  }))

  if (hasLoadedUser && email && !email.endsWith('@yougov.com'))
    window.location.href = 'https://business.yougov.com/product/finance'

  const sampleStates = {
    gender: useState<Gender>('All Genders'),
    maxAge: useState(100),
    maxDate: useState(MAX_END_DATE),
    minAge: useState(18),
    minDate: useState({
      year: MAX_END_DATE.year - 1,
      month: MAX_END_DATE.month,
    }),
    natRep: useState(false),
    regions: useState<string[]>([]),
    sampleSize: useState(0),
    useCoverageDates: useState(true),
  }

  // @ts-expect-error - I don't know how to fix this either. This is a very complex issue. Maybe react-router is just not up to par with the level of type complexity I am looking. Might be worth looking at remix or another strongly typed routing library.
  const routes = validRoutes(useRoutes(brand, APIaccess, userGroups, trial))

  return (
    <Routes>
      <Route path='*' element={<DashboardLayout pages={routes} />}>
        {routes.length > 0 && (
          <Route
            index
            element={<Navigate to={'/dashboard/' + routes[0].link} />}
          />
        )}
        {(
          routes.filter(
            (r: RouteType) => r !== null && r !== 'divider' && r !== 'loader'
          ) as ActualRouteType[]
        ).map(route => dashboardRoute(route, sampleStates))}
        {routes.length === 0 ? (
          <Route path='*' element={<div></div>} />
        ) : (
          <Route path='*' element={<Page404 />} />
        )}
      </Route>
    </Routes>
  )
}

export const validRoutes = (routes: Readonly<RouteType[]>) =>
  routes.filter(
    route => route === 'divider' || route === 'loader' || route.link !== ''
  )
