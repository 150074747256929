import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import FlagUK from '../assets/icons/flags-united-kingdom.svg'
import FlagUS from '../assets/icons/flags-united-states.svg'

const env = process.env.REACT_APP_ENV

const otherPortalLink =
  env === 'us'
    ? 'https://www.business.yougov.finance/'
    : 'https://us.business.yougov.finance/'

const thisPortal = env === 'us' ? 'United States' : 'United Kingdom'
const otherPortal = env === 'us' ? 'United Kingdom' : 'United States'

export const PortalCountrySwitcher = () => (
  <div className='relative'>
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={`${
              open ? 'text-white' : 'text-white/90'
            } group inline-flex items-center rounded-md bg-stonegrey-800 px-2 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}>
            <span>
              <img
                src={env === 'us' ? FlagUS : FlagUK}
                className='h-4 w-auto'
                alt={`Flag of the ${env === 'us' ? 'US' : 'UK'}`}
              />
            </span>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${open ? 'text-white' : 'text-white/70'}
                  ml-2 h-3 w-3 transition duration-150 ease-in-out group-hover:text-white/80`}
              aria-hidden='true'
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'>
            <Popover.Panel className='absolute top-10 z-10 w-64 translate-x-0 translate-y-0 transform'>
              <div className='overflow-hidden rounded-lg bg-white p-3 text-sm shadow-lg ring-1 ring-black/5'>
                You're using the {thisPortal} portal. To access the{' '}
                {otherPortal} portal,{' '}
                <a href={otherPortalLink} target='_blank' className='underline'>
                  click here
                </a>
                .
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  </div>
)
