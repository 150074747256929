import { z } from 'zod'

const envSchema = z.object({
  REACT_APP_ENV: z
    .union([z.literal('dev'), z.literal('prod'), z.literal('us')])
    .default('dev'),
  REACT_APP_OLD_AUTH_PASS: z.string().optional(),
  REACT_APP_FEATURE_FLAG_REPORTS: z
    .union([z.literal('true'), z.literal('false')])
    .default('false'),
})

export const ENV = envSchema.parse(process.env)

declare global {
  namespace NodeJS {
    interface ProcessEnv extends z.infer<typeof envSchema> {}
  }
}
