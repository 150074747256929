import { useSelector } from 'react-redux'
import { State } from '../store/rootReducer'
import { useQueryWrapper } from './useQueryWrapper'

export const useTransactions = (selectedUser: string | undefined) => {
  const user = useSelector(({ user }: State) => user)
  return useQueryWrapper(
    ['transactions', selectedUser],
    '/data/usertransactions',
    r => ({
      barChartData: r?.barChartData ?? defaultTransactions.barChartData,
      categories: r?.categories ?? defaultTransactions.categories,
      pieChartData: r?.pieChartData ?? defaultTransactions.pieChartData,
    }),
    {
      queryStringParameters: {
        user_id: 'username' in user ? user.username : undefined,
        user: selectedUser,
      },
    },
    {
      enabled: 'username' in user && selectedUser !== undefined,
      placeholderData: defaultTransactions,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  )
}

const defaultTransactions = {
  barChartData: null,
  categories: [],
  pieChartData: null,
}
