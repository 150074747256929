import { AuthState } from '@aws-amplify/ui-components'
import { Auth, Hub } from 'aws-amplify'
import store from '../store/store'

class LoginManager {
  constructor() {
    this.loginState = 'signIn'

    this.signIn = (email, password) => Auth.signIn(email, password)

    this.sendCustomChallengeAnswer = (user, challengeResponse) =>
      Auth.sendCustomChallengeAnswer(user, challengeResponse)

    this.signOut = () => {
      try {
        return Auth.signOut()
      } catch (e) {
        console.error('error signing out: ', e)
      }
    }

    this.forgotPassword = async email => {
      await Auth.forgotPassword(email)
      this.loginState = 'resettingpassword'
    }

    this.forgotPasswordSubmit = async (email, verificationCode, password) =>
      await Auth.forgotPasswordSubmit(email, verificationCode, password)

    const listener = data => {
      switch (data.payload.event) {
        case 'signIn':
          this.loadData()
          store.dispatch({
            type: 'changeAuthState',
            payload: AuthState.SignedIn,
          })
          break
        case 'signUp':
          store.dispatch({
            type: 'changeAuthState',
            payload: AuthState.ConfirmSignUp,
          })
          break
        case 'forgotPassword':
          store.dispatch({
            type: 'changeAuthState',
            payload: AuthState.ResetPassword,
          })
          break
        case 'configured':
          this.loadData(Math.random())
          break
        case 'signOut':
          this.loadData()
          break
        default:
          break
      }
    }

    Hub.listen('auth', listener)
  }

  loadData() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        store.dispatch({
          type: 'changeUserGroups',
          payload:
            user?.signInUserSession?.idToken?.payload?.['cognito:groups'],
        })
        return Auth.currentUserInfo()
      })
      .then(data => {
        if (data !== null) {
          this.formState = 'signedIn'
          store.dispatch({ type: 'changeUser', payload: data })
        } else {
          store.dispatch({ type: 'logout' })
          this.formState = 'signIn'
        }
      })
      .catch(() => {
        store.dispatch({ type: 'logout' })
        this.formState = 'signIn'
      })
  }
}

const loginManager = new LoginManager()

export default loginManager
