import API from '@aws-amplify/api'
import { useQuery } from '@tanstack/react-query'
import { Storage } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { z } from 'zod'

import { State } from '../store/rootReducer'
import { Sample } from './useSample'

export const useMarketTrends = (sample: Sample, brands: string[]) =>
  useQuery(
    ['get-market-trends', sample, brands],
    async () => {
      if (!sample || !brands || brands.length === 0) return
      const init = {
        body: {
          brands: brands.join(','),
          sample: Object.keys(sample).map(s => {
            return { ...{ user_id: s }, ...sample[s] }
          }),
        },
      }
      return API.post('clientGateway', '/data/aggregatedspending', init)
        .then(res => {
          if (Object.keys(res.data).length > 0) return res.data
          return null
        })
        .catch(e => console.error(e))
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

const queryApiSchema = z.object({
  query_id: z.string(),
  sample_id: z.string(),
  status: z.union([
    z.literal('deleted'),
    z.literal('failed'),
    z.literal('finished'),
    z.literal('no_transactions'),
    z.literal('running'),
  ]),
  brands: z.array(z.string()),
})

export const useQueryApi = (
  args:
    | { query_id: string }
    | { sample_id: string | undefined; brands: string[] }
) => {
  const user = useSelector(({ user }: State) => user)
  return useQuery(
    ['get-query', user, args],
    async () => {
      if (!user || !('username' in user) || !user.username)
        throw new Error('User not logged in')
      const result = queryApiSchema.parse(
        await API.post('clientGateway', '/data/query', {
          body: {
            user_id: user.username,
            ...args,
          },
        })
      )
      return result
    },
    {
      enabled: 'query_id' in args || ('sample_id' in args && !!args.sample_id),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: data => {
        if (
          data &&
          typeof data === 'object' &&
          data?.status &&
          (data.status === 'finished' ||
            data.status === 'failed' ||
            data.status === 'no_transactions' ||
            data.status === 'deleted')
        )
          return Infinity
        return 1000
      },
    }
  )
}

const brandDataSchema = z.object({
  weekly_split: z.object({
    amount: z.array(z.number()),
    count: z.array(z.number()),
  }),
  monthly_split: z.object({
    amount: z.array(z.number()),
    count: z.array(z.number()),
  }),
  four_weekly_split: z.object({
    amount: z.array(z.number()),
    count: z.array(z.number()),
  }),
})
export type BrandData = z.infer<typeof brandDataSchema>

const brandDataRecordSchema = z.record(brandDataSchema)
export type BrandDataRecord = z.infer<typeof brandDataRecordSchema>

const queryResultSchema = z.object({
  data: z.object({
    nat: brandDataRecordSchema,
    unweighted: brandDataRecordSchema,
    weighted: brandDataRecordSchema,
  }),
})
export type QueryResult = z.infer<typeof queryResultSchema>

const emptyQueryResult: QueryResult = {
  data: {
    nat: {},
    unweighted: {},
    weighted: {},
  },
}

export const useMarketTrendsQuery = ({
  sample_id,
  brands,
}: {
  sample_id: string | undefined
  brands: string[]
}) => {
  const { data: queryApiData } = useQueryApi({ sample_id, brands })
  return useQuery(
    ['query', queryApiData],
    async () => {
      const parsedQueryApiData = queryApiSchema.parse(queryApiData)
      if (parsedQueryApiData.status === 'deleted')
        throw new Error('The sample has been deleted')
      if (parsedQueryApiData.status === 'failed')
        throw new Error('The sample failed to be created')
      if (parsedQueryApiData.status === 'running')
        throw new Error('The sample is still being created')
      if (parsedQueryApiData.status === 'no_transactions')
        return { ...queryApiData, data: emptyQueryResult.data }

      const s3data = await Storage.get(
        `queries/query_id=${parsedQueryApiData.query_id}/query.json`,
        {
          download: true,
        }
      )
      // @ts-expect-error This is an type issue with amplify, should be fixed when we upgrade aws-amplify
      if (s3data.Body === undefined) throw new Error('No sample data found')
      // @ts-expect-error This is an type issue with amplify, should be fixed when we upgrade aws-amplify
      if (!(s3data.Body instanceof Blob))
        throw new Error('Market trends data in wrong format')
      // @ts-expect-error This is an type issue with amplify, should be fixed when we upgrade aws-amplify
      const jsonString = await s3data.Body.text()
      return {
        ...queryApiData,
        data: queryResultSchema.parse(JSON.parse(jsonString)).data,
      }
    },
    {
      enabled: queryApiData !== undefined && queryApiData.status !== 'running',
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
}
