import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ActualRouteType } from '../routes'
import DashboardPage from './DashboardPage'

const DashboardParentPage = ({
  containerClassName = '',
  children,
  renderLink = (route: ActualRouteType) => (
    <Link
      to={route.link}
      key={route.title}
      className='rounded border bg-stonegrey-200 p-3'>
      <FontAwesomeIcon icon={route.icon} className='mr-2' />
      {route.title}
    </Link>
  ),
  routingData,
  ...dashboardPageProps
}: {
  containerClassName?: string
  children?: ReactNode
  linksHeader?: string
  renderLink?: (route: ActualRouteType, otherData: unknown) => ReactNode
  routingData: ActualRouteType
  dashboardPageProps?: Omit<ComponentProps<typeof DashboardPage>, 'title'>
  topButtons?: ReactNode
}) => {
  const title = routingData && 'title' in routingData ? routingData.title : ''
  const subroutes =
    routingData && 'subroutes' in routingData ? routingData.subroutes : []
  return (
    <div className='flex-grow'>
      <DashboardPage {...{ title, ...dashboardPageProps }}>
        <div className='mt-4'>
          <div
            className={`grid grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-4 ${containerClassName}`}>
            {subroutes !== undefined &&
              typeof subroutes !== 'string' &&
              (
                subroutes.filter(
                  x => x !== 'divider' && x !== 'loader'
                ) as ActualRouteType[]
              )
                .filter(
                  ({ link }: { link: unknown }) =>
                    !!link && typeof link === 'string' && link.charAt(0) !== ':'
                )
                .map(renderLink)}
          </div>
        </div>
        {children}
      </DashboardPage>
    </div>
  )
}
export default DashboardParentPage
