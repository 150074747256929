import { Range } from 'rc-slider'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import months from '../../helpers/DateHelper'
import {
  addMonths,
  monthAbbrs,
  ymDiff,
} from '../../helpers/yearMonthObjectHelper'
import { YearMonthObject } from '../../types/year-month-object'

import 'rc-slider/assets/index.css'

const DateRangeSelector = ({
  endDateState: [endDate, setEndDate],
  maxYearMonth,
  minYearMonth,
  startDateState: [startDate, setStartDate],
}: {
  endDateState: [YearMonthObject, Dispatch<SetStateAction<YearMonthObject>>]
  maxYearMonth: YearMonthObject
  minYearMonth: YearMonthObject
  startDateState: [YearMonthObject, Dispatch<SetStateAction<YearMonthObject>>]
}) => {
  const dateRangeSelectorRef = useRef<HTMLDivElement>(null)
  const dateTextRef = useRef<HTMLSpanElement>(null)

  const MAX_MONTH_DIFF = ymDiff(maxYearMonth, minYearMonth)

  const [startDateIndex, setStartDateIndex] = useState(
    ymDiff(startDate, minYearMonth)
  )
  const [endDateIndex, setEndDateIndex] = useState(
    ymDiff(endDate, minYearMonth)
  )

  const dateIndexToString = (dateIndex: number) => {
    const { month, year } = addMonths(minYearMonth, dateIndex)
    return `${months[monthAbbrs.indexOf(month)]} ${year}`
  }

  useEffect(() => {
    setStartDateIndex(ymDiff(startDate, minYearMonth))
    setEndDateIndex(ymDiff(endDate, minYearMonth))
  }, [startDate, endDate, minYearMonth])

  const getDateTextMarginLeft = () =>
    ((startDateIndex + endDateIndex) * 50) / MAX_MONTH_DIFF

  const getDateTextTransformX = () => {
    if (!dateTextRef.current || !dateRangeSelectorRef.current) return '-50%'

    // alert(dateTextRef.innerHTML + ' ' + getDateText())
    if (dateTextRef.current.innerHTML !== getDateText())
      dateTextRef.current.innerHTML = getDateText()

    if (
      (getDateTextMarginLeft() / 100) *
        dateRangeSelectorRef.current.offsetWidth <
      dateTextRef.current.offsetWidth / 2
    )
      return (
        '-' +
        (getDateTextMarginLeft() / 100) *
          dateRangeSelectorRef.current.offsetWidth +
        'px'
      )
    if (
      (getDateTextMarginLeft() / 100) *
        dateRangeSelectorRef.current.offsetWidth >
      dateRangeSelectorRef.current.offsetWidth -
        dateTextRef.current.offsetWidth / 2
    )
      return (
        dateRangeSelectorRef.current.offsetWidth -
        dateTextRef.current.offsetWidth -
        (getDateTextMarginLeft() / 100) *
          dateRangeSelectorRef.current.offsetWidth +
        'px'
      )
    return '-50%'
  }

  const getDateText = () => {
    if (startDateIndex === endDateIndex)
      return dateIndexToString(startDateIndex)
    return `${dateIndexToString(startDateIndex)} - ${dateIndexToString(
      endDateIndex
    )}`
  }

  return (
    <div ref={dateRangeSelectorRef}>
      <Range
        draggableTrack={true}
        handleStyle={[
          {
            borderWidth: 0,
            boxShadow: '0px 1px 5px 1px rgba(0, 0, 0, 0.4)',
          },
          {
            borderWidth: 0,
            boxShadow: '0px 1px 5px 1px rgba(0, 0, 0, 0.4)',
          },
        ]}
        min={0}
        max={MAX_MONTH_DIFF}
        onChange={values => {
          setStartDateIndex(values[0])
          setEndDateIndex(values[1])
        }}
        onAfterChange={() => {
          setStartDate(addMonths(minYearMonth, startDateIndex))
          setEndDate(addMonths(minYearMonth, endDateIndex))
        }}
        railStyle={{
          height: 10,
          top: 2,
        }}
        trackStyle={[
          {
            backgroundColor: '#33415C',
            cursor: 'pointer',
            height: 10,
            top: 2,
          },
        ]}
        value={[startDateIndex, endDateIndex]}
      />
      <span
        className='inline-block whitespace-nowrap text-center text-xs'
        ref={dateTextRef}
        style={{
          marginLeft: getDateTextMarginLeft() + '%',
          transform: 'translate(' + getDateTextTransformX() + ',0)',
        }}>
        {getDateText()}
      </span>
    </div>
  )
}

export default DateRangeSelector
