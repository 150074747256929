import { useQueryWrapper } from './useQueryWrapper'

export const useBrands = () =>
  useQueryWrapper(
    ['brands'],
    '/data/listbrands',
    r => {
      if (!r.data || !Array.isArray(r.data)) return r
      r.data.sort((a, b) =>
        sortAlphabeticallyWithNumbersAtEnd(a.brand, b.brand)
      )
      return r.data
    },
    {
      queryStringParameters: {},
    },
    { refetchOnWindowFocus: false, staleTime: Infinity }
  )

const sortAlphabeticallyWithNumbersAtEnd = (a, b) => {
  const isNumberString = str => str.charAt(0) <= '9' && str.charAt(0) >= '0'
  if (isNumberString(a) && !isNumberString(b)) return 1
  if (isNumberString(b) && !isNumberString(a)) return -1
  return a.localeCompare(b)
}
