import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import AuthPage from './AuthPages/AuthPage'
import NewSignUpPage from './AuthPages/NewSignUpPage'
import OldSignInPage from './AuthPages/OldSignInPage'
import SignInPage from './AuthPages/SignInPage'
import DashRoutes from './dashboard/routes'

import './styles/globals.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <Routes>
        <Route path='/'>
          <Route index element={<RedirectToSSO />} />
          <Route path='login' element={<SignInPage />} />
          <Route path='auth' element={<AuthPage />} />
          <Route path='oldlogin' element={<OldSignInPage />} />
          <Route path='new-signup' element={<NewSignUpPage />} />
          <Route path='dashboard/*' element={<DashRoutes />} />
          <Route path='*' element={<RedirectToSSO />} />
        </Route>
      </Routes>
    </Router>
  </QueryClientProvider>
)

const RedirectToSSO = () => {
  const hasLoadedUser = useSelector(({ hasLoadedUser }) => hasLoadedUser)
  const authState = useSelector(({ authState }) => authState)

  if (!hasLoadedUser) return <></>

  if (authState === 'authenticated') return <Navigate to='/dashboard' />

  window.location.href =
    'https://customers.yougov.com/oauth/authorize?scope=openid%20email%20profile%20ygcustomers&response_type=code&client_id=yougov-finance&redirect_uri=https://www.business.yougov.finance/auth/'
  return <></>
}

export default App
