import { useMemo } from 'react'

export const reduceToTen = data => {
  const sorted = data.sort((a, b) => b.value - a.value)
  // take top nine and then create other category with sum of the remaining values
  const topTen = sorted.slice(0, 9)
  const other = sorted.slice(9).reduce((acc, { value }) => acc + value, 0)
  return other > 0 ? [...topTen, { name: 'Other', value: other }] : topTen
}

const MIN_YEAR = 2018

export const useTimeSplitToGraphDataReducer = ({
  data,
  minDate = 0,
  maxDate = Infinity,
  showAmount = true,
  timeFrame = '1m',
}) => {
  if (!['1w', '4w', '1m'].includes(timeFrame)) timeFrame = '1m'
  return useMemo(() => {
    const varKey = showAmount ? 'amount' : 'count'
    const timeFrameKey =
      timeFrame === '1m'
        ? 'monthly_split'
        : timeFrame === '4w'
        ? 'four_weekly_split'
        : 'weekly_split'

    var reducedData = []
    Object.values(data)[0][timeFrameKey][varKey].forEach((_, i) => {
      var values = {}
      Object.keys(data).forEach(
        key => (values[key] = data[key][timeFrameKey][varKey][i])
      )
      let month = timeFrame === '1m' ? i : 0
      let day = timeFrame === '1w' ? 7 * i : timeFrame === '4w' ? 28 * i : 2

      const date = new Date(MIN_YEAR, month, day)
      if (date < minDate || date > maxDate) return
      reducedData.push({ date, values })
    })

    while (
      Object.values(reducedData[0]?.values ?? [1]).reduce(
        (acc, cur) => acc + cur
      ) === 0
    )
      reducedData.shift()
    while (
      Object.values(reducedData[reducedData.length - 1]?.values ?? [1]).reduce(
        (acc, cur) => acc + cur
      ) === 0
    )
      reducedData.pop()

    return reducedData
  }, [data, minDate, maxDate, showAmount, timeFrame])
}
