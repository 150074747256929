import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css'
import Footer from '../components/shared/Footer'
import NavBar from '../components/shared/NavBar'
import Redirect from '../helpers/Redirect'
import { useMutationSaveNonce } from '../hooks/sso'
import './AuthPages.css'

const SignInPage = () => {
  const { hasLoadedUser, user } = useSelector(({ hasLoadedUser, user }) => ({
    hasLoadedUser,
    user,
  }))
  const { mutate: saveNonce } = useMutationSaveNonce()

  useEffect(() => {
    if (!hasLoadedUser || 'attributes' in user) return
    saveNonce()
  }, [hasLoadedUser, saveNonce, user])

  return (
    <Redirect ifAuthStateIs={true} to={'/dashboard'}>
      <div className='d-flex flex-column justify-content-between auth-page-wrapper'>
        <NavBar />
        <Container className='auth-page sign-in-page p-10'>
          Redirecting...
        </Container>
        <Footer />
      </div>
    </Redirect>
  )
}

export default SignInPage
