import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SmallPieChart } from 'ygf_graphs'
import DashboardSection from '../../components/dashboard/DashboardSection'
import GraphConfig from '../../components/dashboard/GraphConfig'
import { LineGraph } from '../../components/dashboard/LineGraph'
import Loading from '../../components/dashboard/Loading'
import brands, { Brand } from '../../components/reports/order/brands'
import { CURRENCY_SYMBOL, TAILWIND_XL_BREAKPOINT } from '../../constants'
import { useBrands } from '../../hooks/useBrands'
import { ReportMetadata, useReport, useReports } from '../../hooks/useReports'
import { useSampleById } from '../../hooks/useSample'
import { useScreenWidth } from '../../hooks/useScreenWidth'
import { State } from '../../store/rootReducer'
import { AssociatedBrands } from '../components/AssociatedBrands'
import { FactSheet, FactSheetItem } from '../components/FactSheet'
import { SampleInfoBox, SampleInfoCol } from '../components/SampleInfo'
import DashboardPage from '../content/DashboardPage'

const Report = ({
  brand: propBrand,
  infoBoxContent,
  reportId: propReportId,
  year: propYear,
}: {
  brand?: string
  infoBoxContent?: ReactNode
  reportId?: string
  year?: number
  payment_status?: string
}) => {
  const user = useSelector(({ user }: State) => user)

  const { reportId: paramReportId } = useParams<{ reportId: string }>()
  const reportId = propReportId || paramReportId || ''

  const { data: brandsData } = useBrands()
  const brandLogos = useMemo(() => {
    if (!brandsData) return {}
    return brandsData.reduce(
      (
        acc: Record<string, string>,
        cur: { brand: string; logo_link: string }
      ) => {
        acc[cur.brand] = cur.logo_link
        return acc
      },
      {}
    )
  }, [brandsData])

  const { data: hookData, error, isLoading } = useReport(reportId)
  const data =
    !isLoading && !error && hookData !== undefined && hookData.length > 0
      ? hookData[0]
      : undefined

  const sampleQuery = useSampleById(data?.sample_id)

  const brand = data?.brand || propBrand
  const year = propYear ?? sampleQuery.data?.params?.min_date?.substring(0, 4)

  const title = `${brand ?? ''} ${year ?? ''} Report`

  const unitState = useState<'Sum' | 'Count'>('Sum')
  const stackedState = useState(false)
  const timeFrameState = useState<'1w' | '1m' | '4w'>('1m')

  const getTopBrand = (data: Record<string, number>): [string, number] =>
    data
      ? Object.entries(data)
          .sort((a, b) => b[1] - a[1])
          .find(x => x[0] !== brand) ?? ['other', 0]
      : ['other', 0]
  const topSupermarket: [string, number] = data?.top_shop_dist?.Supermarkets
    ? getTopBrand(data.top_shop_dist.Supermarkets)
    : ['other', 0]
  const topStreaming: [string, number] = data?.top_shop_dist?.Streaming
    ? getTopBrand(data.top_shop_dist.Streaming)
    : ['other', 0]
  const topTakeout: [string, number] = data?.top_shop_dist?.Takeout
    ? getTopBrand(data.top_shop_dist.Takeout)
    : ['other', 0]

  const getSwitches = (switchData: Record<string, number>) =>
    Object.entries(switchData).sort((a, b) => b[1] - a[1])
  const switchFrom = data?.switch_dist?.from
    ? getSwitches(data.switch_dist.from)
    : undefined
  const switchTo = data?.switch_dist?.to
    ? getSwitches(data.switch_dist.to)
    : undefined

  const screenWidth = useScreenWidth()

  const errorMessage = error
    ? 'Something went wrong while loading this report. Are you sure you have the correct URL?'
    : !isLoading && data?.payment_status !== 'payment_success'
    ? 'This report has not been paid for yet. Please contact your account manager to pay for this report. If this is a mistake and you have paid for this report, please give it a few minutes to update in our system and reload this page.'
    : reportId !== 'free' &&
      !isLoading &&
      'attributes' in user &&
      data !== undefined &&
      data.email !== user.attributes.email
    ? 'You do not have access to this report.'
    : null

  const { data: reports } = useReports()

  if (errorMessage)
    return (
      <div className='flex flex-col lg:flex-row'>
        <div className='flex-1'>
          <DashboardPage title={title}>
            <div className='my-4 rounded bg-red-200 p-3 text-sm'>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='mr-2 text-red-800'
              />
              {errorMessage}
            </div>
          </DashboardPage>
        </div>
      </div>
    )

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='flex-1'>
        <DashboardPage title={title}>
          {isLoading && <Loading />}

          {infoBoxContent && (
            <div className='my-4 border bg-stonegrey-200 p-3 text-sm'>
              {infoBoxContent}
            </div>
          )}

          <SampleInfoBox
            sample={sampleQuery.data?.sample}
            isLoadingSample={sampleQuery.isLoading}
            sampleError={sampleQuery.error}
          />

          {!isLoading && !error && data && getFactSheetMarkup(data)}

          {!isLoading && !error && (
            <DashboardSection
              title='Demographic Breakdown'
              description={`Demographic breakdown for ${
                brand ?? 'this brand'
              }'s customers${year ? ` in ${year}` : ''}`}>
              <div className='block justify-around xl:flex'>
                {data?.gend_dist && (
                  <div>
                    <h4 className='font-bold'>Gender:</h4>
                    <div className='w-0 overflow-visible xl:w-auto'>
                      <SmallPieChart
                        data={Object.entries(data.gend_dist)
                          .filter(([k]) => k !== 'Unknown')
                          .map(([key, value]) => ({
                            name: key,
                            value: value,
                          }))}
                        hideExactValue={true}
                        id='genderSplitChart'
                        legendBelow={screenWidth > TAILWIND_XL_BREAKPOINT}
                        radius={110}
                      />
                    </div>
                  </div>
                )}
                {data?.age_dist && (
                  <div>
                    <h4 className='font-bold'>Age:</h4>
                    <div className='w-0 overflow-visible xl:w-auto'>
                      <SmallPieChart
                        data={Object.entries(data.age_dist)
                          .filter(([k]) => k !== 'Unknown')
                          .map(([key, value]) => ({
                            name: key,
                            value: value,
                          }))}
                        hideExactValue={true}
                        id='ageSplitChart'
                        legendBelow={screenWidth > TAILWIND_XL_BREAKPOINT}
                        radius={110}
                      />
                    </div>
                  </div>
                )}
                {data?.region_dist && (
                  <div>
                    <h4 className='font-bold'>Location:</h4>
                    <div className='w-0 overflow-visible xl:w-auto'>
                      <SmallPieChart
                        data={Object.entries(data.region_dist)
                          .filter(([k]) => k !== 'Unknown')
                          .map(([key, value]) => ({
                            name: key,
                            value: value,
                          }))}
                        hideExactValue={true}
                        id='locationSplitChart'
                        legendBelow={screenWidth > TAILWIND_XL_BREAKPOINT}
                        radius={110}
                      />
                    </div>
                  </div>
                )}
              </div>
            </DashboardSection>
          )}

          {year &&
            data?.weekly_totals &&
            data?.monthly_totals &&
            data?.four_weekly_totals && (
              <DashboardSection title='Aggregated Spend'>
                <GraphConfig
                  showStackedToggle={false}
                  showUnitToggle={
                    !!data?.weekly_counts &&
                    !!data?.monthly_counts &&
                    !!data?.four_weekly_counts
                  }
                  {...{ unitState, stackedState, timeFrameState }}
                />
                <LineGraph
                  data={
                    data?.weekly_totals?.[year]
                      ? {
                          [brand ?? 'Brand']: {
                            weekly_split: {
                              amount: data.weekly_totals[year]?.map(
                                (x: number) => -x
                              ) ?? [0],
                              count: data?.weekly_counts?.[year] ??
                                data.weekly_totals[year]?.map(
                                  (x: number) => -x
                                ) ?? [0],
                            },
                            monthly_split: {
                              amount: data.monthly_totals[year]?.map(
                                (x: number) => -x
                              ) ?? [0],
                              count: data?.monthly_counts?.[year] ??
                                data.monthly_totals[year]?.map(
                                  (x: number) => -x
                                ) ?? [0],
                            },
                            four_weekly_split: {
                              amount: data.four_weekly_totals[year]?.map(
                                (x: number) => -x
                              ) ?? [0],
                              count: data?.four_weekly_counts?.[year] ??
                                data.four_weekly_totals[year]?.map(
                                  (x: number) => -x
                                ) ?? [0],
                            },
                          },
                        }
                      : {}
                  }
                  id='spend-amount'
                  showAmount={unitState[0] === 'Sum'}
                  stacked={false}
                  timeFrame={timeFrameState[0]}
                  dataStartYear={parseInt(year)}
                />
              </DashboardSection>
            )}

          {topSupermarket[0] !== 'other' && (
            <DashboardSection
              title='Associated Brands'
              description={`${brand} customers:`}>
              <div className='flex items-start gap-4'>
                <AssociatedBrands
                  {...{
                    brand,
                    topSupermarket,
                    topStreaming,
                    topTakeout,
                    switchFrom,
                    switchTo,
                    brandLogos,
                    getLink: (brand: string) => {
                      const reportId =
                        reports &&
                        reports.find(
                          ({ brand: b, min_date: d }: ReportMetadata) =>
                            brand === b && new Date(d).getUTCFullYear() === year
                        )?.report_id
                      if (reportId) return `../${reportId}`
                      const brandObject = brands.find(
                        ({ name }: Brand) => brand === name
                      )
                      if (brandObject)
                        return `/dashboard/buy-report/?brand=${brand}`
                      return undefined
                    },
                  }}
                />
              </div>
            </DashboardSection>
          )}
        </DashboardPage>
      </div>

      <SampleInfoCol
        sample={sampleQuery.data?.sample}
        isLoadingSample={sampleQuery.isLoading}
        sampleError={sampleQuery.error}
      />
    </div>
  )
}

const getFactSheetMarkup = (data: {
  nr_trans?: number
  avg_amount?: number
  nr_users?: number
  avg_total_month?: number
  avg_nr_trans_month?: number
  nr_active_users?: number
  avg_freq_days?: number
}) => {
  return (
    <DashboardSection title='Fact Sheet'>
      <FactSheet>
        {data?.nr_trans && data?.avg_amount && data?.nr_users && (
          <FactSheetItem
            title='Average total spend (per customer)'
            info='Total spend divided by number of customers'>{`${CURRENCY_SYMBOL}${
            Math.round(
              (-100 * data.nr_trans * data.avg_amount) / data.nr_users
            ) / 100
          }`}</FactSheetItem>
        )}
        {data?.avg_total_month && (
          <FactSheetItem
            title='Average monthly spend (per customer)'
            info={`Average total spend in a month.\nCalculated by finding the average total spend in a month for each active user over the months that they had payments in, and then averaging over all of those users`}>
            {data.avg_total_month === null
              ? '-'
              : `${CURRENCY_SYMBOL}${-1 * data.avg_total_month}`}
          </FactSheetItem>
        )}
        {data?.avg_amount && (
          <FactSheetItem
            title='Average transaction value'
            info='Average transaction amount over all transactions'>
            {`${CURRENCY_SYMBOL}${-1 * data.avg_amount}`}
          </FactSheetItem>
        )}
        {data?.avg_nr_trans_month && (
          <FactSheetItem
            title='Average number of transactions per month'
            info={`Average number of transactions per month.\nCalculated by finding the average number of transactions in a month for each active user over the months that they had payments in, and then averaging over all of those users`}>
            {data.avg_nr_trans_month === null ? '-' : data.avg_nr_trans_month}
          </FactSheetItem>
        )}
        {data?.nr_active_users && (
          <FactSheetItem
            title='Number of active customers in sample'
            info='Number of users whose last payment at the brand was made less than 3 months ago'>
            {data.nr_active_users}
          </FactSheetItem>
        )}
        {data?.avg_freq_days && (
          <FactSheetItem
            title='Average number of days between transactions'
            info={`Average payment frequency in days.\nCalculated by finding the average number of days between payments for each active user that has transactions at the brand, (given they have at least two), and then averaging over all of those users`}>
            {data.avg_freq_days === null ? '-' : Math.round(data.avg_freq_days)}
          </FactSheetItem>
        )}
      </FactSheet>
    </DashboardSection>
  )
}

export default Report
