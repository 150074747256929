import { TextLink } from '../components/shared/TextLink'
import DashboardPage from './content/DashboardPage'

const Page404 = () => (
  <DashboardPage title='404 Page Not Found'>
    <div className='mt-2 flex flex-col gap-2'>
      <p>
        Sorry, the page you are looking for does not exist, or you do not have
        permission to view it.
      </p>
      <TextLink variant='standalone' to='/dashboard'>
        Return to the dashboard home
      </TextLink>
    </div>
  </DashboardPage>
)

export default Page404
