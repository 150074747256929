import Slider, { Range } from 'rc-slider'
import { useState } from 'react'
import Select from '../components/configure-sample/select'
import Toggle from '../components/configure-sample/toggle'
import DateRangeSelector from '../components/dashboard/DateRangeSelector'
import { MAX_AGE, MAX_END_DATE, MIN_AGE, MIN_START_DATE } from '../constants'
import { ENV } from '../env'
import { useSample } from '../hooks/useSample'
import { SampleStates } from '../types/sample'
import { SampleInfoBox, SampleInfoCol } from './components/SampleInfo'
import DashboardPage from './content/DashboardPage'

const ConfigureSample = ({ sampleStates }: { sampleStates: SampleStates }) => {
  const {
    gender: genderState,
    maxAge: maxAgeState,
    maxDate: maxDateState,
    minAge: minAgeState,
    minDate: minDateState,
    natRep: natRepState,
    regions: regionsState,
    sampleSize: sampleSizeState,
    useCoverageDates: useCoverageDatesState,
  } = sampleStates

  const [newNatRep, setNewNatRep] = useState(natRepState?.[0])

  const [useSampleSize, setUseSampleSize] = useState(sampleSizeState?.[0] !== 0)
  const [newSampleSize, setNewSampleSize] = useState(sampleSizeState?.[0])

  const [newMaxAge, setNewMaxAge] = useState(maxAgeState?.[0])
  const [newMinAge, setNewMinAge] = useState(minAgeState?.[0])
  const [newGender, setNewGender] = useState(genderState?.[0])
  const [newLocation, setNewLocation] = useState(regionsState?.[0])

  const [newUseCoverageDates, setNewUseCoverageDates] = useState(
    useCoverageDatesState?.[0]
  )
  const [newMaxDate, setNewMaxDate] = useState(maxDateState?.[0])
  const [newMinDate, setNewMinDate] = useState(minDateState?.[0])

  const sample = useSample({
    gender: genderState?.[0],
    maxAge: maxAgeState?.[0],
    maxDate: maxDateState?.[0],
    minAge: minAgeState?.[0],
    minDate: minDateState?.[0],
    natRep: natRepState?.[0],
    regions: regionsState?.[0],
    sampleSize: sampleSizeState?.[0],
    useCoverageDates: useCoverageDatesState?.[0],
  })

  const clear = () => {
    natRepState?.[1] && natRepState?.[1](false)
    setNewNatRep(false)
    sampleSizeState?.[1] && sampleSizeState?.[1](0)
    setUseSampleSize(false)
    setNewSampleSize(1000)
    maxAgeState?.[1] && maxAgeState[1](MAX_AGE)
    setNewMaxAge(MAX_AGE)
    minAgeState?.[1] && minAgeState[1](MIN_AGE)
    setNewMinAge(MIN_AGE)
    genderState?.[1] && genderState[1]('All Genders')
    setNewGender('All Genders')
    regionsState?.[1] && regionsState[1]([])
    setNewLocation([])
    setNewUseCoverageDates(true)
    setNewMaxDate(MAX_END_DATE)
    setNewMinDate({ year: MAX_END_DATE.year - 1, month: MAX_END_DATE.month })
    maxDateState?.[1] && maxDateState[1](MAX_END_DATE)
    minDateState?.[1] &&
      minDateState[1]({ ...MAX_END_DATE, year: MAX_END_DATE.year - 1 })
    useCoverageDatesState?.[1] && useCoverageDatesState[1](true)
  }

  const save = () => {
    natRepState?.[1] && natRepState?.[1](newNatRep)
    sampleSizeState?.[1] && sampleSizeState?.[1](newSampleSize)
    maxAgeState?.[1] && maxAgeState[1](newMaxAge)
    minAgeState?.[1] && minAgeState[1](newMinAge)
    genderState?.[1] && genderState[1](newGender)
    regionsState?.[1] && regionsState[1](newLocation)
    useCoverageDatesState?.[1] && useCoverageDatesState[1](newUseCoverageDates)
    maxDateState?.[1] && maxDateState[1](newMaxDate)
    minDateState?.[1] && minDateState[1](newMinDate)
  }

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='flex-1'>
        <DashboardPage
          title='Configure Sample'
          description='Use this page to configure the sample used in the Consumer Personas and Market Trends pages.'>
          <div className='mt-4 space-y-8 divide-y divide-gray-200' />
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div className='space-y-6 sm:space-y-5'>
              <div>
                <h3 className='text-xl font-bold leading-6 text-gray-900'>
                  Nationally Repesentative?
                </h3>
                <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                  If you check the box below, the sample will be nationally
                  repesentative, which means we will sample users in a way that
                  each demographic is repesented in proportions that match their
                  national averages.
                </p>
                <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                  Sampling in this way takes slightly longer, but can yield more
                  accurate results when looking at macro trends.
                </p>
              </div>
              <div>
                <div className='mt-6 space-y-6 sm:mt-5 sm:space-y-5'>
                  {newNatRep !== undefined && (
                    <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                      <label
                        htmlFor='Locations'
                        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                        Nat-Rep
                      </label>
                      <div className='mt-1 sm:col-span-2 sm:mt-0'>
                        <div className='max-w-lg'>
                          <Toggle state={[newNatRep, setNewNatRep]} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='space-y-6 pt-8 sm:space-y-5 sm:pt-10'>
              <div className='space-y-6 sm:space-y-5'>
                <div className='space-y-6 sm:space-y-5'>
                  <div>
                    <h3 className='text-xl font-bold leading-6 text-gray-900'>
                      Sample Size
                    </h3>
                    <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                      Our total sample is made up of 10,000+ panelists. By
                      default, we return the largest sample that matches the
                      criteria you enter on this page. If you want to specify a
                      sample size, you can check the toggle below and enter a
                      sample size. If you do this, we will return a sample at
                      most as large as you selected, but that might be smaller
                      if it is restricted by other criteria.
                    </p>
                  </div>
                  <div className='mt-6 space-y-6 sm:mt-5 sm:space-y-5'>
                    {newSampleSize !== undefined && (
                      <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                        <label
                          htmlFor='Locations'
                          className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                          Sample Size
                        </label>
                        <div className='mt-1 sm:col-span-2 sm:mt-0'>
                          <div className='flex max-w-lg flex-row'>
                            <div className='mr-5 max-w-lg'>
                              <Toggle
                                state={[useSampleSize, setUseSampleSize]}
                                onChange={(v: boolean) => {
                                  setUseSampleSize(v)
                                  if (v) setNewSampleSize(1000)
                                  if (!v) setNewSampleSize(0)
                                }}
                              />
                            </div>
                            {useSampleSize && (
                              <>
                                <span className='mr-5 text-xs leading-6'>
                                  {newSampleSize === 10000
                                    ? 'MAX'
                                    : newSampleSize}
                                </span>
                                <div className='ml-2 mt-1 flex-1'>
                                  <Slider
                                    handleStyle={{
                                      borderWidth: 1,
                                      borderColor: 'rgb(220,220,220)',
                                    }}
                                    min={100}
                                    max={10000}
                                    onChange={v => setNewSampleSize(v)}
                                    railStyle={{
                                      height: 10,
                                      top: 2,
                                    }}
                                    trackStyle={{
                                      backgroundColor: '#33415C',
                                      cursor: 'pointer',
                                      height: 10,
                                      top: 2,
                                    }}
                                    value={newSampleSize}
                                    step={100}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='space-y-6 pt-8 sm:space-y-5 sm:pt-10'>
                <div className='space-y-6 sm:space-y-5'>
                  <div>
                    <h3 className='text-xl font-bold leading-6 text-gray-900'>
                      Demographic Variables
                    </h3>
                    <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                      {newNatRep ? (
                        <>
                          Note: You selected Nat Rep above, so you can't filter
                          by specific demographic variables.
                        </>
                      ) : (
                        <>
                          Design your sample by selecting one or more
                          demographic variables below.
                        </>
                      )}
                    </p>
                  </div>
                  {!newNatRep && (
                    <div className='mt-6 space-y-6 sm:mt-5 sm:space-y-5'>
                      {newMinAge !== undefined && newMaxAge !== undefined && (
                        <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                          <label
                            htmlFor='Age'
                            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                            Age
                          </label>
                          <div className='mt-1 sm:col-span-2 sm:mt-0'>
                            <div className='flex max-w-lg flex-row'>
                              <span className='mr-5 text-xs leading-6'>
                                {newMinAge}
                              </span>
                              <div className='mt-1 flex-1'>
                                <Range
                                  draggableTrack={true}
                                  handleStyle={[
                                    {
                                      borderWidth: 0,
                                      boxShadow:
                                        '0px 1px 5px 1px rgba(0, 0, 0, 0.4)',
                                    },
                                    {
                                      borderWidth: 0,
                                      boxShadow:
                                        '0px 1px 5px 1px rgba(0, 0, 0, 0.4)',
                                    },
                                  ]}
                                  min={MIN_AGE}
                                  max={MAX_AGE}
                                  onChange={([min, max]) => {
                                    setNewMinAge(min)
                                    setNewMaxAge(max)
                                  }}
                                  railStyle={{
                                    height: 10,
                                    top: 2,
                                  }}
                                  trackStyle={[
                                    {
                                      backgroundColor: '#33415C',
                                      cursor: 'pointer',
                                      height: 10,
                                      top: 2,
                                    },
                                  ]}
                                  value={[newMinAge, newMaxAge]}
                                />
                              </div>
                              <span className='ml-5 text-xs leading-6'>
                                {newMaxAge >= 100 ? '100+' : newMaxAge}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      {newGender !== undefined && (
                        <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                          <label
                            htmlFor='Genders'
                            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                            Gender
                          </label>
                          <div className='mt-1 sm:col-span-2 sm:mt-0'>
                            <div className='max-w-lg'>
                              <Select
                                allowMultiple={false}
                                options={['All Genders', 'Female', 'Male']}
                                state={[newGender, setNewGender]}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {newLocation !== undefined && (
                        <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                          <label
                            htmlFor='Locations'
                            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                            Location
                          </label>
                          <div className='mt-1 sm:col-span-2 sm:mt-0'>
                            <div className='max-w-lg'>
                              <Select
                                allowMultiple={true}
                                options={locationOptions}
                                state={[newLocation, setNewLocation]}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className='space-y-6 pt-8 sm:space-y-5 sm:pt-10'>
                <div className='space-y-6 sm:space-y-5'>
                  <div>
                    <h3 className='text-xl font-bold leading-6 text-gray-900'>
                      Full Coverage Period
                    </h3>
                    <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                      By checking the box below, the sample will be made up only
                      of panelists for whom we have a full coverage of data
                      between the two selected dates. This will restrict the
                      sample, but ensure that changes in the data are actually
                      indicative of a change in spend by the panelists.
                    </p>
                  </div>
                  <div className='mt-6 space-y-6 sm:mt-5 sm:space-y-5'>
                    {newMaxDate !== undefined && newMinDate !== undefined && (
                      <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                        <label
                          htmlFor='Locations'
                          className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                          Use Full Coverage
                        </label>
                        <div className='mt-1 sm:col-span-2 sm:mt-0'>
                          <div className='flex max-w-lg flex-row'>
                            <div className='mr-5 max-w-lg'>
                              <Toggle
                                state={[
                                  newUseCoverageDates,
                                  setNewUseCoverageDates,
                                ]}
                                onChange={(v: boolean) =>
                                  setNewUseCoverageDates(v)
                                }
                              />
                            </div>
                            {newUseCoverageDates && (
                              <div className='ml-2 mt-1 flex-1'>
                                <DateRangeSelector
                                  endDateState={[newMaxDate, setNewMaxDate]}
                                  maxYearMonth={MAX_END_DATE}
                                  minYearMonth={MIN_START_DATE}
                                  startDateState={[newMinDate, setNewMinDate]}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <SampleInfoBox
              sample={sample?.data?.sample}
              isLoadingSample={sample.isLoading}
              sampleError={sample.error}
            />

            <div className='pt-5'>
              <div className='flex justify-center'>
                <button
                  onClick={save}
                  className='mr-3 inline-flex justify-center rounded-md border border-transparent bg-stonegrey-1100 px-4 py-2 text-sm font-medium text-white hover:bg-stonegrey-1000 focus:outline-none focus:ring-2 focus:ring-stonegrey-800 focus:ring-offset-2'>
                  Save Configuration
                </button>
                <button
                  onClick={clear}
                  className='rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-stonegrey-800 focus:ring-offset-2'>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </DashboardPage>
      </div>

      <SampleInfoCol
        sample={sample?.data?.sample}
        isLoadingSample={sample.isLoading}
        sampleError={sample.error}
      />
    </div>
  )
}

const locationOptions =
  ENV.REACT_APP_ENV === 'us'
    ? ['Northeast', 'West', 'Midwest', 'South']
    : [
        'East Midlands',
        'East of England',
        'London',
        'North East',
        'North West',
        'Northern Ireland',
        'Scotland',
        'South East',
        'South West',
        'Wales',
        'West Midlands',
        'Yorkshire and the Humber',
      ]

export default ConfigureSample
