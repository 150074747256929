import { ComponentProps, useState } from 'react'
import { useSelector } from 'react-redux'
import { fromDate } from '../../../helpers/yearMonthObjectHelper'
import { useQueryParameters } from '../../../hooks/useQueryParameters'
import {
  useConfirmReportPayment,
  useCreateReport,
  useReport,
} from '../../../hooks/useReports'
import { useSample } from '../../../hooks/useSample'
import { State } from '../../../store/rootReducer'
import { Intercom } from '../../shared/Intercom'
import ConfirmationPage from './ConfirmationPage'
import LoadingPage from './LoadingPage'
import PaymentPage from './PaymentPage'
import SelectBrand from './SelectBrand'
import SelectTimeframe, { Timeframe, timeframes } from './SelectTimeframe'
import Step from './Step'
import brands, { Brand } from './brands'

const BuyReportForm = () => {
  const user = useSelector(({ user }: State) => user)
  const email = 'attributes' in user ? user.attributes.email : ''
  const uuid =
    'attributes' in user && user.attributes['custom:uuid'] !== undefined
      ? user.attributes['custom:uuid']
      : ''

  const [step, setStep] = useState(0)

  const queryParams = useQueryParameters()
  const [brand, setBrand] = useState<Brand>(
    brands.find(b => b.name === queryParams.get('brand')) || brands[0]
  )
  const [timeframe, setTimeframe] = useState<Timeframe>(timeframes[0])
  const [paymentModalHasClosed, setPaymentModalHasClosed] = useState(false)

  const sampleQuery = useSample({
    gender: 'All Genders',
    maxAge: 1000,
    maxDate: fromDate(new Date(timeframe.max_date)),
    minAge: 18,
    minDate: fromDate(new Date(timeframe.min_date)),
    natRep: true,
    regions: [],
    sampleSize: 0,
    useCoverageDates: true,
  })

  const sample_id = sampleQuery.data?.sample_id
  const sample = sampleQuery.data?.sample

  const createReportQuery = useCreateReport({
    brand: brand.name,
    email,
    sample_id,
    enabled: step === 2,
    uuid,
  })
  const checkoutId = createReportQuery.data?.checkout_id
  const reportId = createReportQuery.data?.report_id
  const createReportPaymentStatus = createReportQuery.data?.payment_status

  const reportQuery = useReport(reportId ?? '')

  const [paid, setPaid] = useState(false)
  const confirmReportPaymentQuery = useConfirmReportPayment({
    checkout_id: checkoutId,
    report_id: reportId,
    paymentModalHasClosed,
  })

  const steps = [
    {
      title: 'Select brand',
      component: SelectBrand,
    },
    {
      title: 'Select timeframe',
      component: SelectTimeframe,
    },
    {
      title: 'Set up',
      component: LoadingPage,
      continueButtonText: 'Continue to checkout',
      showContinue:
        !sampleQuery.isLoading &&
        !sampleQuery.isError &&
        !!sample &&
        createReportQuery.isSuccess &&
        !!checkoutId &&
        createReportPaymentStatus !== 'payment_success',
    },
    {
      title: 'Checkout',
      component: PaymentPage,
      showContinue: false,
      showBack: false,
    },
    {
      title: 'Confirmation',
      component: ConfirmationPage,
      showContinue: false,
      showBack: false,
    },
  ]

  if (paid && step < steps.length - 1) setStep(steps.length - 1)

  const handleClosePaymentModal = () => {
    setPaymentModalHasClosed(true)
  }
  const handleSuccessClosePaymentModal = () => {
    setPaid(true)
    handleClosePaymentModal()
  }

  const props = {
    alreadyBought: createReportPaymentStatus === 'payment_success',
    brand,
    checkoutId,
    confirmReportPaymentQuery,
    createReportQuery,
    email,
    handleClosePaymentModal,
    handleSuccessClosePaymentModal,
    reportId,
    reportQuery,
    sampleQuery,
    setBrand,
    setTimeframe,
    timeframe,
  } satisfies ComponentProps<(typeof steps)[number]['component']>

  const MainComponent = steps[step].component

  return (
    <Step
      continueButtonText={steps[step]?.continueButtonText}
      handleBackClick={
        step > 0 ? () => setStep(s => (s > 0 ? s - 1 : s)) : undefined
      }
      handleContinueClick={() =>
        setStep(s => (s < steps.length - 1 ? s + 1 : s))
      }
      showBack={steps[step]?.showBack ?? true}
      showContinue={steps[step].showContinue ?? true}
      step={step + 1}
      title={steps[step].title}
      totalSteps={steps.length}>
      <Intercom
        itemCost={1500}
        itemName={`${brand.name} ${timeframe.title}`}
        productName='Brand Report'
      />
      <MainComponent {...props} />
    </Step>
  )
}

export default BuyReportForm
