import BuyReportForm from '../../components/reports/order/BuyReportForm'
import DashboardPage from '../content/DashboardPage'

const Report = () => (
  <DashboardPage title='Buy Report'>
    <BuyReportForm />
  </DashboardPage>
)

export default Report
