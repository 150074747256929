import { AuthState } from '@aws-amplify/ui-components'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import URLParamHelper from '../helpers/URLParamHelper'
import { State } from '../store/rootReducer'

type Props = {
  addParams?: object
  children: ReactNode
  passParams?: boolean
} & (
  | {
      ifAuthStateIs: true
      to: string
    }
  | {
      ifAuthStateIs: false
      to?: string
    }
)

const Redirect = ({
  addParams = {},
  children,
  ifAuthStateIs,
  passParams = false,
  to = '',
}: Props) => {
  const hasLoadedUser = useSelector(({ hasLoadedUser }: State) => hasLoadedUser)
  const authState = useSelector(({ authState }: State) => authState)

  if (!hasLoadedUser) return <>{children}</>

  // if hasLoadedUser and authState does not match the redirect auth state, don't redirect
  const isAuthed = authState === AuthState.SignedIn
  if (
    ifAuthStateIs !== null &&
    ifAuthStateIs !== undefined &&
    isAuthed !== ifAuthStateIs
  )
    return <>{children}</>

  let URL = ''
  if (to) URL = to
  else if (URLParamHelper.get('redirect'))
    URL = '/' + URLParamHelper.get('redirect')

  const oldParams = URLParamHelper.getParams()
  delete oldParams.redirect
  if (URL !== '' && oldParams.from === URL.substring(1)) delete oldParams.from

  const paramsToPass = { ...(passParams ? oldParams : {}), ...addParams }
  const fullUrl = URL + '/?' + URLParamHelper.paramsToURL(paramsToPass)

  window.onbeforeunload = null
  return <Navigate to={fullUrl} />
}

export default Redirect
