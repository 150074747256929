import { useState } from 'react'

import { useConfirmReportPayment } from '../../../hooks/useReports'
import Loading from '../../dashboard/Loading'
import { PaymentModal } from '../../shared/PaymentModal'

const PaymentPage = ({
  checkoutId,
  email,
  handleClosePaymentModal,
  handleSuccessClosePaymentModal,
  confirmReportPaymentQuery,
}: {
  checkoutId: string | undefined
  email: string
  handleClosePaymentModal: () => void
  handleSuccessClosePaymentModal: () => void
  confirmReportPaymentQuery: ReturnType<typeof useConfirmReportPayment>
}) => {
  const [open, setOpen] = useState(true)

  const handleClose = (f: () => void) => {
    setOpen(false)
    f()
  }
  const handleCloseFail = () => handleClose(handleClosePaymentModal)
  const handleCloseSuccess = () => handleClose(handleSuccessClosePaymentModal)

  const steps: {
    title: string
    state: 'done' | 'loading' | 'todo' | 'error'
    subtext?: string | undefined
  }[] = [
    {
      title: 'Awaiting payment',
      state: open ? 'loading' : 'done',
    },
    {
      title: 'Verifying payment',
      state: open
        ? 'todo'
        : confirmReportPaymentQuery.isLoading ||
          confirmReportPaymentQuery.data === 'address_submitted' ||
          confirmReportPaymentQuery.data === 'payment_initiated' ||
          confirmReportPaymentQuery.data === 'payment_processing'
        ? 'loading'
        : confirmReportPaymentQuery.data === 'payment_success'
        ? 'done'
        : 'error',
      subtext: open
        ? undefined
        : confirmReportPaymentQuery.data === 'payment_success'
        ? 'Payment successful'
        : confirmReportPaymentQuery.data === 'payment_fail' ||
          confirmReportPaymentQuery.data === 'created'
        ? 'Payment failed. Please try again later.'
        : 'Loading payment status...',
    },
  ]

  if (checkoutId === undefined) return <div>Something went wrong.</div>
  return (
    <div>
      {steps.map(({ title, state, subtext }) => (
        <div key={title} className='flex'>
          <div className='w-10 flex-shrink-0 text-center'>
            {state === 'done' ? (
              <>&#10003;</>
            ) : state === 'loading' ? (
              <Loading />
            ) : state === 'error' ? (
              <span className='text-red'>x</span>
            ) : (
              '-'
            )}
          </div>
          <div className={state === 'loading' ? 'font-bold' : ''}>
            <div>{title}</div>
            <div className='text-xs'>{subtext}</div>
          </div>
        </div>
      ))}
      <PaymentModal
        checkoutId={checkoutId}
        handleCloseSuccess={handleCloseSuccess}
        handleCloseFail={handleCloseFail}
        userEmail={email}
      />
    </div>
  )
}

export default PaymentPage
