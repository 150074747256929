import { ENV } from './env'
import { fromDate } from './helpers/yearMonthObjectHelper'
import { YearMonthObject } from './types/year-month-object'

export const CURRENCY_SYMBOL = ENV.REACT_APP_ENV === 'us' ? '$' : '£'

// dashboard contants
export const MAX_AGE = 100
// last month
export const MAX_END_DATE: YearMonthObject = fromDate(
  new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1))
)
export const MIN_AGE = 18
export const MIN_START_DATE: YearMonthObject = { year: 2021, month: 'jan' }

export const DATA_MIN_YEAR = 2018
//
// tailwind
export const TAILWIND_XL_BREAKPOINT = 1280
