import 'bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../components/shared/Footer'
import NavBar from '../components/shared/NavBar'
import loginManager from '../helpers/LoginManager'
import Redirect from '../helpers/Redirect'
import { useMutationAuthorize } from '../hooks/sso'
import './AuthPages.css'

const AuthPage = () => {
  const [step, setStep] = useState('Authorizing')

  const { mutate: authorize } = useMutationAuthorize()

  useEffect(() => {
    authorize(null, {
      onSuccess: r => {
        loginManager.signIn(r.email, r.password)
        setStep('Signing In')
      },
    })
  }, [authorize])

  return (
    <Redirect ifAuthStateIs={true} to={'/dashboard'}>
      <div className='d-flex flex-column justify-content-between auth-page-wrapper'>
        <NavBar />
        <Container className='auth-page sign-in-page p-10'>{step}...</Container>
        <Footer />
      </div>
    </Redirect>
  )
}

export default AuthPage
