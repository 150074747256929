import { ComponentProps } from 'react'
import { Link } from 'react-router-dom'

type BaseProps = {
  arrow?: 'left' | 'right' | 'none'
  showVisited?: boolean
  variant?: 'standalone' | 'inline'
}
type Props = BaseProps & (ComponentProps<'a'> | ComponentProps<typeof Link>)

export const TextLink = ({
  arrow = 'none',
  children,
  className: inputClassName,
  showVisited = true,
  variant = 'inline',
  ...props
}: Props) => {
  const visitedClassName = showVisited ? 'visited:text-blueberry-1000' : ''
  const className = `group rounded-sm text-stonegrey-1200 hover:text-blueberry-1000 focus:text-blueberry-1000 ${visitedClassName} ${inputClassName}`
  const textClassName =
    variant === 'inline' ? 'underline' : 'group-hover:underline'
  const content =
    arrow === 'left' ? (
      <>
        <span
          className='ml-1 transition-all group-hover:ml-0 group-hover:mr-1 group-focus:ml-0 group-focus:mr-1'
          aria-hidden='true'>
          ←
        </span>{' '}
        <span className={textClassName}>{children}</span>
      </>
    ) : arrow === 'right' ? (
      <>
        <span className={textClassName}>{children}</span>{' '}
        <span
          className='mr-1 transition-all group-hover:ml-1 group-hover:mr-0 group-focus:ml-1 group-focus:mr-0'
          aria-hidden='true'>
          →
        </span>
      </>
    ) : (
      <span className={textClassName}>{children}</span>
    )
  if ('href' in props)
    return (
      <a className={className} {...props}>
        {content}
      </a>
    )
  if ('to' in props)
    return (
      <Link className={className} {...props}>
        {content}
      </Link>
    )
  return <></>
}
