import API from '@aws-amplify/api'
import { useQuery } from '@tanstack/react-query'
import { Storage } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { z } from 'zod'

import { State } from '../store/rootReducer'

// types
import { addMonths, toDate } from '../helpers/yearMonthObjectHelper'
import { SampleStateTypes } from '../types/sample'

const sampleApiSchema = z.object({
  params: z.any(),
  sample_id: z.string(),
  status: z.union([
    z.literal('deleted'),
    z.literal('failed'),
    z.literal('finished'),
    z.literal('no_users'),
    z.literal('running'),
  ]),
})
type SampleApi = z.infer<typeof sampleApiSchema>

const useSampleAPI = (
  args:
    | {
        gender?: SampleStateTypes['gender']
        max_age?: number
        min_age?: number
        max_date?: string
        min_date?: string
        nat_rep: boolean
        regions?: string[]
        sample_size?: number
      }
    | { sample_id: string | undefined } = { nat_rep: false }
) => {
  const user = useSelector(({ user }: State) => user)

  const trial = useSelector(({ trial }: State) => trial)
  if (trial && !('sample_id' in args))
    args = {
      ...args,
      gender: 'All Genders',
      max_age: 1000,
      min_age: 18,
      nat_rep: false,
      regions: [],
      min_date: '2022-01-01',
      max_date: '2024-01-01',
    }

  return useQuery(
    ['get-sample-v2', user, args],
    async () => {
      // sampleApiSchema.parse(
      const data = await API.post('clientGateway', '/data/samplev2', {
        body: {
          ...args,
        },
      })
      return data
      // ),
    },
    {
      enabled: !('sample_id' in args) || args.sample_id !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: data => {
        if (
          data &&
          typeof data === 'object' &&
          data?.status &&
          data.status === 'finished'
        )
          return Infinity
        return 1000
      },
    }
  )
}

const sampleSchema = z.record(
  z.string(),
  z.object({
    age: z.number(),
    age_granular: z.string(),
    core_nat: z.boolean(),
    first_payment: z.string().nullable(),
    gender: z.string(),
    last_payment: z.string().nullable(),
    pmx_id: z.string(),
    region: z.string(),
    Weight: z
      .number()
      .optional()
      .transform(v => (v === undefined ? 1 : v)),
  })
)
export type Sample = z.infer<typeof sampleSchema>

const useSampleHelper = (sampleApiData: SampleApi) =>
  useQuery(
    ['sample-v2', sampleApiData],
    async () => {
      const parsedSampleApiData = sampleApiSchema.parse(sampleApiData)
      if (parsedSampleApiData.status === 'deleted')
        throw new Error('The sample has been deleted')
      if (parsedSampleApiData.status === 'failed')
        throw new Error('The sample failed to be created')
      if (parsedSampleApiData.status === 'running')
        throw new Error('The sample is still being created')
      if (parsedSampleApiData.status === 'no_users')
        return { ...parsedSampleApiData, sample: {} }
      const s3data = await Storage.get(
        `samples/sample_id=${parsedSampleApiData.sample_id}/sample.json`,
        {
          download: true,
        }
      )
      // @ts-expect-error This is an type issue with amplify, should be fixed when we upgrade aws-amplify
      if (s3data.Body === undefined) throw new Error('No sample data found')
      // @ts-expect-error This is an type issue with amplify, should be fixed when we upgrade aws-amplify
      if (!(s3data.Body instanceof Blob))
        throw new Error('Sample data in wrong format')
      // @ts-expect-error This is an type issue with amplify, should be fixed when we upgrade aws-amplify
      const jsonString = await s3data.Body.text()
      return {
        ...parsedSampleApiData,
        sample: sampleSchema.parse(JSON.parse(jsonString)),
      }
    },
    {
      enabled:
        sampleApiData !== undefined && sampleApiData.status !== 'running',
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

export const useSample = ({
  gender = 'All Genders',
  maxAge = 1000,
  maxDate,
  minAge = 18,
  minDate,
  natRep = false,
  regions = [],
  sampleSize = 0,
  useCoverageDates,
}: SampleStateTypes) => {
  const { data: sampleApiData } = useSampleAPI({
    gender,
    max_age: maxAge,
    min_age: minAge,
    nat_rep: natRep,
    regions,
    sample_size: sampleSize,
    ...(useCoverageDates
      ? {
          max_date: toDate(addMonths(maxDate, 1)).toISOString(),
          min_date: toDate(minDate).toISOString(),
        }
      : {}),
  })
  return useSampleHelper(sampleApiData)
}

export const useSampleById = (sampleId: string | undefined) => {
  const { data: sampleApiData } = useSampleAPI({
    sample_id: sampleId,
  })
  return useSampleHelper(sampleApiData)
}
