import { faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

// types
import { ReactNode } from 'react'

const DashboardSection = ({
  children,
  description,
  info,
  seeMore,
  title,
}: {
  children: ReactNode
  description?: string
  info?: string
  seeMore?: string
  title: string
}) => (
  <div className='my-3'>
    <h3 className='text-xl font-bold'>
      {title}{' '}
      {info && (
        <FontAwesomeIcon
          data-for={title}
          data-tip={'<div class="w-52">' + info + '</div>'}
          data-html={true}
          icon={faInfoCircle}
        />
      )}
    </h3>
    <div className='text-sm'>{description}</div>
    {seeMore && (
      <Link
        to={seeMore}
        className='inline-block rounded-full bg-gray-300 px-2 py-1 text-center text-xs font-medium hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300'>
        See more <FontAwesomeIcon icon={faArrowRight} />
      </Link>
    )}
    <div className='mt-3'>{children}</div>
    <ReactTooltip id={title} effect='solid' multiline={true} />
  </div>
)

export default DashboardSection
