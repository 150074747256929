export const Icons_Actions_Add = ({
  className,
  color,
  height,
}: {
  className?: string
  color: string
  height: number
}) => (
  <svg
    className={className}
    width={height}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#gi4mw7l0ba)'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.5 10.5V0h3v10.5H24v3H13.5V24h-3V13.5H0v-3h10.5z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='gi4mw7l0ba'>
        <path fill={color} d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
)
