import { useEffect, useRef, useState } from 'react'
import { ENV } from '../../env'

const isProd = ENV.REACT_APP_ENV !== 'dev'

export const PaymentModal = ({
  checkoutId,
  handleCloseSuccess = () => {},
  handleCloseFail = () => {},
  userEmail,
}: {
  checkoutId: string
  handleCloseSuccess?: () => void
  handleCloseFail?: () => void
  userEmail: string
}) => {
  const ref = useRef<HTMLElement>(null)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (!scriptLoaded || !ref.current) return
    const cur = ref.current
    cur.addEventListener('successCloseModal', handleCloseSuccess)
    cur.addEventListener('closeModule', handleCloseFail)
    return () => {
      cur.removeEventListener('successCloseModal', handleCloseSuccess)
      cur.removeEventListener('closeModule', handleCloseFail)
    }
  }, [handleCloseSuccess, handleCloseFail, scriptLoaded])

  useEffect(() => {
    if (!checkoutId) return

    const script = document.createElement('script')

    script.src = 'https://d27h3ivlnqo63n.cloudfront.net/v1.0/main.js'
    script.async = true
    script.onload = () => setScriptLoaded(true)
    script.onerror = () => console.error("Couldn't load payment modal script")

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [checkoutId])

  if (checkoutId === '' || !scriptLoaded) return <></>
  return (
    <yg-payment
      ref={ref}
      checkout-id={checkoutId}
      opened={true}
      user-email={userEmail}
      {...(isProd ? { 'production-api': true } : {})}
    />
  )
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'yg-payment': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          'checkout-id': string
          opened: boolean
          'production-api'?: boolean
          'user-email': string
        },
        HTMLElement
      >
    }
  }
}
