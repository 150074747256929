import { Link } from 'react-router-dom'
import { useCreateReport, useReport } from '../../../hooks/useReports'
import { useSample } from '../../../hooks/useSample'
import Loading from '../../dashboard/Loading'

const LoadingPage = ({
  alreadyBought,
  sampleQuery,
  createReportQuery,
  reportQuery,
}: {
  alreadyBought: boolean
  sampleQuery: ReturnType<typeof useSample>
  createReportQuery: ReturnType<typeof useCreateReport>
  reportQuery: ReturnType<typeof useReport>
}) => {
  const step = alreadyBought
    ? 3
    : sampleQuery.isLoading || sampleQuery.isError
    ? 0
    : createReportQuery.isLoading || createReportQuery.isError
    ? 1
    : reportQuery.isLoading || reportQuery.data?.[0].status !== 'finished'
    ? 2
    : 3
  const steps: {
    isError: boolean
    title: string
    errorMessage: string
  }[] = [
    {
      title: 'Load sample',
      isError: sampleQuery.isError,
      errorMessage:
        'Something went wrong while loading the sample. Please try again later.',
    },
    {
      title: 'Create report',
      isError: createReportQuery.isError,
      errorMessage:
        'Something went wrong while creating the report. Please try again later.',
    },
    {
      title: 'Compute report data',
      isError: reportQuery.isError || reportQuery.data?.[0].status === 'error',
      errorMessage:
        'Something went wrong while computing the report data. Please try again later.',
    },
  ]

  const reportId = createReportQuery.data?.report_id

  return (
    <>
      {steps.map(({ title, isError, errorMessage }, i) => (
        <div key={title} className='flex'>
          <div className='w-10 flex-shrink-0 text-center'>
            {i < step ? (
              <>&#10003;</>
            ) : i === step ? (
              <Loading />
            ) : isError ? (
              <span className='text-red'>x</span>
            ) : (
              '-'
            )}
          </div>
          <div className={i === step ? 'font-bold' : ''}>
            <div>{title}</div>
            {isError && (
              <div className='text-xs text-grapefruit-900'>{errorMessage}</div>
            )}
          </div>
        </div>
      ))}
      <div className='mt-2 h-3 overflow-hidden rounded-full bg-stonegrey-300'>
        <div
          style={{ width: `${(step / steps.length) * 100}%` }}
          className='h-full rounded-full bg-stonegrey-1100 transition-all'></div>
      </div>
      {alreadyBought && (
        <div className='mt-10 rounded-lg border bg-stonegrey-200 p-4 text-sm'>
          You've already bought this report. You can view it here:{' '}
          <Link
            to={`/dashboard/report/${reportId ?? ''}`}
            className='font-bold hover:underline'>
            business.yougov.finance/dashboard/report
            {reportId ? '/' + reportId : ''}
          </Link>
        </div>
      )}
    </>
  )
}

export default LoadingPage
