import { API } from 'aws-amplify'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import MultiSelector from '../../../../components/dashboard/MultiSelector'

function MistakeSubmission({ user, brands }) {
  const [brand, setBrand] = useState('')
  const [comment, setComment] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const submit = () => {
    if (brand && comment) {
      setSubmitting(true)
      const init = {
        body: {
          user_id: user.username,
          email: user.attributes.email,
          other: {
            brand: brand,
            comment: comment,
          },
        },
      }
      API.post('clientGateway', '/internal/monthlyreview', init)
        .then(() => {
          setBrand('')
          setComment('')
          setSubmitting(false)
          setSubmitted(true)
          setTimeout(() => setSubmitted(false), 7000)
        })
        .catch(e => console.error(e))
    }
  }

  return (
    <div className='my-12 rounded bg-stonegrey-200 p-3'>
      <div className='text-xl font-bold'>
        Noticed a mistake? {submitted && '   Thank you!'}
      </div>
      <div className='flex items-center'>
        <div>
          <MultiSelector
            title='Brand'
            options={brands}
            selected={[]}
            select={setBrand}
            unselect={() => {}}
            placeholder='Search brands...'
          />
        </div>
        <div className='p-2.5'>{brand}</div>
        <textarea
          className='bg-stomegray-300 w-96 border p-2.5'
          value={comment}
          onChange={e => {
            setComment(e.target.value)
          }}
        />
        {submitting ? (
          <div>
            <Spinner
              animation='border'
              variant='dark'
              size='sm'
              className='inline-block'
            />
          </div>
        ) : (
          <button
            className='p-2'
            onClick={() => {
              submit()
            }}>
            Submit
          </button>
        )}
      </div>
    </div>
  )
}

export default MistakeSubmission
