import { useQueryWrapper } from './useQueryWrapper'

export const useBrandQuickInsights = brand =>
  useQueryWrapper(
    ['quick-insights', brand],
    '/data/brandinfo',
    res => {
      const now = new Date()
      let this_year = now.getFullYear()

      let this_month = now.getMonth() - 2
      if (this_month < 0) {
        this_month = 12 + this_month
        this_year = this_year - 1
      }
      const [last_month_year, last_month] =
        this_month > 0 ? [this_year, this_month - 1] : [this_year - 1, 11]

      const myData = res.filter(d => d.brand === brand)[0]

      const [sector_spend, sector_last_month_spend] = res.reduce(
        (acc, cur) => [
          this_year in cur.monthly_totals
            ? acc[0] + cur.monthly_totals[this_year][this_month]
            : acc[0],
          last_month_year in cur.monthly_totals
            ? acc[1] + cur.monthly_totals[last_month_year][last_month]
            : acc[1],
        ],
        [0, 0]
      )

      const factSheetData = {
        category: res?.[0]?.category,
        industrySpendDelta: (sector_spend / sector_last_month_spend - 1) * 100,
        spendDelta:
          (myData.monthly_totals[this_year][this_month] /
            myData.monthly_totals[last_month_year][last_month] -
            1) *
          100,
      }

      let marketShareData = []
      res.forEach(cur => {
        if (!(this_year in cur.monthly_totals))
          marketShareData.push({
            name: cur.brand,
            value: 0,
          })
        else
          marketShareData.push({
            name: cur.brand,
            value: Math.abs(cur.monthly_totals[this_year][this_month]),
          })
      })

      return { factSheetData, marketShareData, this_month }
    },
    {
      queryStringParameters: {
        brand,
        brandInsights: true,
      },
    },

    {
      placeholderData: {
        factSheetData: null,
        marketShareData: null,
        this_month:
          new Date().getMonth() - 2 < 0
            ? 12 + new Date().getMonth() - 2
            : new Date().getMonth() - 2,
      },
    }
  )

export const useSectorQuickInsights = marketShareData =>
  useQueryWrapper(
    ['sector-quick-insights', marketShareData],
    '/data/aggregatedspending',
    res => {
      const data = res.data

      const MIN_YEAR = 2018

      var reducedData = []
      Object.values(data)[0].monthly_split.amount.forEach((amount, i) => {
        var values = {}
        Object.keys(data).forEach(
          key => (values[key] = data[key].monthly_split.amount[i])
        )
        reducedData.push({
          date: new Date(MIN_YEAR, i, 1),
          values: values,
        })
      })

      reducedData = reducedData.slice(12 * 2) // remove 2018 and 2019

      while (
        Object.values(
          reducedData[reducedData.length - 1]?.values ?? [1]
        ).reduce((acc, cur) => acc + cur) === 0
      )
        reducedData.pop()
      reducedData.pop()
      return reducedData
    },
    {
      queryStringParameters: {
        brands:
          marketShareData && Array.isArray(marketShareData)
            ? marketShareData.map(b => b.name).join(',')
            : '',
        genders: '',
        locations: '',
        maxAge: 1000,
        minAge: 0,
      },
    },
    {
      enabled: !!marketShareData,
    }
  )
