import { useEffect } from 'react'

export const Intercom = ({
  itemCost,
  itemName,
  productName,
}: {
  itemCost: number
  itemName: string
  productName: string
}) => {
  useEffect(() => {
    window.Intercom('boot', {
      app_id: 'tqufpmtd',
      hide_default_launcher: true,
      item_cost: `£${itemCost}`,
      item_name: `${itemName}`,
      product_name: `${productName}`,
    })
    return () => window.Intercom('shutdown')
  }, [itemCost, itemName, productName])
  return <></>
}

declare global {
  interface Window {
    Intercom: (action: 'boot' | 'shutdown', ...args: unknown[]) => void
  }
}
