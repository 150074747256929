import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { Dispatch, SetStateAction, useState } from 'react'
import brands, { Brand } from './brands'

export default function SelectBrand({
  brand,
  setBrand,
}: {
  brand: Brand
  setBrand: Dispatch<SetStateAction<Brand>>
}) {
  const [query, setQuery] = useState('')
  const filteredBrands =
    query === ''
      ? brands
      : brands.filter(b => b.name.toLowerCase().includes(query.toLowerCase()))

  return (
    <Combobox as='div' value={brand} onChange={setBrand}>
      <div className='relative my-8 mt-1'>
        <Combobox.Input
          className='w-full rounded-md border border-gray-300 bg-white py-6 pl-3 pr-10 font-semibold text-gray-800 shadow-sm focus:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-600 sm:text-lg'
          onChange={e => setQuery(e.target.value)}
          displayValue={(brand: Brand) => brand?.name}
        />
        <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ChevronDownIcon
            className='h-5 w-5 text-lg font-semibold text-gray-900'
            aria-hidden='true'
          />
        </Combobox.Button>

        {filteredBrands.length > 0 && (
          <Combobox.Options className='absolute z-10 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base font-bold text-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-lg'>
            {filteredBrands.map(brand => (
              <Combobox.Option
                key={brand.name}
                value={brand}
                className={({ active }) =>
                  'relative cursor-default select-none py-6 pl-3 pr-9 ' +
                  (active
                    ? 'bg-gradient-to-br from-pink-500 to-orange-500 text-lg font-semibold text-white'
                    : 'text-gray-900')
                }>
                {({ active, selected }) => (
                  <>
                    <div className='flex items-center'>
                      {brand.imageUrl && (
                        <img
                          src={brand.imageUrl}
                          alt=''
                          className='h-6 w-6 flex-shrink-0 rounded-full'
                        />
                      )}
                      <span
                        className={
                          'ml-3 truncate ' + (selected && 'font-semibold')
                        }>
                        {brand.name}
                      </span>
                    </div>
                    {selected && (
                      <span
                        className={
                          'absolute inset-y-0 right-0 flex items-center pr-4 ' +
                          (active ? 'text-white' : 'text-indigo-600')
                        }>
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
