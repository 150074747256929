import { Dispatch, SetStateAction } from 'react'
import Select from '../../components/configure-sample/select'

type timeFrame = '1w' | '4w' | '1m'
type unit = 'Sum' | 'Count'

const GraphConfig = ({
  showStackedToggle = true,
  showUnitToggle = true,
  stackedState,
  timeFrameState,
  unitState,
}: {
  showStackedToggle?: boolean
  showUnitToggle?: boolean
  stackedState: [boolean, Dispatch<SetStateAction<boolean>>]
  timeFrameState: [timeFrame, Dispatch<SetStateAction<timeFrame>>]
  unitState: [unit, Dispatch<SetStateAction<unit>>]
}) => {
  const [unit, setUnit] = unitState
  const [stacked, setStacked] = stackedState
  const [timeFrame, setTimeFrame] = timeFrameState

  const unitOptions = ['Sum', 'Count']
  const stackedOptions = ['Yes', 'No']
  const timeOptions = ['1w', '4w', '1m']

  return (
    <div className='my-10 flex justify-around'>
      {showUnitToggle && (
        <div className='flex items-center'>
          <div className='px-2 font-semibold'>Unit</div>
          <div>
            <Select
              allowMultiple={false}
              options={unitOptions}
              state={[unit, setUnit]}
            />
          </div>
        </div>
      )}

      {showStackedToggle && (
        <div className='flex content-center items-center justify-center'>
          <div className='px-2 font-semibold'>Stacked</div>
          <div>
            <Select
              allowMultiple={false}
              options={stackedOptions}
              state={[
                stacked ? 'Yes' : 'No',
                (v: (typeof stackedOptions)[number]) => setStacked(v === 'Yes'),
              ]}
            />
          </div>
        </div>
      )}

      <div className='flex content-center items-center justify-end'>
        <div className='px-2 font-semibold'>Time Frame</div>
        <div>
          <Select
            allowMultiple={false}
            options={timeOptions}
            state={[timeFrame, setTimeFrame]}
          />
        </div>
      </div>
    </div>
  )
}

export default GraphConfig
